import React, { useState, useEffect } from 'react';
import Joyride from 'react-joyride';
import useApi from './api';
import { useUser } from './user/UserContext';

const UserWalkthrough = ({ user, setComplete }: { user: any, setComplete: any }) => {
    const [run, setRun] = useState(false);
    const { get, getStream, post, postStream, put, patch, remove } = useApi();
    const { organization, refreshUser } = useUser();


    const handleComplete = async (data: any) => {
        const { status } = data;

        // Check if the tour has ended
        if (status === 'finished' || status === 'skipped') {
            try {
                // Update the user's walkthrough status in the backend
                const response = await put(`/users/${user.id}/walkthrough-complete/`, {
                    auth_id: user.sub,
                    email: user.email,
                    username: user.nickname
                });
                setComplete(true);
                refreshUser();
                // Handle response or set state as necessary
            } catch (error) {
                // Handle error (e.g., network error, server error)
                console.error('Error updating walkthrough status:', error);
            }
        }
    };

    const steps: any = [
        {
            target: 'body', // Targeting the body to ensure it's not specifically anchored to an element
            content: (
                <div className="flex flex-col grow">
                    <h1 className="text-3xl mb-8 font-bold text-blue-900">Welcome to Aimdoc!</h1>
                    <p className="text-lg font-medium mb-8">
                        Aimdoc let's you deploy personalized conversational assistants on your website and via email. This allows you to scale engagement with your customers and leads. Aimdoc brings you into the conversation at just the right time.
                    </p>
                    <p className="text-md text-blue-700 font-semibold">
                        Let's get started with a quick walkthrough!
                    </p>
                </div>
            ),
            placement: 'center', // This attempts to center the tooltip, but specific centering may need custom styles or floaterProps
            floaterProps: {
                options: {
                    // Use these options to adjust the position, if necessary
                }
            },
            // Additional configurations to ensure it appears as a modal/popup
            modal: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
        },
        {
            target: '#agents',
            content: (
                <div>
                    <p className="text-xl text-blue-900 font-semibold mb-4">
                        Agents
                    </p>
                    <p className="text-md mb-4">
                        When you create an assistant, you can assign them knowledge from the documents you've created. Enter your website and Aimdoc will crawl your web pages and extract relevant information for your assistant to use. You can give your agents a persona and a goal to achieve when interacting with customers. You can give your agents access to URLs, which they can surface for the customer. You can brand your agent to align with your company's branding.
                    </p>
                </div>
            ),
            placement: 'bottom',
        },
        {
            target: '#documents',
            content: (
                <div>
                    <p className="text-xl text-blue-900 font-semibold mb-4">
                        Agent Knowledge
                    
                    </p>
                    <p className="text-md mb-4">
                        This is where you create and manage the knowledge that can be accessed by your assistants.
                    </p>
                    <p className="text-md">
                        Aimdoc lets you create documents from scratch or import existing documents. You can import PDFs or Word documents, or integrate with Google Docs.
                    </p>
                </div>
            ),
            placement: 'bottom',
        },
        {
            target: '#sessions',
            content: (
                <div>
                    <p className="text-xl text-blue-900 font-semibold mb-4">
                        Sessions
                    </p>
                    <p className="text-md mb-4">
                        You can review the conversation transcripts and see how your assistants are performing. Easily filter to see only sessions that include interaction.
                    </p>
                </div>
            ),
            placement: 'top',
        },
        {
            target: '#leads',
            content: (
                <div>
                    <p className="text-xl text-blue-900 font-semibold mb-4">
                        Leads
                    </p>
                    <p className="text-md mb-4">
                        If your assistant collects an email from an engaged lead or visitor, a lead will be created here. All relevant interactions will be stored on the lead's profile. You will be notified everytime you get a new lead.
                    </p>
                </div>
            ),
            placement: 'bottom',
        },
        {
            target: '#analytics',
            content: (
                <div>
                    <p className="text-xl text-blue-900 font-semibold mb-4">
                        Analytics - Coming soon!
                    </p>
                    <p className="text-md mb-4">
                        Soon you'll be able to visualize your data here.
                    </p>
                </div>
            ),
            placement: 'top',
        }
    ];

    return <Joyride
        steps={steps}
        run={true}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        styles={{
            options: {
                zIndex: 10000,
                primaryColor: '#147dc8',
                textColor: '#333333',
                backgroundColor: '#fff',
            },
        }}
        callback={handleComplete}
    />;
};

export default UserWalkthrough;
