import React, { useState, useContext, useEffect } from 'react';
import useApi from '../api';
import { Navigate } from 'react-router-dom';
import { Description, Field, Input, Label, Button } from '@headlessui/react'
import clsx from 'clsx'
import { set } from 'lodash';
import { Switch } from '@headlessui/react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastConfig from '../config/Toast';

interface UserProfile {
  first_name: string;
  last_name: string;
  password?: string;
}

function UserProfile() {
  const [organizationName, setOrganizationName] = useState('');
  const { get, getStream, post, postStream, put, patch, remove } = useApi();
  const [interactNotification, setInteractNotification] = useState<boolean>();
  const [newLeadNotification, setNewLeadNotification] = useState<boolean>();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [plan, setPlan] = useState<string>('');


  const handleSave = async (e: any) => {
    e.preventDefault();
    // check if organization name is empty
    if (!organizationName) {
      toast.error('Organization name cannot be empty', toastConfig);
      return;
    }
    const data = {
      name: organizationName,
      settings: {
        interaction_notification: interactNotification,
        new_lead_notification: newLeadNotification
      }
    };
    try {
      const response = await put(`/users/me/organization`, data);
      toast.success('Organization updated successfully', toastConfig);
    } catch (error: any) {
      console.error('Error updating organization:', error);
    }
    try {
      const response = await put(`/users/me`, { first_name: firstName, last_name: lastName, email: email });
      toast.success('User updated successfully', toastConfig);
    } catch (error: any) {
      console.error('Error updating user:', error);
    }

  };


  useEffect(() => {
    // get current user from database
    const getUser = async () => {
      try {
        const response = await get(`/users/me`);
        setFirstName(response.data.first_name);
        setLastName(response.data.last_name);
        setEmail(response.data.email);
      } catch (error: any) {
        console.error('Error fetching user:', error);
      }
    };
    const getOrganization = async () => {
      try {
        const response = await get(`/users/me/organization`);
        setOrganizationName(response.data.name);
        setInteractNotification(response.data.settings.interaction_notification);
        setNewLeadNotification(response.data.settings.new_lead_notification);
        setPlan(response.data.license_type);
      } catch (error: any) {
        console.error('Error fetching organization:', error);
      }
    };
    getUser();
    getOrganization();
  }, []);

  return (
    <div className="flex flex-col justify-start max-w-full px-8 dark:bg-slate-900 w-full">
      <form className="mt-8 w-full max-w-2xl">
        <div className="w-full max-w-xl mb-4">
          <Field>
            <Label className="text-sm/6 font-medium dark:text-slate-100">Organization Name</Label>
            <Description className="text-sm/6 dark:text-slate-200">The name of your organization.</Description>
            <Input
              className={clsx(
                'mt-3 block w-full rounded-lg border-none bg-slate-100 py-1.5 px-3 text-sm/6 dark:bg-slate-800 dark:text-slate-200 dark:border-none',
                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
              )}
              onChange={(e) => setOrganizationName(e.target.value)}
              value={organizationName}
            />
          </Field>
          <Field className='mt-4'>
            <Label className="text-sm/6 font-medium dark:text-slate-100">First Name</Label>
            <Description className="text-sm/6 dark:text-slate-200">Your first name.</Description>
            <Input
              className={clsx(
                'mt-3 block w-full rounded-lg border-none bg-slate-100 py-1.5 px-3 text-sm/6 dark:bg-slate-800 dark:text-slate-200 dark:border-none',
                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
              )}
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
          </Field>
          <Field className='mt-4'>
            <Label className="text-sm/6 font-medium dark:text-slate-100">Last Name</Label>
            <Description className="text-sm/6 dark:text-slate-200">Your last name.</Description>
            <Input
              className={clsx(
                'mt-3 block w-full rounded-lg border-none bg-slate-100 py-1.5 px-3 text-sm/6 dark:bg-slate-800 dark:text-slate-200 dark:border-none',
                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
              )}
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </Field>
          <Field className="mt-4">
            <Label className="text-sm/6 font-medium dark:text-slate-100">Plan</Label>
            <Description className="text-sm/6 dark:text-slate-200">Your Aimdoc Plan</Description>
            <div className="mt-2 px-2 py-1 rounded-md bg-green-400 w-1/3 items-center text-sm text-center font-bold justify-center dark:text-slate-800 dark:bg-green-300">
              {plan}
            </div>
          </Field>
          <Field className='mt-4'>
            <Label className="text-sm/6 font-medium dark:text-slate-100">New Interaction Notification</Label>
            <Description className="text-sm/6 dark:text-slate-200">All users in the organization will receive an email when a visitor interacts with the agent.</Description>
            <Switch
              checked={interactNotification}
              onChange={setInteractNotification}
              className={`${interactNotification ? 'bg-green-400 dark:bg-green-700' : 'bg-slate-200 dark:bg-slate-700'
                } relative inline-flex h-6 w-11 items-center rounded-full mt-1`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${interactNotification ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </Field>
          <Field className='mt-4'>
            <Label className="text-sm/6 font-medium dark:text-slate-100">New Lead Notification</Label>
            <Description className="text-sm/6 dark:text-slate-200">All users in the organization will receive an email when a visitor submits their email.</Description>
            <Switch
              checked={newLeadNotification}
              onChange={setNewLeadNotification}
              className={`${newLeadNotification ? 'bg-green-400 dark:bg-green-700' : 'bg-slate-200 dark:bg-slate-700'
                } relative inline-flex h-6 w-11 items-center rounded-full mt-1`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${newLeadNotification ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </Field>
        </div>
        <button
          onClick={handleSave}
          type="submit"
          className="rounded-md bg-blue-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus-visible:outline-slate-500"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
}

export default UserProfile;
