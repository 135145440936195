import React, { useState, useContext, useEffect} from 'react';
import useApi from '../api';
import logo from '../../logo_main.svg';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastConfig from '../config/Toast';
import { log } from 'console';
import { Auth } from '@supabase/auth-ui-react'
import { supabase } from '../supabaseClient'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { ReactComponent as Logo } from '../../logo_main.svg';
import PrivacyPolicy from '../PrivacyPolicy';
import TermsConditions from '../TermsConditions';
import { useUser } from '../user/UserContext';
import useAnalytics from '../analytics/analytics';
import { conLog } from '../utils/conLog';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // <-- state for show password
    const [error, setError] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const { get, getStream, post, postStream, put, patch, remove } = useApi();
    const { user, loading } = useUser();

    const navigate = useNavigate();
    const { captureEvent } = useAnalytics();

    const login = async () => {
      try {
          if (user) {
              const aimdocUser = await post('/users/', {
                  auth_id: user.id,
                  email: user.email,
                  username: user.email
              });
          }
      } catch (error: any) {
          conLog(error)
          toast.error(error.detail, toastConfig);
      }
  };

  useEffect(() => {
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      // login();
      if (event === 'SIGNED_IN') {
        navigate('/');
        captureEvent('logged_in', {});
      } 
      // Handle other events as needed
    });
  
    // Cleanup function to unsubscribe
    return () => {
      data.subscription.unsubscribe();
    };
  }, [navigate]);

  const myCustomTheme = {
    colors: {
      primary: "#147dc8", // Primary color
      background: "#143d8f", // Background color
      text: "#333333", // Text color
    },
  };

  return (

    <div className="flex justify-center w-full items-center h-screen flex-col bg-white">
      <Logo className="h-14 mr-2 mb-10"></Logo>
    <div className="w-96">
      <Auth
        supabaseClient={supabase}
        providers={['google']}
        socialLayout="horizontal"
        theme="default"
        appearance={{ 
          theme: ThemeSupa,
          variables: {
            default: {
              colors: {
                brand: '#147dc8',
                brandAccent: '#143d8f',
                brandButtonText: 'white',
              },
            },
          },
        
        }}
      />
    </div>
    <div className="flex w-64 ml-1 mb-2 justify-center text-sm text-neutral-400">
      By signing in, you agree to our Privacy Policy and Terms and Conditions.
    </div>
    <div className="flex flex-row space-x-2">
    <PrivacyPolicy />
    <TermsConditions />
    </div>
  </div>
  );
}

export default Login;
