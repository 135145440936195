// utils/dateFormatter.js

const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
};

export const formatDate = (dateString: string | Date) => {
    let date: Date;
    if (typeof dateString === 'string') {
        // Check if the string already ends with 'Z' (UTC)
        date = dateString.endsWith('Z') ? new Date(dateString) : new Date(dateString + 'Z');
    } else {
        date = dateString;
    }
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const addMinutes = (dateString: string, minutes: number) => {
    const date = new Date(dateString + 'Z'); // Append 'Z' to indicate UTC
    date.setUTCMinutes(date.getUTCMinutes() + minutes); // Use setUTCMinutes to ensure UTC handling
    return new Intl.DateTimeFormat('en-US', options).format(date);
};