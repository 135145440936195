import { createClient } from '@supabase/supabase-js'
import config from '../config.json'

const supabaseUrl = config.env === 'development' ? process.env.REACT_APP_SUPABASE_URL_DEV : process.env.REACT_APP_SUPABASE_URL_PROD
const supabaseAnonKey = config.env === 'development' ? process.env.REACT_APP_SUPABASE_ANON_KEY_DEV : process.env.REACT_APP_SUPABASE_ANON_KEY_PROD

if (!supabaseUrl) {
  throw new Error('REACT_APP_PUBLIC_SUPABASE_URL is missing')
}

if (!supabaseAnonKey) {
  throw new Error('REACT_APP_SUPABASE_ANON_KEY is missing')
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey)