import React, { useEffect, useState, useContext, useRef, Fragment } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useApi from '../api';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { formatDate } from '../utils/dateFormatter';
import RowDelete from '../ui/RowDelete';
import Filter from '../ui/Filter';
import { FilterInterface } from '../types'
import useAnalytics from '../analytics/analytics';
import BeatLoader from '../ui/BeatLoader';

interface SessionsCardProps {
    setSessionId: (id: string) => void;
    selectedSessionId: string | null;
    selectedSessionStatus: string;
}

const SessionsCard: React.FC<SessionsCardProps> = ({ setSessionId, selectedSessionId, selectedSessionStatus }) => {
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState<any[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [page, setPage] = useState(1);

    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate(); // Create a navigate function
    const location = useLocation();
    const { get, getStream, post, postStream, put, patch, remove } = useApi();
    const { captureEvent } = useAnalytics();

    // get search params
    const search = new URLSearchParams(window.location.search);
    const newAgent = search.get('new');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const currentPage = parseInt(params.get('page') || '1');
        const currentSearch = params.get('search') || '';
        setPage(currentPage);
        setSearchInput(currentSearch);
        getSessions(currentPage, currentSearch);
    }, [location.search]);

    const getSessions = async (page: number = 1, search: string = '') => {
        try {
            setLoading(true);
            const params = new URLSearchParams({
                page: page.toString(),
                per_page: '25',
                search,
            });
            const data = await get(`/sessions/?${params.toString()}`);
            setSessions(data.data.items);
            setPage(data.data.page);
            setTotalPages(data.data.pages);
            captureEvent('viewed_sessions', {});
        } catch (error) {
            console.error('Error fetching sessions:', error);
        }
        setLoading(false);
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            const params = new URLSearchParams(location.search);
            params.set('page', (page + 1).toString());
            navigate(`?${params.toString()}`);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            const params = new URLSearchParams(location.search);
            params.set('page', (page - 1).toString());
            navigate(`?${params.toString()}`);
        }
    };

    const handleDelete = async (id: string, index: number) => {
        try {
            const response = await remove(`/sessions/${id}`);
            
            // Find the next session to select
            const nextSession = sessions[index + 1] || sessions[index - 1];
            if (nextSession) {
                setSessionId(nextSession.id);
                navigate(`/sessions/${nextSession.id}`);
            } else {
                // If no sessions left, clear the selection
                setSessionId('');
                navigate('/sessions');
            }

            setSessions(sessions.filter((agent: any) => agent.id !== id));
            toast.success('Session deleted successfully', toastConfig);
            captureEvent('delete_session', {
                session_id: id,
            });
        } catch (error) {
            console.error('Error deleting Session:', error);
        }
        setLoading(false);
    };


    const handleSearch = (search: string) => {
        const params = new URLSearchParams({ search, page: '1' });
        navigate(`?${params.toString()}`);
    };

    useEffect(() => {
        if (selectedSessionId && selectedSessionStatus) {
            setSessions(prevSessions => 
                prevSessions.map(session => 
                    session.id === selectedSessionId 
                        ? { ...session, status: selectedSessionStatus }
                        : session
                )
            );
        }
    }, [selectedSessionId, selectedSessionStatus]);

    return (
        <div className={`pt-4 flex flex-row w-1/4 dark:bg-slate-900 px-4`}>
            <div className={`w-full grow`}>
                <div className='mx-auto rounded-md flex flex-row'>
                    <div className="relative flex w-full h-12 rounded-lg bg-white border dark:bg-slate-800 dark:border-slate-700">
                        <div className="grid place-items-center h-full w-12 text-slate-300 dark:border-none dark:border-slate-900">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </div>
                        <input
                            className="h-full w-full border-0 text-sm text-slate-700 pr-2 focus:outline-none focus:ring-1 rounded-r-lg focus:ring-blue-200 focus:border-transparent dark:bg-slate-800 dark:text-slate-300 dark:focus:ring-1 dark:focus:ring-slate-600"
                            type="text"
                            id="search"
                            placeholder="Search sessions by lead..."
                            autoComplete='off'
                            value={searchInput}
                            onChange={(e: any) => setSearchInput(e.target.value)}
                            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') {
                                    handleSearch(e.currentTarget.value);
                                }
                            }}
                        />

                    </div>
                </div>
                <div className="flex flex-col mt-8 space-y-4 max-h-[calc(100vh-100px)] overflow-y-auto scrollbar-hidden pb-12">
                    {sessions.length > 0 && !loading &&
                        sessions.map((session: any, index: number) => (
                            <div
                                key={session.id}
                                className={`rounded-lg shadow-sm border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-500 transition-colors duration-200 ${
                                    selectedSessionId === session.id ? 'dark:bg-slate-900 bg-slate-50' : 'dark:bg-slate-800 bg-white'
                                }`}
                            >
                                <Link to={`/sessions/${session.id}`} className="block p-4">
                                    <div className="flex items-center justify-between">
                                        <div className="flex flex-col space-y-3">
                                            {/* Lead Info */}
                                            <div className="text-sm text-slate-600 dark:text-slate-200">
                                                {session.lead ? (
                                                    <span className="">
                                                        {session.lead.email}
                                                    </span>
                                                ) : 'No lead'}
                                            </div>

                                            {/* Date */}
                                            <div className="text-xs text-slate-400 dark:text-slate-500">
                                                {formatDate(session.created_at)}
                                            </div>
                                        </div>

                                        <div className="flex flex-col space-y-3 items-center">
                                            {/* Status Badge */}
                                            {session.status === 'active' ? (
                                                <div className="flex items-center space-x-1 bg-green-100 border-green-200 border animate-pulse text-green-800 text-xs rounded-lg py-1 px-2 dark:bg-green-700 dark:border-green-600 dark:text-green-200">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                                    </svg>
                                                    <span className="font-semibold">Active</span>
                                                </div>
                                            ) : (
                                                <div className="flex items-center space-x-1 bg-slate-100 border-slate-200 border text-slate-500 text-xs rounded-lg py-1 px-2 dark:bg-slate-800 dark:border-slate-600 dark:text-slate-400">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.412 15.655 9.75 21.75l3.745-4.012M9.257 13.5H3.75l2.659-2.849m2.048-2.194L14.25 2.25 12 10.5h8.25l-4.707 5.043M8.457 8.457 3 3m5.457 5.457 7.086 7.086m0 0L21 21" />
                                                    </svg>
                                                    <span className="font-semibold">Inactive</span>
                                                </div>
                                            )}

                                            {/* Delete Button */}
                                            <div className="flex justify-end w-full" onClick={(e) => e.preventDefault()}>
                                                <RowDelete deleteCallback={() => handleDelete(session.id, index)} />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}

                    {/* Empty State */}
                    {sessions.length === 0 && !loading && (
                        <div className="text-center py-16 text-slate-500 dark:text-slate-400">
                            No sessions
                        </div>
                    )}

                    {/* Loading State */}
                    {loading && (
                        <div className="text-center py-16">
                            <BeatLoader />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SessionsCard;