import React, { useEffect, useState, useRef } from 'react'
import useApi from '../api';

import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';

import { FormField, OwnerInterface, DocumentationInterface } from '../types'
import Spinner from '../documentation/Spinner';
import { useParams, useNavigate } from 'react-router-dom';
import Tools from './Tools';
import Links from './builder/Links';
import { HexColorPicker } from "react-colorful";
import GenericDropdown from '../ui/GenericDropdown';
import Sessions from './Sessions';
import Website from './builder/Website';
import { Tab, TabGroup, TabList, TabPanel } from '@headlessui/react';
import DeployAgent from './DeployAgent';
import FontDropdown from '../ui/FontDropdown';
import useAnalytics from '../analytics/analytics';
import Files from './builder/Files';
import PublishAgent from './PublishAgent';
import clsx from 'clsx';
import { Input, Field, Label, Switch, Description } from '@headlessui/react'
import { conLog } from '../utils/conLog';
import { Toast } from 'react-toastify/dist/components';
import { IconPicker } from '../ui/heroicons-picker/IconPicker';
import { IconList } from '../ui/heroicons-picker/iconType';
import Starters from './builder/Starters';
import Form from './builder/Form';
import { useUser } from '../user/UserContext';
interface EditorProps {
    docId?: string | undefined;
    agentId?: string | undefined;
    fields?: FormField[];
    modalRef?: any;
    toggleModal?: any
    setAgents?: any
    modalVisible?: boolean
}

export default function ConfigureAgent({ docId, agentId, fields, modalRef, toggleModal, setAgents, modalVisible }: EditorProps) {
    const { id } = useParams();
    const [doc, setDoc] = useState<DocumentationInterface | undefined>();
    const [docFields, setDocFields] = useState<FormField[] | undefined>(fields ? fields : []);
    const [agId, setAgId] = useState<string | undefined>(() => {
        if (agentId) {
            return agentId;
        } else {
            if (window.location.pathname.includes('agents')) {
                return id;
            }
        }
    });
    const [content, setContent] = useState<any>('');
    const [tools, setTools] = useState<any>([]);
    const [links, setLinks] = useState<any>([]);
    const [starters, setStarters] = useState<any>([]);
    const [agentName, setAgentName] = useState<string>('');
    const [agentDescription, setAgentDescription] = useState<string>('');
    const [goal, setGoal] = useState<string>('');
    const [persona, setPersona] = useState<string>('');
    const [userInput, setUserInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedFields, setSelectedFields] = useState<FormField[] | undefined>([]);
    const [editName, setEditName] = useState(false);
    const [needsUpdate, setNeedsUpdate] = useState(false);
    const [file, setFile] = useState(null);
    const [iconFile, setIconFile] = useState(null);
    const [fileChanged, setFileChanged] = useState(false);
    const [iconFileChanged, setIconFileChanged] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [iconPreviewUrl, setIconPreviewUrl] = useState('');
    const [type, setType] = useState<string | undefined>('Outreach');
    const [color, setColor] = useState("#000000");
    const [endColor, setEndColor] = useState("#000000");
    const [isColorValid, setIsColorValid] = useState(true);
    const [isEndColorValid, setIsEndColorValid] = useState(true);
    const [website, setWebsite] = useState<string>('');
    const [indexedPages, setIndexedPages] = useState<any[]>([]);
    const [selectedPages, setSelectedPages] = useState<Set<string>>(new Set());
    const [colorScheme, setColorScheme] = useState<string>('Solid');
    const [welcomeMessage, setWelcomeMessage] = useState<string>('Welcome to our website! How can I help you today?');
    const [font, setFont] = useState<string>('open-sans');
    const [refreshPreview, setRefreshPreview] = useState<boolean>(false);
    const [autoOpenEnabled, setAutoOpenEnabled] = useState<boolean>(false);
    const [openOncePerSession, setOpenOncePerSession] = useState<boolean>(false);
    const [enableFollowUps, setEnableFollowUps] = useState<boolean>(false);
    const [followUpInstructions, setFollowUpInstructions] = useState<string>('');
    const [enableVisitorLocation, setEnableVisitorLocation] = useState<boolean>(false);
    const [enablePageAwareness, setEnablePageAwareness] = useState<boolean>(false);
    const [autoOpenSeconds, setAutoOpenSeconds] = useState<number>(0);
    const [popupMessageEnabled, setPopupMessageEnabled] = useState<boolean>(false);
    const [contextualPopupEnabled, setContextualPopupEnabled] = useState<boolean>(true);
    const [contextualStartersEnabled, setContextualStartersEnabled] = useState<boolean>(true);
    const [hideAimdoc, setHideAimdoc] = useState<boolean>(false);
    const [popupMessage, setPopupMessage] = useState<string>('');
    const [agentIconType, setAgentIconType] = useState<string>('Icon');
    const [agentIcon, setAgentIcon] = useState<IconList>('FireIcon');
    const [draftId, setDraftId] = useState<string | undefined>();
    const [form, setForm] = useState<Record<string, any>>({});
    const { get, getStream, post, postStream, put, patch, remove } = useApi();

    const navigate = useNavigate();
    const { captureEvent } = useAnalytics();
    const { user, organization, checkLicenseType } = useUser();

    // get search params
    const search = new URLSearchParams(window.location.search);
    const newAgent = search.get('new');

    const createAgent = async (silent?: boolean) => {
        const colors = compareColors(color, endColor);
        if (agentName === "") {
            toast.error('Agent name is required.', toastConfig);
            return;
        }
        if (isColorValid === false) {
            toast.error('First color is not valid.', toastConfig);
            return;
        };
        if (isEndColorValid === false) {
            toast.error('Second color is not valid.', toastConfig);
            return;
        }
        if (agentIconType === 'Image' && !iconPreviewUrl) {
            toast.error('Please upload an icon image.', toastConfig);
            return;
        }
        console.log(form)
        const data = {
            document_id: docId ? docId : doc?.id,
            fields: selectedFields,
            name: agentName,
            description: agentDescription,
            goal: goal,
            type: type ? type : "Outreach",
            persona: persona,
            tools: tools,
            links: links,
            starters: starters,
            config: {
                font: font,
                scheme: colorScheme,
                color: color,
                endColor: endColor,
                mixedColor: mixColors(color, endColor),
                darkerColor: colors.darkerColor,
                lighterColor: colors.lighterColor,
                welcomeMessage: welcomeMessage,
                autoOpenEnabled: autoOpenEnabled,
                autoOpenSeconds: autoOpenSeconds,
                openOncePerSession: openOncePerSession,
                popupMessageEnabled: popupMessageEnabled,
                popupMessage: popupMessage,
                contextualPopupEnabled: contextualPopupEnabled,
                contextualStartersEnabled: contextualStartersEnabled,
                agentMessageIconType: agentIconType,
                agentMessageIcon: agentIcon,
                enableFollowUps: enableFollowUps,
                followUpInstructions: followUpInstructions,
                enableVisitorLocation: enableVisitorLocation,
                enablePageAwareness: enablePageAwareness,
                hideAimdoc: hideAimdoc,
                form: form,
            },
            website

        }
        conLog(data)

        try {
            if (agId) {
                put(`/agents/${agId}`, data)
                    .then(async (response) => {
                        if (previewUrl === "") {
                            deleteLogo();
                        } else {
                            uploadLogo(agId, file);
                        }
                        if (iconPreviewUrl === "") {
                            deleteIcon();
                        } else {
                            uploadIcon(agId, iconFile);
                        }
                        setLoading(false);
                        if (!silent && toggleModal) {
                            toggleModal();
                        }
                        toast.success(`${agentName} succesfully updated.`, toastConfig);
                        captureEvent('agent_updated', {
                            agent_id: response.data.id,
                            agent_name: agentName,
                        });
                    })
                    .catch(error => {
                        console.error('Error creating agent:', error);
                        toast.error(`${agentName} failed to update.`, toastConfig);
                        setLoading(false);
                    });
            } else {
                data.config.form = [
                    {
                        name: 'first_name',
                        label: 'First Name',
                        type: 'text',
                        required: true,
                    },
                    {
                        name: 'last_name',
                        label: 'Last Name',
                        type: 'text',
                        required: true,
                    },
                    {
                        name: 'email',
                        label: 'Email',
                        type: 'email',
                        required: true,
                    }
                ];
                post('/agents/', data)
                    .then(async (response) => {
                        uploadLogo(response.data.id, file);
                        uploadIcon(response.data.id, iconFile);
                        setLoading(false);
                        if (!silent && toggleModal) {
                            toggleModal();
                        }
                        if (docId) {
                            console.log(response.data)
                            setAgId(response.data.id);
                            setAgents((prev: any) => [...prev, response.data]);
                        } else {
                            navigate(`/agents/${response.data.id}`);
                        }

                        toast.success(`${agentName} succesfully created.`, toastConfig);
                        captureEvent('agent_created', {
                            agent_id: response.data.id,
                            agent_name: agentName,
                        });
                    })
                    .catch(error => {
                        console.error('Error creating agent:', error);
                        toast.error(`${error.detail}`, toastConfig);
                        setLoading(false);
                    });

            }

            setRefreshPreview(true);

        } catch (error) {
            toast.error(`${agentName} failed to update.`, toastConfig);
            console.error('Error creating content:', error);
        }

        setLoading(false);
        setUserInput('');
    };

    // set content if contentId is passed
    useEffect(() => {
        if (agId) {
            // call api to get content
            const fetchContent = async () => {
                setLoading(true)
                try {
                    const response = await get(`/agents/${agId}`);
                    const data = response.data;
                    console.log(data)
                    conLog(data)
                    setDraftId(data.id);
                    setContent(data.content);
                    setAgentName(data.name);
                    setAgentDescription(data.description);
                    setGoal(data.goal);
                    setPersona(data.persona);
                    setSelectedFields(data.fields);
                    setTools(data.tools ? data.tools : []);
                    setLinks(data.links ? data.links : []);
                    setStarters(data.starters ? data.starters : []);
                    setPreviewUrl(data.logo_url ? data.logo_url : '');
                    setIconPreviewUrl(data.message_icon_url ? data.message_icon_url : '');
                    setType(data.type);
                    setDocFields(data.document ? data.document.doc_schema : []);
                    setColorScheme(data.config.scheme);
                    setFont(data.config.font);
                    setDoc(data.document);
                    setColor(data.config.color);
                    setEndColor(data.config.endColor);
                    setWelcomeMessage(data.config.welcomeMessage);
                    setAutoOpenEnabled(data.config.autoOpenEnabled ? data.config.autoOpenEnabled : false);
                    setAutoOpenSeconds(data.config.autoOpenSeconds ? data.config.autoOpenSeconds : 0);
                    setOpenOncePerSession(data.config.openOncePerSession ? data.config.openOncePerSession : false);
                    setPopupMessageEnabled(data.config.popupMessageEnabled ? data.config.popupMessageEnabled : false);
                    setPopupMessage(data.config.popupMessage ? data.config.popupMessage : '');
                    setAgentIconType(data.config.agentMessageIconType ? data.config.agentMessageIconType : 'Icon');
                    setAgentIcon(data.config.agentMessageIcon ? data.config.agentMessageIcon : 'FireIcon');
                    setEnableFollowUps(data.config.enableFollowUps ? data.config.enableFollowUps : false);
                    setEnableVisitorLocation(data.config.enableVisitorLocation ? data.config.enableVisitorLocation : false);
                    setFollowUpInstructions(data.config.followUpInstructions ? data.config.followUpInstructions : '');
                    setHideAimdoc(data.config.hideAimdoc ? data.config.hideAimdoc : false);
                    setForm(data.config.form ? data.config.form : {});
                    setContextualPopupEnabled(data.config.contextualPopupEnabled ? data.config.contextualPopupEnabled : false);
                    setContextualStartersEnabled(data.config.contextualStartersEnabled ? data.config.contextualStartersEnabled : false);
                    setEnablePageAwareness(data.config.enablePageAwareness ? data.config.enablePageAwareness : false);
                } catch (error) {
                    console.error('Error fetching content:', error);
                }
                setLoading(false);
            };
            fetchContent();
        }
    }, [agId]);

    // default all fields to set if contentId is not passed
    useEffect(() => {
        if (!agId && id) {
            setSelectedFields(fields);
        }
    }, []);

    useEffect(() => {
        if (needsUpdate) {
            createAgent(true);
            setNeedsUpdate(false);
        };
    }, [needsUpdate]);

    useEffect(() => {
        if (doc) {
            setDocFields(doc.doc_schema);
        }
    }, [doc]);

    function handleLogoFile(event: any) {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
            setFileChanged(true);
        }
    }

    useEffect(() => {
        if (file) {
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);

            // Revoke the object URL on cleanup to avoid memory leaks
            return () => URL.revokeObjectURL(url);
        }
    }, [file]);

    function uploadLogo(id: string, file: any) {
        const formData = new FormData();
        if (file && fileChanged) {
            formData.append('file', file);  // Ensure 'logo' matches the backend expectation
            post(`/agents/${id}/logo`, formData)
                .then(response => {
                    conLog('Success');
                })
                .catch((error) => {
                    console.error('Error:', error.response.data);
                });
        }
    }

    const clearLogo = () => {
        setFile(null);
        setPreviewUrl('');
        setFileChanged(true);
    };

    const deleteLogo = () => {
        remove(`/agents/${agId}/logo`)
            .then(response => {
                setFile(null);
                setPreviewUrl('');
            })
            .catch((error) => {
                console.error('Error:', error.response.data);
            });
    };

    function handleIconFile(event: any) {
        const iconFile = event.target.files[0];
        if (iconFile) {
            setIconFile(iconFile);
            setIconFileChanged(true);
        }
    }

    useEffect(() => {
        if (iconFile) {
            const url = URL.createObjectURL(iconFile);
            setIconPreviewUrl(url);

            // Revoke the object URL on cleanup to avoid memory leaks
            return () => URL.revokeObjectURL(url);
        }
    }, [iconFile]);

    function uploadIcon(id: string, file: any) {
        const formData = new FormData();
        if (iconFile && iconFileChanged) {
            formData.append('file', file);  // Ensure 'logo' matches the backend expectation
            post(`/agents/${id}/message-icon`, formData)
                .then(response => {
                    conLog('Success');
                })
                .catch((error) => {
                    console.error('Error:', error.response.data);
                });
        }
    }

    const clearIcon = () => {
        setIconFile(null);
        setIconPreviewUrl('');
        setIconFileChanged(true);
    };

    const deleteIcon = () => {
        remove(`/agents/${agId}/message-icon`)
            .then(response => {
                setIconFile(null);
                setIconPreviewUrl('');
            })
            .catch((error) => {
                console.error('Error:', error.response.data);
            });
    };


    function mixColors(color1: string, color2: string, weight = 0.5) {
        // Convert hex to RGB
        let [r1, g1, b1] = hexToRgb(color1);
        let [r2, g2, b2] = hexToRgb(color2);

        // Mix colors
        let r = Math.round(r1 * (1 - weight) + r2 * weight);
        let g = Math.round(g1 * (1 - weight) + g2 * weight);
        let b = Math.round(b1 * (1 - weight) + b2 * weight);

        // Convert RGB back to hex
        return rgbToHex(r, g, b);
    }

    function hexToRgb(hex: any) {
        let r = parseInt(hex.slice(1, 3), 16);
        let g = parseInt(hex.slice(3, 5), 16);
        let b = parseInt(hex.slice(5, 7), 16);
        return [r, g, b];
    }

    function rgbToHex(r: number, g: number, b: number) {
        return "#" + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('');
    }

    function luminance(r: number, g: number, b: number) {
        // Use the formula for relative luminance under sRGB
        var a = [r, g, b].map(function (v) {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }

    function compareColors(hex1: string, hex2: string) {
        var rgb1 = hexToRgb(hex1);
        var rgb2 = hexToRgb(hex2);
        var lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
        var lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);

        if (lum1 < lum2) {
            return { darkerColor: hex1, lighterColor: hex2 };
        } else {
            return { darkerColor: hex2, lighterColor: hex1 };
        }
    }

    const handleColorChange = (setColorFunction: any, setValidityFunction: any) => (e: any) => {
        let value = e.target.value;

        if (!value.startsWith("#")) {
            value = `#${value}`;
        }

        const isValid = /^#[0-9A-F]{6}$/i.test(value) || /^#[0-9A-F]{3}$/i.test(value);

        setValidityFunction(isValid);
        setColorFunction(value);

    };

    const handleColorPickerChange = (setColorFunction: any, setValidityFunction: any) => (color: string) => {
        setValidityFunction(true);
        setColorFunction(color);
    };

    return (
        <>
            {agId && agentName === "" ? <Spinner /> :
                <div className="flex h-full w-full">
                    <div className={`flex flex-col h-full w-full border-slate-200 bg-white dark:bg-slate-900 dark:border-none ${agId ? '' : 'rounded-lg'}`}>
                        {!agId && (
                            <div className="flex items-center justify-between px-3 py-2 border-b dark:bg-slate-800 rounded-t-lg dark:border-none">
                                <button onClick={() => toggleModal()} type="button" data-tooltip-target="tooltip-fullscreen" className="p-2 text-slate-500 rounded cursor-pointer sm:ms-auto hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:rotate-180 transition duration-200">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                    <span className="sr-only">Close</span>
                                </button>
                                <div id="tooltip-fullscreen" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-slate-900 rounded-lg shadow-sm opacity-0 tooltip">
                                    Close
                                    <div className="tooltip-arrow" data-popper-arrow></div>
                                </div>
                            </div>
                        )}
                        {agId && (
                            <PublishAgent agId={agId} editName={editName} setEditName={setEditName} setAgentName={setAgentName} agentName={agentName} />
                        )}
                        <div className={`flex flex-row overflow-y-auto h-full`}>
                            <div className="flex flex-grow h-full overflow-hidden w-5/6">
                                <div className="flex flex-col h-full w-full">
                                    <div className="flex h-full">
                                        <div className={`pb-16 w-full h-full justify-center overflow-y-auto overflow-x-hidden scrollbar-thin p-8 shadow-lg`}>
                                            <div className="hover:shadow-sm hover:cursor-pointer rounded-lg grow w-1/2" onClick={() => setEditName(true)}>
                                                {!agId && (
                                                    <div className="">
                                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600">
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                autoComplete="off"
                                                                autoFocus={true}
                                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-slate-900 placeholder:text-slate-400 placeholder:text-md rounded-md focus:border-blue-300 focus:ring focus:ring-slate-200 text-2xl focus:ring-opacity-50 sm:leading-6 dark:bg-slate-800 dark:placeholder:text-slate-400 dark:text-slate-200 dark:border dark:border-slate-600"
                                                                placeholder="Enter you agent name..."
                                                                value={agentName || ''}
                                                                onChange={(e) => setAgentName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <TabGroup className={`${!agId ? 'mt-6' : ''}`}>
                                                <TabList className="flex gap-2">
                                                    <Tab className="rounded-md py-2 px-4 text-md font-semibold bg-slate-100 text-slate-600 border border-slate-200 focus:outline-none data-[selected]:text-white data-[selected]:bg-slate-500 data-[hover]:bg-slate-300 transition duration-200 dark:data-[selected]:bg-slate-700 dark:bg-slate-800 dark:text-slate-50 dark:data-[hover]:bg-slate-800/70 dark:border-slate-600">Persona & Goal</Tab>
                                                    {agId && (
                                                        <Tab className="rounded-md py-2 px-4 text-md font-semibold bg-slate-100 text-slate-600 border border-slate-200 focus:outline-none data-[selected]:text-white data-[selected]:bg-slate-500 data-[hover]:bg-slate-300 transition duration-200 dark:data-[selected]:bg-slate-700 dark:bg-slate-800 dark:text-slate-50 dark:data-[hover]:bg-slate-800/70 dark:border-slate-600">Knowledge</Tab>
                                                    )}
                                                    {agId && (
                                                        <Tab className="rounded-md py-2 px-4 text-md font-semibold bg-slate-100 text-slate-600 border border-slate-200 focus:outline-none data-[selected]:text-white data-[selected]:bg-slate-500 data-[hover]:bg-slate-300 transition duration-200 dark:data-[selected]:bg-slate-700 dark:bg-slate-800 dark:text-slate-50 dark:data-[hover]:bg-slate-800/70 dark:border-slate-600">Customization</Tab>
                                                    )}
                                                    {agId && (
                                                        <Tab className="rounded-md py-2 px-4 text-md font-semibold bg-slate-100 text-slate-600 border border-slate-200 focus:outline-none data-[selected]:text-white data-[selected]:bg-slate-500 data-[hover]:bg-slate-300 transition duration-200 dark:data-[selected]:bg-slate-700 dark:bg-slate-800 dark:text-slate-50 dark:data-[hover]:bg-slate-800/70 dark:border-slate-600">Sessions</Tab>
                                                    )}
                                                </TabList>
                                                <TabPanel>
                                                    <div className="hover:cursor-pointer rounded-lg">
                                                        <label className="block text-md font-medium leading-6 text-slate-900 mt-6 dark:text-slate-200">
                                                            Persona
                                                        </label>
                                                        <label className="block text-sm font-light leading-6 text-slate-400 mt-1 dark:text-slate-300">
                                                            This is the buyer persona you expect to be interacting with the agent. A more detailed description yields higher quality results. Example: "Pre-seed Startups, 1-5 employees, Automotive Industry".
                                                        </label>
                                                        <div className="mt-3">
                                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-slate-300 dark:bg-slate-800 dark:ring-slate-600">
                                                                <textarea
                                                                    name="persona"
                                                                    id="persona"
                                                                    autoComplete="off"
                                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-slate-900 placeholder:text-slate-400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-md focus:ring-opacity-50 sm:leading-6 dark:focus-ring-slate-500 dark:text-slate-200 dark:placeholder:text-slate-400"
                                                                    placeholder=""
                                                                    autoFocus={true}
                                                                    value={persona || ''}
                                                                    onChange={(e) => setPersona(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="hover:cursor-pointer rounded-lg">
                                                        <label className="block text-md font-medium leading-6 text-slate-900 mt-6 dark:text-slate-200">
                                                            Goal
                                                        </label>
                                                        <label className="block text-sm font-light leading-6 text-slate-400 mt-1 dark:text-slate-300">
                                                            How should your agent interact with prospects? What is your agent's personality? What should it try and achieve? Example: "Engage prospects in a friendly manner, provide information about our product, and schedule a demo."
                                                        </label>
                                                        <div className="mt-3">
                                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-slate-300 dark:bg-slate-800 dark:ring-slate-600">
                                                                <textarea
                                                                    name="goal"
                                                                    id="goal"
                                                                    autoComplete="off"
                                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-slate-900 placeholder:text-slate-400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-md focus:ring-opacity-50 sm:leading-6 dark:focus-ring-slate-500 dark:text-slate-200 dark:placeholder:text-slate-400"
                                                                    placeholder=""
                                                                    autoFocus={true}
                                                                    value={goal || ''}
                                                                    onChange={(e) => setGoal(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="mt-8 space-y-4">
                                                        <div className="p-6 bg-white shadow-sm border rounded-lg dark:bg-slate-800 dark:border-slate-700/50">
                                                            <label className="block text-xl font-semibold leading-6 text-slate-900 dark:text-slate-50">
                                                                Websites
                                                            </label>
                                                            <label className="block text-sm font-light leading-6 text-slate-500 mt-1 dark:text-slate-200">
                                                                Aimdoc will index all of the content on your website and use it to train your agent. This will allow your agent to answer questions about your product or service.
                                                            </label>
                                                            <Website agentId={agId} agentName={agentName} website={website} selectedPages={selectedPages} setSelectedPages={setSelectedPages} setWebsite={setWebsite} indexedPages={indexedPages} setIndexedPages={setIndexedPages} />
                                                        </div>
                                                        <div className="p-6 bg-white shadow-sm border rounded-lg dark:bg-slate-800 dark:border-slate-700/50">
                                                            <label className="block text-xl font-semibold leading-6 text-slate-900 dark:text-slate-50">
                                                                Files
                                                            </label>
                                                            <label className="block text-sm font-light leading-6 text-slate-500 mt-1 dark:text-slate-200">
                                                                Upload Word Documents and PDFs
                                                            </label>
                                                            {agId && (
                                                                <Files agentId={agId} agentName={agentName} />
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* <div className="mt-6">
                                                    <Tools tools={tools} addTool={addTool} deleteTool={deleteTool} />
                                                </div> */}
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className='mt-8 space-y-4'>
                                                        <div className="p-6 bg-white shadow-sm border rounded-lg dark:bg-slate-800 dark:border-slate-700/50">
                                                            <label className="block text-xl font-semibold leading-6 text-slate-900 dark:text-slate-50">
                                                                Appearance
                                                            </label>
                                                            <label className="block text-sm font-light leading-6 text-slate-500 mt-1 dark:text-slate-200">
                                                                Logo, colors, and font
                                                            </label>
                                                            <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 mb-4 dark:text-slate-100">
                                                                Logo
                                                            </label>
                                                            {previewUrl === "" && (
                                                                <div className="p-4 border rounded-lg shadow-sm w-1/3 dark:bg-slate-700 dark:border-none">
                                                                    <input
                                                                        type="file"
                                                                        accept="image/*"  // This restricts the file input to image files
                                                                        onChange={handleLogoFile}
                                                                        className="block w-full text-sm text-slate-500
                                                                        file:mr-4 file:py-2 file:px-4
                                                                        file:rounded file:border-0
                                                                        file:text-sm file:font-semibold
                                                                        file:bg-slate-100 file:text-slate-700
                                                                        hover:file:bg-slate-200 transition duration-200
                                                                        dark:file:bg-slate-600 dark:file:text-slate-50 dark:hover:file:bg-slate-600/50 transition duration-200 dark:text-slate-200"
                                                                    />
                                                                </div>
                                                            )}
                                                            {(file || previewUrl) && (
                                                                <div className="flex mt-4">
                                                                    {previewUrl && (
                                                                        <div className="flex flex-row justify-between p-4 rounded-lg shadow-md border bg-white">
                                                                            <img
                                                                                src={previewUrl}
                                                                                alt="Preview"
                                                                                style={{ maxWidth: '200px', maxHeight: '200px' }} // Limiting size to 200x200 pixels
                                                                                className="rounded-lg"
                                                                            />
                                                                            <div onClick={() => clearLogo()} className="hover:cursor-pointer">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-slate-400 hover:stroke-slate-800">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>

                                                                    )}
                                                                </div>
                                                            )}
                                                            <div className="mt-6">
                                                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mb-2 dark:text-slate-100">
                                                                    Font
                                                                </label>
                                                                <FontDropdown value={font} setValue={setFont} />
                                                            </div>
                                                            <div className="mt-6">
                                                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mb-2 dark:text-slate-100">
                                                                    Color Scheme
                                                                </label>
                                                                <GenericDropdown value={colorScheme} selectValues={["Solid", "Gradient"]} setValue={setColorScheme} defaultValue={'Solid'} />
                                                            </div>
                                                            <div className="flex flex-row space-x-8">
                                                                <div className="">
                                                                    <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-10 mb-4 dark:text-slate-100">
                                                                        Primary Color
                                                                    </label>
                                                                    <HexColorPicker color={color} onChange={handleColorPickerChange(setColor, setIsColorValid)} />
                                                                    <input
                                                                        type="text"
                                                                        className={`mt-4 flex rounded-lg w-1/2 border-slate-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-md sm:leading-6 dark:bg-slate-700 dark:text-slate-200 dark:border-none ${!isColorValid ? 'focus:ring-red-500' : ''}`}
                                                                        value={color}
                                                                        onChange={handleColorChange(setColor, setIsColorValid)}
                                                                    />
                                                                </div>
                                                                {colorScheme === "Gradient" && (
                                                                    <div>
                                                                        <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-10 mb-4 dark:text-slate-100">
                                                                            Gradient End Color
                                                                        </label>
                                                                        <HexColorPicker color={endColor} onChange={handleColorPickerChange(setEndColor, setIsEndColorValid)} />
                                                                        <input
                                                                            type="text"
                                                                            className={`mt-4 flex rounded-lg w-1/2 border-slate-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-md sm:leading-6 dark:bg-slate-700 dark:text-slate-200 dark:border-none ${!isEndColorValid ? 'focus:ring-red-500' : ''}`}
                                                                            value={endColor}
                                                                            onChange={handleColorChange(setEndColor, setIsEndColorValid)}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="p-6 bg-white shadow-sm border rounded-lg dark:bg-slate-800 dark:border-slate-700/50">
                                                            <label className="block text-xl font-semibold leading-6 text-slate-900 dark:text-slate-50">
                                                                Links
                                                            </label>
                                                            <label className="block text-sm font-light leading-6 text-slate-500 mt-1 dark:text-slate-200">
                                                                Links that will display in the side panel of the agent screen. Your visitors can click on these links.
                                                            </label>
                                                            <div className="mt-2">
                                                                <Links links={links} setLinks={setLinks} setNeedsUpdate={setNeedsUpdate} agId={agId} agentName={agentName} />
                                                            </div>
                                                        </div>
                                                        <div className="p-6 bg-white shadow-sm border rounded-lg dark:bg-slate-800 dark:border-slate-700/50">
                                                            <label className="block text-xl font-semibold leading-6 text-slate-900 dark:text-slate-50">
                                                                Conversation Starters
                                                            </label>
                                                            <label className="block text-sm font-light leading-6 text-slate-500 mt-1 dark:text-slate-200">
                                                                These are default conversation starters that will display below your welcome message. Your visitors can click on these starters to start a conversation.
                                                            </label>
                                                            <div className="mt-2">
                                                                <Starters
                                                                    starters={starters}
                                                                    setStarters={setStarters}
                                                                    setNeedsUpdate={setNeedsUpdate}
                                                                    agId={agId}
                                                                    agentName={agentName}
                                                                    enableFollowUps={enableFollowUps}
                                                                    setEnableFollowUps={setEnableFollowUps}
                                                                    followUpInstructions={followUpInstructions}
                                                                    setFollowUpInstructions={setFollowUpInstructions}
                                                                    contextualStartersEnabled={contextualStartersEnabled}
                                                                    setContextualStartersEnabled={setContextualStartersEnabled}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="p-6 bg-white shadow-sm border rounded-lg dark:bg-slate-800 dark:border-slate-700/50">
                                                            <label className="block text-xl font-semibold leading-6 text-slate-900 dark:text-slate-50">
                                                                Chat Form
                                                            </label>
                                                            <label className="block text-sm font-light leading-6 text-slate-500 mt-1 dark:text-slate-200">
                                                                This is the form that will display in the chat. Your visitors can fill out this form.
                                                            </label>
                                                            <div className="mt-2">
                                                                <Form
                                                                    form={form}
                                                                    setForm={setForm}
                                                                    setNeedsUpdate={setNeedsUpdate}
                                                                    agId={agId}
                                                                    agentName={agentName}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="p-6 bg-white shadow-sm border rounded-lg dark:bg-slate-800 dark:border-slate-700/50">
                                                            <label className="block text-xl font-semibold leading-6 text-slate-900 dark:text-slate-50">
                                                                Chat Settings
                                                            </label>
                                                            <label className="block text-sm font-light leading-6 text-slate-500 mt-1 dark:text-slate-200">
                                                                Welcome message, auto-open, and auto-open duration.
                                                            </label>
                                                            <div className="hover:cursor-pointer rounded-lg">
                                                                <label className="block text-md font-medium leading-6 text-slate-900 mt-6 dark:text-slate-200">
                                                                    Welcome Message
                                                                </label>
                                                                <label className="block text-sm font-light leading-6 text-slate-500 mt-1 dark:text-slate-200">
                                                                    This is the first message your agent will send to visitors. It should be friendly and informative. Example: "Hi! I'm your personal assistant. How can I help you today?"
                                                                </label>
                                                                <div className="mt-3">
                                                                    <div className="flex w-1/2 rounded-md shadow-sm ring-1 ring-inset ring-slate-300 dark:bg-slate-700 dark:ring-slate-600">
                                                                        <textarea
                                                                            name="welcome"
                                                                            id="welcome"
                                                                            autoComplete="off"
                                                                            className="block text-sm flex-1 border-0 bg-transparent py-1.5 pl-2 text-slate-900 placeholder:text-slate-400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-md focus:ring-opacity-50 sm:leading-6 dark:focus-ring-slate-500 dark:text-slate-200 dark:placeholder:text-slate-400"
                                                                            placeholder=""
                                                                            value={welcomeMessage || ''}
                                                                            onChange={(e) => setWelcomeMessage(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Field className='mt-4'>
                                                                <Label className="text-md font-medium dark:text-slate-100">Pop-up Message</Label>
                                                                <Description className="text-sm/6 text-slate-400 dark:text-slate-300">Default pop-up message shown next to the collapsed widget.</Description>
                                                                <Switch
                                                                    checked={popupMessageEnabled}
                                                                    onChange={setPopupMessageEnabled}
                                                                    className={`${popupMessageEnabled ? 'bg-green-400 dark:bg-green-700' : 'bg-slate-200 dark:bg-slate-700'
                                                                        } relative inline-flex h-6 w-11 items-center rounded-full mt-2`}
                                                                >
                                                                    <span
                                                                        className={`${popupMessageEnabled ? 'translate-x-6' : 'translate-x-1'
                                                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                    />
                                                                </Switch>
                                                                {popupMessageEnabled && (
                                                                    <div className="mt-3">
                                                                        <div className="flex w-1/2 rounded-md shadow-sm ring-1 ring-inset ring-slate-300 dark:bg-slate-700 dark:ring-slate-600">
                                                                            <textarea
                                                                                name="welcome"
                                                                                id="welcome"
                                                                                autoComplete="off"
                                                                                className="block text-sm flex-1 border-0 bg-transparent py-1.5 pl-2 text-slate-900 placeholder:text-slate-400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-md focus:ring-opacity-50 sm:leading-6 dark:focus-ring-slate-500 dark:text-slate-200 dark:placeholder:text-slate-400"
                                                                                placeholder=""
                                                                                value={popupMessage || ''}
                                                                                onChange={(e) => setPopupMessage(e.target.value)}
                                                                                maxLength={80}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Field>
                                                            {popupMessageEnabled && (
                                                                <Field className='mt-4'>
                                                                    <Label className="text-md font-medium dark:text-slate-100">Contextual Pop-ups</Label>
                                                                    <Description className="text-sm/6 text-slate-400 dark:text-slate-300">AI generated pop-ups based on the page the visitor is currently on. You can modify these in your website page settings.</Description>
                                                                    <Switch
                                                                        checked={contextualPopupEnabled}
                                                                        onChange={setContextualPopupEnabled}
                                                                        className={`${contextualPopupEnabled ? 'bg-green-400 dark:bg-green-700' : 'bg-slate-200 dark:bg-slate-700'
                                                                            } relative inline-flex h-6 w-11 items-center rounded-full mt-2`}
                                                                    >
                                                                        <span
                                                                            className={`${contextualPopupEnabled ? 'translate-x-6' : 'translate-x-1'
                                                                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                        />
                                                                    </Switch>
                                                                </Field>
                                                            )}
                                                            <Field className='mt-4'>
                                                                <Label className="text-md font-medium dark:text-slate-100">Auto-open</Label>
                                                                <Description className="text-sm/6 text-slate-400 dark:text-slate-300">The agent will automatically open after the specificied duration.</Description>
                                                                <div className="flex flex-row items-center mt-2">
                                                                    <Switch
                                                                        checked={autoOpenEnabled}
                                                                        onChange={setAutoOpenEnabled}
                                                                        className={`${autoOpenEnabled ? 'bg-green-400 dark:bg-green-700' : 'bg-slate-200 dark:bg-slate-700'
                                                                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                                                                    >
                                                                        <span
                                                                            className={`${autoOpenEnabled ? 'translate-x-6' : 'translate-x-1'
                                                                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                        />
                                                                    </Switch>
                                                                    {autoOpenEnabled && (
                                                                        <div className="flex flex-row items-center ml-2">
                                                                            <Input
                                                                                type="number"
                                                                                value={autoOpenSeconds}
                                                                                onChange={(e) => setAutoOpenSeconds(Number(e.target.value))}
                                                                                className={clsx(
                                                                                    'block w-16 text-center bg-slate-50 py-1.5 px-3 text-sm/6 text-slate-900 border-slate-200 rounded-lg',
                                                                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25 dark:bg-slate-700 dark:border-none dark:text-slate-200'
                                                                                )}
                                                                            />
                                                                            <div className="flex ml-2 text-slate-400 text-xs">
                                                                                seconds
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Field>
                                                            {autoOpenEnabled && (
                                                                <Field className='mt-4'>
                                                                    <Label className="text-md font-medium dark:text-slate-100">Auto-open Once Per Session</Label>
                                                                    <Description className="text-sm/6 text-slate-400 dark:text-slate-300">Only auto-open once per visitor session.</Description>
                                                                    <div className="flex flex-row items-center mt-2">
                                                                        <Switch
                                                                            checked={openOncePerSession}
                                                                            onChange={setOpenOncePerSession}
                                                                            className={`${openOncePerSession ? 'bg-green-400 dark:bg-green-700' : 'bg-slate-200 dark:bg-slate-700'
                                                                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                                                                        >
                                                                            <span
                                                                                className={`${openOncePerSession ? 'translate-x-6' : 'translate-x-1'
                                                                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                            />
                                                                        </Switch>
                                                                    </div>
                                                                </Field>
                                                            )}
                                                            <div className="flex flex-row space-x-6">
                                                                <Field className='mt-4'>
                                                                    <Label className="text-md font-medium dark:text-slate-100">Agent Icon Type</Label>
                                                                    <Description className="text-sm/6 text-slate-400 dark:text-slate-300">Select an icon or upload an image</Description>
                                                                    <div className="flex flex-row items-center mt-2">
                                                                        <GenericDropdown value={agentIconType} selectValues={["Icon", "Image"]} setValue={setAgentIconType} defaultValue={'Icon'} />
                                                                    </div>
                                                                </Field>
                                                                {agentIconType === "Icon" && (
                                                                    <Field className=''>
                                                                        <div className="flex flex-row items-center mt-2">
                                                                            <IconPicker
                                                                                onChange={(icon) => setAgentIcon(icon)}
                                                                                library={'solid'}
                                                                                value={agentIcon}
                                                                            />
                                                                        </div>
                                                                    </Field>
                                                                )}
                                                                {agentIconType === "Image" && (
                                                                    <div>
                                                                        {iconPreviewUrl === "" && (
                                                                            <Field className=''>
                                                                                <div className="p-4 border rounded-lg shadow-sm dark:bg-slate-700 dark:border-none">
                                                                                    <input
                                                                                        type="file"
                                                                                        accept="image/*"  // This restricts the file input to image files
                                                                                        onChange={handleIconFile}
                                                                                        className="block w-full text-sm text-slate-500
                                                                            file:mr-4 file:py-2 file:px-4
                                                                            file:rounded file:border-0
                                                                            file:text-sm file:font-semibold
                                                                            file:bg-slate-100 file:text-slate-700
                                                                            hover:file:bg-slate-200 transition duration-200
                                                                            dark:file:bg-slate-600 dark:file:text-slate-50 dark:hover:file:bg-slate-600/50 transition duration-200 dark:text-slate-200"
                                                                                    />
                                                                                </div>
                                                                            </Field>
                                                                        )}
                                                                        {iconPreviewUrl && (
                                                                            <div className="flex flex-row justify-between p-4 rounded-3xl shadow-md border bg-white">
                                                                                <img
                                                                                    src={iconPreviewUrl}
                                                                                    alt="Preview"
                                                                                    style={{ maxWidth: '50px', maxHeight: '50px' }} // Limiting size to 200x200 pixels
                                                                                    className="rounded-lg"
                                                                                />
                                                                                <div onClick={() => clearIcon()} className="hover:cursor-pointer">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-slate-400 hover:stroke-slate-800">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>

                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <Field className='mt-4'>
                                                                <Label className="text-md font-medium dark:text-slate-100">Location Enhanced Response</Label>
                                                                <Description className="text-sm/6 text-slate-400 dark:text-slate-300">Enable this to have Aimdoc include location in its data search. It is recommended you leave this off unless your business is location-based.</Description>
                                                                <Switch
                                                                    checked={enableVisitorLocation}
                                                                    onChange={setEnableVisitorLocation}
                                                                    className={`${enableVisitorLocation ? 'bg-green-400 dark:bg-green-700' : 'bg-slate-200 dark:bg-slate-700'
                                                                        } relative inline-flex h-6 w-11 items-center rounded-full mt-2`}
                                                                >
                                                                    <span
                                                                        className={`${enableVisitorLocation ? 'translate-x-6' : 'translate-x-1'
                                                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                    />
                                                                </Switch>
                                                            </Field>
                                                            <Field className='mt-4'>
                                                                <Label className="text-md font-medium dark:text-slate-100">{`Page Awareness ${checkLicenseType('pro') ? '' : ' (Upgrade to Pro)'}`}</Label>
                                                                <Description className="text-sm/6 text-slate-400 dark:text-slate-300">This will give Aimdoc awareness of the current page the visitor is on. This is particularly useful for authenticated web experiences.</Description>
                                                                <Switch
                                                                    checked={enablePageAwareness}
                                                                    onChange={setEnablePageAwareness}
                                                                    disabled={!checkLicenseType('pro')}
                                                                    className={`${enablePageAwareness ? 'bg-green-400 dark:bg-green-700' : 'bg-slate-200 dark:bg-slate-700'
                                                                        } relative inline-flex h-6 w-11 items-center rounded-full mt-2`}
                                                                >
                                                                    <span
                                                                        className={`${enablePageAwareness ? 'translate-x-6' : 'translate-x-1'
                                                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                    />
                                                                </Switch>
                                                            </Field>
                                                            <Field className='mt-4'>
                                                                <Label className="text-md font-medium dark:text-slate-100">{`Hide Aimdoc Branding ${!checkLicenseType('pro') ? ' (Upgrade to Pro)' : ''}`}</Label>
                                                                <Description className="text-sm/6 text-slate-400 dark:text-slate-300">Hides "Powered by Aimdoc" in the bottom of your chat.</Description>
                                                                <Switch
                                                                    checked={hideAimdoc}
                                                                    onChange={setHideAimdoc}
                                                                    disabled={!checkLicenseType('pro')}
                                                                    className={`${hideAimdoc ? 'bg-green-400 dark:bg-green-700' : 'bg-slate-200 dark:bg-slate-700'
                                                                        } relative inline-flex h-6 w-11 items-center rounded-full mt-2`}
                                                                >
                                                                    <span
                                                                        className={`${hideAimdoc ? 'translate-x-6' : 'translate-x-1'
                                                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                    />
                                                                </Switch>
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    {agId && (
                                                        <div className="">
                                                            <Sessions agentId={agId} />
                                                        </div>
                                                    )}
                                                </TabPanel>
                                            </TabGroup>
                                            <div className="flex w-full justify-end mt-8">
                                                <button
                                                    onClick={() => createAgent()}
                                                    type="submit"
                                                    className="rounded-md bg-blue-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:bg-slate-500 dark:hover:bg-slate-600 dark:focus-visible:outline-slate-500"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>

                            </div>
                            {agId && (
                                <DeployAgent agId={agId} draftId={draftId} refresh={refreshPreview} setRefreshPreview={setRefreshPreview} />
                            )}
                        </div>
                    </div>
                </div>
            }
        </>
    );
}