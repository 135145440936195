import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { UserProvider } from './components/user/UserContext';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import './App.css';
import Main from './components/Main';

// Initialize PostHog once, outside of the component
posthog.init(
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY || '',
  {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
  }
);

function App() {
  return (
    <UserProvider>
      <PostHogProvider client={posthog}>
        <Router>
          <Main />
        </Router>
      </PostHogProvider>
    </UserProvider>
  );
}

export default App;
