import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement as HTMLElement);
    root.render(
            <App />
    );
}

// Add Rewardful scripts
(function(w: any, r: any) {
    w._rwq = r;
    w[r] = w[r] || function() {
        (w[r].q = w[r].q || []).push(arguments);
    };
})(window, 'rewardful');

// Create and append the async script tag
const rewardfulScript = document.createElement('script');
rewardfulScript.src = 'https://r.wdfl.co/rw.js';
rewardfulScript.async = true;
rewardfulScript.setAttribute('data-rewardful', '6e1ec2');
document.body.appendChild(rewardfulScript);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();