import { Fragment } from 'react'
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { FilterInterface, OnFilterChange } from '../types'

interface FilterComponentProps {
    filters: FilterInterface[];
    onFilterChange: OnFilterChange;
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const Filter: React.FC<FilterComponentProps> = ({ filters, onFilterChange }) => {
    const handleCheckboxChange = (sectionId: string, optionValue: string, checked: boolean) => {
        const newFilters = filters.map(section => {
            if (section.id === sectionId) {
                return {
                    ...section,
                    options: section.options.map(option => {
                        if (option.value === optionValue) {
                            return { ...option, checked };
                        }
                        return option;
                    }),
                };
            }
            return section;
        });
        onFilterChange(newFilters);
    };

    return (
        <div className="bg-white pt-12 dark:bg-slate-900">
            <div>
                <main className="mx-auto px-4 sm:px-6 lg:px-8">
                    <section aria-labelledby="products-heading" className="pb-24 pt-6">
                        <h2 id="products-heading" className="sr-only">
                            Products
                        </h2>
                        <div className="">
                            {/* Filters */}
                            <div className="hidden lg:block pt-1">
                                <h3 className="sr-only">Categories</h3>
                                {filters.map((section) => (
                                    <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                                        {({ open }) => (
                                            <>
                                                <h3 className="-my-3 flow-root">
                                                    <DisclosureButton className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500 dark:bg-slate-900">
                                                        <span className="font-medium text-gray-600 hover:text-gray-900 dark:text-slate-200">{section.name}</span>
                                                        <span className="ml-6 flex items-center">
                                                            {open ? (
                                                                <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                                            ) : (
                                                                <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                                            )}
                                                        </span>
                                                    </DisclosureButton>
                                                </h3>
                                                <DisclosurePanel className="pt-6">
                                                    <div className="space-y-4">
                                                        {section.options.map((option, optionIdx) => (
                                                            <div key={option.value} className="flex items-center">
                                                                <input
                                                                    id={`filter-${section.id}-${optionIdx}`}
                                                                    name={`${section.id}[]`}
                                                                    value={option.value}
                                                                    type="checkbox"
                                                                    checked={option.checked}
                                                                    onChange={(e) => handleCheckboxChange(section.id, option.value, e.target.checked)}
                                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 dark:text-slate-700"
                                                                />
                                                                <label
                                                                    htmlFor={`filter-${section.id}-${optionIdx}`}
                                                                    className="ml-3 text-sm text-gray-600 dark:text-slate-300"
                                                                >
                                                                    {option.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </DisclosurePanel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>

    )
}

export default Filter;

