import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import useApi from '../../api';
import { conLog } from '../../utils/conLog';
import { ToastContainer, toast } from 'react-toastify';
import toastConfig from '../../config/Toast';
import BeatLoader from '../../ui/BeatLoader';


export default function SelectFiles({ agentId, setShowFiles, setFilesSelected }: { agentId: string, setShowFiles: any, setFilesSelected: any }) {
  const [isOpen, setIsOpen] = useState(true);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set()); // Use Set to store selected file IDs
  const { get, post } = useApi();

  function closeModal() {
    setIsOpen(false);
    setShowFiles(false); // Call setShowFiles if needed to notify parent that modal is closed
  }

  function openModal() {
    setIsOpen(true);
  }

  function toggleFileSelection(fileId: string) {
    setSelectedFiles((prevSelectedFiles) => {
      const updatedSelectedFiles = new Set(prevSelectedFiles);
      if (updatedSelectedFiles.has(fileId)) {
        updatedSelectedFiles.delete(fileId); // Uncheck the file if already selected
      } else {
        updatedSelectedFiles.add(fileId); // Check the file
      }
      return updatedSelectedFiles;
    });
  }

  // Call this function to associate files with an agent
  function associateFilesWithAgent() {
    const selectedFilesArray = Array.from(selectedFiles); // Convert Set to Array
    console.log('Selected files:', selectedFilesArray);

    post(`/agents/${agentId}/files`, { file_ids: selectedFilesArray })
      .then((response) => {
        console.log('Files associated successfully:', response.data);
        setFilesSelected(selectedFilesArray); // Update parent component with selected files
        setShowFiles(false); 
        closeModal(); // Close modal on successful submit
        toast.success('Files associated successfully', toastConfig);
      })
      .catch((error) => {
        console.error('Error associating files:', error);
        // Handle the error, show feedback to the user (e.g., error message)
      });
  }

  useEffect(() => {
    if (!isOpen) return;

    // Prepare the URL with the exclude_agent_id parameter
    const url = agentId ? `/files/?exclude_agent_id=${agentId}` : '/files/';

    get(url)
      .then((response) => {
        conLog(response.data);
        setFiles(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching files:', error);
        // Handle error if needed (e.g., display a message)
      });
  }, [isOpen, agentId]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                  <Dialog.Title
                    as="h3"
                    className="text-xl text-center font-medium leading-6 text-slate-900 mb-4 dark:text-slate-50"
                  >
                    Select Files
                  </Dialog.Title>

                  <div className="overflow-y-auto max-h-96 space-y-3">
                    {files && files.length > 0 && (
                      files.map((file: any) => (
                        <div key={file.id} className="flex items-center space-x-3 px-2 py-2 mx-2 rounded-lg border border-slate-200 bg-slate-50 dark:bg-slate-700 dark:border-slate-600">
                          <input
                            type="checkbox"
                            id={file.id}
                            checked={selectedFiles.has(file.id)}
                            onChange={() => toggleFileSelection(file.id)}
                            className="form-checkbox h-5 w-5 text-slate-600 border-slate-200 dark:bg-slate-800 rounded-md"
                          />
                          <label htmlFor={file.id} className="text-slate-600 dark:text-slate-200 text-sm break-all">
                            {file.name}
                          </label>
                        </div>
                      ))
                    )}
                    {loading && (
                        <BeatLoader />
                    )}
                    {files.length === 0 && !loading && (
                        <p className="text-slate-500 dark:text-slate-300">No files available</p>
                    )}
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      disabled={selectedFiles.size === 0} // Disable if no files selected
                      onClick={associateFilesWithAgent}
                      className={`inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ${selectedFiles.size === 0 ? 'bg-slate-400 dark:bg-slate-900' : 'bg-blue-600 dark:bg-slate-900 hover:bg-blue-700 dark:hover:bg-slate-900/50'} transition duration-200`}
                    >
                      Select Files
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}