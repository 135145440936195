import React, { useState, useEffect, useRef } from 'react';
import { Field, Input, Label, Textarea, Button } from '@headlessui/react';
import clsx from 'clsx';
import { ToastContainer, toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';

interface ChatInputProps {
    sendMessage: (message: string) => void;
    config: any;
    gettingFullResponse: boolean;
    setEmail: (email: string) => void;
    setCompany: (company: string) => void;
    setFirstName: (firstName: string) => void;
    setLastName: (lastName: string) => void;
    setFormSubmitted: (submitted: boolean) => void;
    leadId: string | undefined;
    fullScreen: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ sendMessage, config, gettingFullResponse, setEmail, setCompany, setFirstName, setLastName, setFormSubmitted, leadId, fullScreen }) => {
    const [input, setInput] = useState('');
    const [leadFirstName, setLeadFirstName] = useState('');
    const [leadLastName, setLeadLastName] = useState('');
    const [leadCompany, setLeadCompany] = useState('');
    const [leadEmail, setLeadEmail] = useState('');
    const [showForm, setShowForm] = useState(false);

    const leadEmailRef = useRef('');
    leadEmailRef.current = leadEmail;
    const leadFirstNameRef = useRef('');
    leadFirstNameRef.current = leadFirstName;
    const leadLastNameRef = useRef('');
    leadLastNameRef.current = leadLastName;
    const leadCompanyRef = useRef('');
    leadCompanyRef.current = leadCompany;

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (input === '') {
            return;
        }
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage(input);
            setInput('');
        }
    };

    const handleEnterEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            validateAndSubmitEmail();
        }
    };

    const handleClick = () => {
        if (input === '') {
            return;
        }
        sendMessage(input);
        setInput('');
    };

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [input]);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
        }
    }, []);

    const validateAndSubmitEmail = () => {
        if (leadEmail === '') {
            toast.error('Please enter an email address');
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(leadEmail)) {
            toast.error('Please enter a valid email address');
            return;
        }
        setEmail(leadEmailRef.current);
        setFirstName(leadFirstNameRef.current);
        setLastName(leadLastNameRef.current);
        setCompany(leadCompanyRef.current);
        setFormSubmitted(true);
        setShowForm(false);
    };

    return (
        <div className={`sticky bottom-0 z-30 pt-6 w-full`}>
            <ToastContainer {...toastConfig} />
            {!leadId && !fullScreen && (
                <div className="flex flex-row items-center rounded-xl bg-white/0 pr-1 w-full mb-3 justify-center">
                    <div className="flex flex-col w-full max-w-6xl">
                        <div className={`overflow-hidden transition-max-height duration-1000 ease-in-out ${showForm ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}>
                            {showForm && (
                                <div className="pl-6 pr-6 pb-2">
                                    <div className="flex justify-end">
                                    <div onClick={() => setShowForm(false)} className="flex items-center justify-end hover:cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-slate-600 hover:stroke-slate-900 hover:rotate-180 transform duration-200">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                    </div>
                                    <Field>
                                        <Label className="text-sm/6 font-medium text-slate-600">First Name</Label>
                                        <Input
                                            className={clsx(
                                                'block w-full rounded-lg border border-slate-200 py-1 px-3 text-sm/6 text-slate-900',
                                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                            )}
                                            onChange={(e) => setLeadFirstName(e.target.value)}
                                        />
                                    </Field>
                                    <Field>
                                        <Label className="text-sm/6 font-medium text-slate-600">Last Name</Label>
                                        <Input
                                            className={clsx(
                                                'block w-full rounded-lg border border-slate-200 py-1 px-3 text-sm/6 text-slate-900',
                                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                            )}
                                            onChange={(e) => setLeadLastName(e.target.value)}
                                        />
                                    </Field>
                                    {/* <Field>
                                        <Label className="text-sm/6 font-medium text-white">Company</Label>
                                        <Input
                                            className={clsx(
                                                'block w-full rounded-lg border-none bg-white/20 py-1.5 px-3 text-sm/6 text-white',
                                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                            )}
                                            onChange={(e) => setLeadCompany(e.target.value)}
                                        />
                                    </Field> */}
                                    <Field>
                                        <Label className="text-sm/6 font-medium text-slate-600">Email</Label>
                                        <Input
                                            className={clsx(
                                                'block w-full rounded-lg border border-slate-200 py-1 px-3 text-sm/6 text-slate-900',
                                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                            )}
                                            onChange={(e) => setLeadEmail(e.target.value)}
                                            required={true}
                                            onKeyDown={handleEnterEmail}
                                        />
                                    </Field>
                                </div>
                            )}
                        </div>
                        <div className="pl-6 pr-6"> 
                        {showForm ? (
                            <Button
                                onClick={() => validateAndSubmitEmail()}
                                className="mt-4 w-full shrink-0 items-center rounded-lg border bg-slate-50/50 border-slate-200 py-1 px-3 text-sm/6 font-semibold text-slate-600 focus:outline-none data-[hover]:bg-slate-50/90 data-[open]:bg-slate-700 data-[focus]:outline-1 data-[focus]:outline-white transition duration-200"
                            >
                                Submit
                            </Button>
                        ) : (
                            <Button
                                onClick={() => setShowForm(!showForm)}
                                className="w-full shrink-0 items-center rounded-lg border bg-slate-50/50 border-slate-200 py-1 px-3 text-sm/6 font-semibold text-slate-600 focus:outline-none data-[hover]:bg-slate-50/90 data-[open]:bg-slate-700 data-[focus]:outline-1 data-[focus]:outline-white transition duration-200"
                            >
                                Get more information!
                            </Button>
                        )}
                        </div>
                    </div>
                </div>
            )}
            <div className="mx-auto flex flex-row max-w-6xl pl-6 pr-6">
                <div className="relative flex w-full rounded-lg items-center bg-white border border-slate-200">
                    <Textarea
                        ref={textareaRef}
                        className="w-full bg-white/90 mx-1 my-1 p-1 border-none text-sm placeholder:text-sm text-slate-900 focus:outline-none focus:ring-0 rounded-l-xl focus:ring-blue-200 focus:border-transparent resize-none overflow-hidden"
                        id="message"
                        placeholder="Type your message here..."
                        autoComplete="off"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                        rows={1}
                    />
                </div>
                <div className="bg-white/90 flex pl-2 rounded-lg">
                        <button onClick={handleClick} disabled={gettingFullResponse} style={{ backgroundColor: `${config.color}`, opacity: 0.6 }} className="flex items-center justify-center h-full text-gray-300 text-white rounded-lg hover:cursor-pointer hover:scale-105 transition duration-200 px-2 py-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-6 stroke-neutral-50">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                            </svg>
                        </button>
                    </div>
            </div>
            <div className="flex justify-center mt-3 pl-6 pr-6 bg-slate-50 py-1">
                <a target="_blank" href="https://aimdoc.ai" className="text-xs text-slate-700 font-light hover:scale-110 transform duration-200">
                    Powered by Aimdoc
                </a>
            </div>
        </div>
    );
};

export default ChatInput;
