import React, { useState, useEffect, useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Message from '../documentation/Message';
import Spinner from '../documentation/Spinner';
import useApi from '../api';
import { ConversationProps, MessageType, AssistantRequest, AssistantResponse } from '../types';
import { useParams } from 'react-router-dom';
import { set, uniq, values } from 'lodash';
import { ReactComponent as Logo } from '../../logo_main.svg';
import AgentPanel from './AgentPanel';
import AgentLoader from '../ui/AgentLoader';
import ChatInput from '../chat/ChatInput';

const AgentFullScreen = ({ agentId, closeModal, sidebar, setSidebar, embedConfig, agentLinks, lastConvId, lastMessages, existLeadId, visitorId, welcomeMessage }: { agentId?: string, closeModal?: any, sidebar?: boolean, setSidebar?: any, embedConfig?: any, agentLinks?: any, lastConvId?: any, lastMessages?: MessageType[], existLeadId?: string, visitorId?: string, welcomeMessage: string }) => {
    const { id, lead_id } = useParams();
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState<MessageType[]>(lastMessages ? lastMessages : []);
    const [conversationId, setConversationId] = useState(lastConvId);
    const [assistantResponse, setAssistantResponse] = useState('');
    const [leadId, setLeadId] = useState<string | undefined>(existLeadId);
    const [noLead, setNoLead] = useState(false);
    const [links, setLinks] = useState<any>(agentLinks);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [logo, setLogo] = useState<any | undefined>();
    const [initMessageSent, setInitMessageSent] = useState(false);
    const [results, setResults] = useState<any>([]);
    const [config, setConfig] = useState<any | undefined>(embedConfig);
    const [gettingResponse, setGettingResponse] = useState(false);
    const [gettingFullResponse, setGettingFullResponse] = useState(false);
    const { get, getStream, post, postStream, put, patch, remove } = useApi();

    const queryParams = new URLSearchParams(window.location.search);
    const allParams: any = {};

    queryParams.forEach((value, key) => {
        allParams[key] = value;
    });

    const [firstName, setFirstName] = useState(queryParams.get('first_name') ? queryParams.get('first_name') : '');
    const [lastName, setLastName] = useState(queryParams.get('last_name') ? queryParams.get('last_name') : '');
    const [email, setEmail] = useState(queryParams.get('email') ? queryParams.get('email') : '');
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [company, setCompany] = useState(queryParams.get('company') ? queryParams.get('company') : '');
    const [jobTitle, setJobTitle] = useState(queryParams.get('job_title') ? queryParams.get('job_title') : '');

    const chatRef = React.useRef<HTMLDivElement | null>(null);

    const messagesRef = React.useRef(messages);
    messagesRef.current = messages;

    const assistantResponseRef = React.useRef(assistantResponse);
    assistantResponseRef.current = assistantResponse;

    const conversationIdRef = React.useRef(conversationId);
    conversationIdRef.current = conversationId;

    const leadIdRef = React.useRef(leadId);
    leadIdRef.current = leadId;

    const scrollToBottom = () => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    };

    // useEffect(() => {
    //     scrollToBottom();
    // }, [loading, gettingResponse]);

    const consumeStream = async (stream: any) => {
        const reader = stream.getReader();
        let receivedText = ''; // To accumulate the converted text
        let conversationId = '';
        setLoading(false);
        scrollToBottom();
        try {
            let chunkText = ''; // To store the chunk text
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                // Convert the Uint8Array to String
                const chunk = new TextDecoder().decode(value);
                chunkText += chunk;
                // Split the chunk text by newline character
                const chunks = chunkText.split('\n');
                // Process each chunk
                for (let i = 0; i < chunks.length - 1; i++) {
                    const chunk = chunks[i];
                    // Attempt to parse JSON text and handle the data
                    try {
                        const data = JSON.parse(chunk);
                        if (data.type === 'document') {
                            setResults(data.results);
                        }
                        if (data.type === 'assistant' && data.content && !data.final) {
                            setGettingResponse(false);
                            setAssistantResponse(receivedText);
                            receivedText += data.content;
                        }
                        if (data.conversation_id) {
                            conversationId = data.conversation_id;
                        }
                        if (data.lead_id) {
                            setLeadId(data.lead_id)
                        }
                        if (data.lead_email) {
                            setEmail(data.lead_email)
                        }
                    } catch (error) {
                        console.error("Error parsing JSON from chunk", error);
                    }
                }
                // Store the remaining chunk for the next iteration
                chunkText = chunks[chunks.length - 1];
            }
        } catch (error) {
            console.error("Error reading from stream", error);
        } finally {
            const newMessage = { role: "assistant", content: receivedText, created_at: new Date().toISOString() };
            setMessages(messagesRef.current.concat(newMessage));
            // Clear the assistantResponse for the next use
            setAssistantResponse('');
            setConversationId(conversationId);
            setGettingFullResponse(false);
        }

        return conversationId;
    };

    const sendMessage = async (input: string, type?: string, firstMessage: boolean = false) => {
        setGettingFullResponse(true);
        const date = new Date().toISOString();
        const roleType = type === 'system' ? 'system' : 'user';
        if (!firstMessage) {
            setGettingResponse(true);
        }

        const updatedMessages = [...messages, { role: roleType, content: input, created_at: date }];
        setMessages(updatedMessages);

        const userData = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            company: company,
            job_title: jobTitle
        }

        const payload: any = {
            welcome_message: { role: 'assistant', content: welcomeMessage, created_at: date },
            message: { role: roleType, content: input, created_at: date },
            user_data: userData
        };

        if (conversationId !== 'new' && conversationId !== "") {
            payload['conversation_id'] = conversationId;
        }

        if (leadIdRef.current !== '') {
            payload['lead_id'] = leadIdRef.current;
        }
        payload['visitor_id'] = visitorId;
        postStream(`/agents/${id}`, payload)
            .then(async ({ stream }) => {
                const convId = await consumeStream(stream);
                setConversationId(convId);
            })
            .catch(error => console.error('Streaming error:', error));
    };

    const checkIfMobile = () => {
        return window.innerWidth < 1025;
    };

    const checkIfLaptop = () => {
        return window.innerWidth > 1024;
    };

    const newSession = () => {
        setConversationId('');
        setMessages([]);
        setEmailSubmitted(false);
        setInitMessageSent(false);
    };

    useEffect(() => {
        if (!embedConfig) {
            get(`/agents/${id}/config`)
                .then(({ data }) => {
                    setLogo(data.file_url ? data.file_url : undefined);
                    setLinks(data.links);
                    setConfig(data);
                })
                .catch(error => {
                    console.error('Error getting agent config:', error);
                });
        } else {
            setLogo(embedConfig.file_url ? embedConfig.file_url : undefined);
            setConfig(embedConfig);
        }
    }, []);

    useEffect(() => {
        if (!initMessageSent && !conversationId && (leadId || noLead)) {
            setInitMessageSent(true);
        }

        if (email && emailSubmitted) {
            sendMessage('The user has submitted their email, please inform them a representative will reach out to them shortly and ask them if there is anything else they need help with.', 'system');
        }
    }, [leadId, noLead]);

    useEffect(() => {
        if (!leadId && (firstName || lastName || email || company || jobTitle)) {
            const leadData = {
                agent_id: id,
                first_name: firstName,
                last_name: lastName,
                email: email,
                company: company,
                job_title: jobTitle,
                attributes: allParams
            }

            post('/leads/', leadData)
                .then(({ data }) => {
                    setLeadId(data);
                    window.history.replaceState({}, document.title, window.location.pathname);
                })
                .catch(error => console.error('Error creating lead:', error));
        } else {
            setNoLead(true);
        }

    }, [firstName, lastName, email, company, jobTitle]);

    useEffect(() => {
        const sendHeartbeat = () => {
            if (conversationIdRef.current) {
                post(`/sessions/${conversationIdRef.current}/heartbeat`, {})
                    .catch(error => console.error('Error sending heartbeat:', error));
            }
        };
        const intervalId = setInterval(sendHeartbeat, 60000); // Send heartbeat every 60 seconds
        return () => clearInterval(intervalId);
    }, []);

    return (
        messages && config && (
            <div className={`relative flex flex-col rounded-t-xl h-screen w-full`} style={config.scheme === 'Solid' ? { backgroundColor: config.color } : { backgroundImage: `linear-gradient(to bottom right, ${config.color}, ${config.end_color})` }}>
                <div className={`rounded-t-lg flex shadow-md justify-between items-center w-full`} style={{ backgroundColor: config.scheme === 'Solid' ? config.end_color : `${config.lighter_color}` }}>
                    <div className={`items-center transition-all duration-200`}>
                        <img src={logo} className="h-9 mt-4 mb-4 ml-4"></img>
                    </div>
                        <div className="flex flex-row">
                            <div onClick={() => newSession()} className={"flex items-center justify-end pr-6 hover:cursor-pointer"}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 stroke-gray-500 hover:stroke-gray-900`}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                            </div>
                        </div>
                </div>
                <div className="flex flex-row h-full">
                    <div className="flex flex-col h-full w-full">
                        {links && links.length > 0 && (
                            <div className="sticky top-0 bg-opacity-100 p-1 z-30 w-full">
                                <div className="flex flex-wrap p-2">
                                    {links.length > 0 &&
                                        links.map((link: any, index: number) => (
                                            <a key={index} href={link.url} target="_blank" className={`m-1 bg-white/90 rounded-md py-1 px-2 flex flex-col justify-between leading-normal hover:cursor-pointer transition duration-200 hover:scale-105 hover:bg-white hover:shadow-lg`}>
                                                <div className="flex flex-row items-center justify-center">
                                                    <div className="flex flex-row justify-start mr-1">
                                                        <h1 className={`text-black/70 text-md font-semibold transition duration-200 hover:text-black hover:cursor-pointer`}>{link.name}</h1>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-4 h-4 stroke-gray-500">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                    </svg>
                                                    {!sidebar && !checkIfMobile() && (
                                                        <div>
                                                            <p className="text-gray-500 text-sm">{link.description}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </a>
                                        ))}
                                </div>
                            </div>
                        )}
                        <div className="flex flex-row h-full w-full overflow-hidden">
                            <div ref={chatRef} className="flex flex-col w-full items-center overflow-y-auto scrollbar-thin mb-16">
                                <div className="pl-4 pr-4 pb-4 h-full mb-8 w-full justify-center max-w-6xl items-start">
                                    <Message
                                        key={0}
                                        role={'assistant'}
                                        content={welcomeMessage}
                                        leadEmail={email}
                                        scrollToBottom={scrollToBottom}
                                        config={{
                                            color: config.color,
                                            end_color: config.color,
                                            darker_color: config.darker_color,
                                            lighter_color: config.lighter_color,
                                            mixed_color: config.mixed_color,
                                            scheme: config.scheme
                                        }}
                                    />
                                    {messages
                                        .filter((message) => message.role !== "system")
                                        .sort((a, b) => {
                                            const dateA = new Date(a.created_at.endsWith('Z') ? a.created_at : `${a.created_at}Z`);
                                            const dateB = new Date(b.created_at.endsWith('Z') ? b.created_at : `${b.created_at}Z`);
                                            return dateA.getTime() - dateB.getTime();
                                        })
                                        .map((message, index) => (
                                            config && (
                                                <Message
                                                    key={index}
                                                    role={message.role}
                                                    content={message.content}
                                                    created_at={message.created_at}
                                                    leadEmail={email}
                                                    scrollToBottom={scrollToBottom}
                                                    sidebar={sidebar}
                                                    config={{
                                                        color: config.color,
                                                        end_color: config.color,
                                                        darker_color: config.darker_color,
                                                        lighter_color: config.lighter_color,
                                                        mixed_color: config.mixed_color,
                                                        scheme: config.scheme
                                                    }}
                                                />
                                            )
                                        ))}
                                    {(assistantResponseRef.current || gettingResponse) &&
                                        <Message
                                            role="assistant"
                                            content={assistantResponseRef.current}
                                            leadEmail={email}
                                            scrollToBottom={scrollToBottom}
                                            gettingResponse={gettingResponse}
                                            config={{
                                                color: config.color,
                                                end_color: config.color,
                                                darker_color: config.darker_color,
                                                lighter_color: config.lighter_color,
                                                mixed_color: config.mixed_color,
                                            }}
                                        />}
                                    {loading && <Spinner />}
                                </div>

                            </div>
                        </div>
                            <ChatInput sendMessage={sendMessage} config={config} gettingFullResponse={gettingFullResponse} setEmail={setEmail} setFirstName={setFirstName} setLastName={setLastName} setCompany={setCompany} setFormSubmitted={setEmailSubmitted} leadId={leadId} fullScreen={true} />
                    </div>
                </div>
            </div>
        )

    );

}

export default AgentFullScreen;