import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import GoogleDriveIcon from '../icons/google_drive.png'
import useApi from '../../api';

interface LinkProps {
    addLink: any;
    editLink: any;
    setModalVisible: any;
    link: any;
}

export default function CreateLink({ addLink, editLink, setModalVisible, link }: LinkProps) {
    let [isOpen, setIsOpen] = useState(false)
    const [linkName, setLinkName] = useState(link ? link.name : '');
    const [linkUrl, setLinkUrl] = useState(link ? link.url : '');
    const [linkDescription, setLinkDescription] = useState(link ? link.description : '');
    const { get, getStream, post, postStream, put, patch, remove } = useApi();

    function closeModal() {
        setModalVisible(false)
        setIsOpen(false)
    }

    function addNewLink() {
        const added = addLink(linkName, linkDescription, linkUrl);
        if (added) {
            closeModal();
            setModalVisible(false)
        }
    }

    function updateLink() {
        editLink(link.id, linkName, linkDescription, linkUrl);
        closeModal();
        setModalVisible(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    useEffect(() => {
        setIsOpen(true)
    }, []);

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text--900 mb-6 dark:text-slate-100"
                                    >
                                        Add Link
                                    </Dialog.Title>
                                    <label className="block text-sm font-medium leading-6 text--900 pb-1 mt-6 dark:text-slate-200">
                                        Link Text
                                    </label>
                                    <div className="mt-3">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring--300 dark:bg-slate-700 dark:ring-0">
                                            <textarea
                                                name="persona"
                                                id="persona"
                                                autoComplete="off"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text--900 placeholder:text--400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-md focus:ring-opacity-50 sm:leading-6 dark:text-slate-300"
                                                placeholder=""
                                                autoFocus={true}
                                                value={linkName || ''}
                                                onChange={(e) => setLinkName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <label className="block text-sm font-medium leading-6 text--900 pb-1 mt-6 dark:text-slate-200">
                                        Link Description
                                    </label>
                                    <div className="mt-3">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring--300 dark:bg-slate-700 dark:ring-0">
                                            <textarea
                                                name="persona"
                                                id="persona"
                                                autoComplete="off"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text--900 placeholder:text--400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-md focus:ring-opacity-50 sm:leading-6 dark:text-slate-300"
                                                placeholder=""
                                                autoFocus={true}
                                                value={linkDescription || ''}
                                                onChange={(e) => setLinkDescription(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <label className="block text-sm font-medium leading-6 text--900 pb-1 mt-6 dark:text-slate-200">
                                        Link
                                    </label>
                                    <div className="mt-3">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring--300 dark:bg-slate-700 dark:ring-0">
                                            <textarea
                                                name="persona"
                                                id="persona"
                                                autoComplete="off"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text--900 placeholder:text--400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-md focus:ring-opacity-50 sm:leading-6 dark:text-slate-300"
                                                placeholder=""
                                                autoFocus={true}
                                                value={linkUrl || ''}
                                                onChange={(e) => setLinkUrl(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        {link ? (
                                            <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-600 dark:text-slate-100 hover:dark:bg-slate-700"
                                            onClick={() => updateLink()}
                                        >
                                            Save Link
                                        </button>  
                                        ) : (
                                            <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => addNewLink()}
                                        >
                                            Create Link
                                        </button>
                                        )}
                   
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
