import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import useApi from '../api';
import { useParams } from 'react-router-dom';
import { formatDate, addMinutes } from '../utils/dateFormatter';
import Chat from './Chat';
import SessionsCard from './SessionsCard';
import { useNavigate } from 'react-router-dom';

interface Lead {
    id: string;
    first_name: string;
    last_name: string;
    company: string;
    email: string;
    job_title: string;
    attributes: any;
    integrations: {
        salesforce: string;
        hubspot: string;
    };
    email_verified: boolean;
}

interface Message {
    id: string;
    role: string;
    content: string;
    created_at: string;
}

interface Conversation {
    id: string;
    created_at: string;
    last_activity: string;
    processed: boolean;
    messages: Message[];
    interaction: boolean;
    visitor_id?: string;
    city: string;
    region: string;
    country: string;
    postal: string;
    status: string;
    lead?: Lead;
}

export default function Session() {
    const { id } = useParams();
    const [modalVisible, setModalVisible] = useState(false);
    let [isOpen, setIsOpen] = useState(false);
    const { get } = useApi();
    const [session, setSession] = useState<Conversation | null>(null);
    const [message, setMessage] = useState<Message | null>(null);
    const [status, setStatus] = useState<string>('');
    const [leadId, setLeadId] = useState<string | null>(null);
    const [sessionId, setSessionId] = useState<string | null>(id || null);
    const navigate = useNavigate();
    function closeModal() {
        setModalVisible(false);
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    useEffect(() => {
        if (sessionId) {
            get(`/sessions/${sessionId}`).then((response) => {
                console.log(response.data);
                setSession(response.data);
                setStatus(response.data.status);
            }).catch((error) => {
                console.error('Error fetching session:', error);
            });
        }
    }, [sessionId, leadId]);

    // set sessionId when id is changed
    useEffect(() => {
        if (id) {
            setSessionId(id);
        }
    }, [id]);

    useEffect(() => {
        if (message) {
            openModal();
        }
    }, [message]);

    function isLink(str: string): boolean {
        try {
            new URL(str);
            return true;
        } catch {
            return false;
        }
    }

    return (
        <>
            {session && (
                <div className="flex w-full h-full">
                    <SessionsCard setSessionId={setSessionId} selectedSessionId={sessionId} selectedSessionStatus={status} />
                    <Chat chatId={session.id} status={status} setStatus={setStatus} leadId={leadId} setLeadId={setLeadId} />
                    <div className="w-1/4 transform h-full bg-white p-6 text-left align-middle overflow-y-auto scrollbar-thin dark:bg-slate-900">
                        {session.lead && (
                            <div className="mb-6">
                                <div onClick={() => navigate(`/leads/${session.lead && session.lead.id}`)} className="flex items-center p-4 hover:cursor-pointer bg-slate-50 border border-slate-200 rounded-lg dark:bg-slate-800 dark:border-slate-700">
                                    <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-blue-500 text-white font-medium">
                                        {(session.lead.first_name?.[0] || '') + (session.lead.last_name?.[0] || '')}
                                    </div>
                                    <div className="ml-4">
                                        <div className="text-lg font-medium dark:text-slate-200">
                                            {session.lead.first_name} {session.lead.last_name}
                                        </div>
                                    </div>
                                </div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Company
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-200">
                                    <h3>
                                        {session.lead.company}
                                    </h3>
                                </div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Title
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-200">
                                    <h4>
                                        {session.lead.job_title}
                                    </h4>
                                </div>
                                {session.lead.attributes && Object.keys(session.lead.attributes).map((key) => {
                                    if (key === "email" || key === "first_name" || key === "last_name" || key === "company" || key === "job_title" || key === 'aimdoc_parent_url') {
                                        return null;
                                    } else if (session.lead) {
                                        return (
                                            <div key={key}>
                                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-200">
                                                    {key}
                                                </label>
                                                <div className="">
                                                    {isLink(session.lead.attributes[key]) ? (
                                                        <a href={session.lead.attributes[key]} className="text-blue-500 underline break-words text-sm font-medium" target="_blank" rel="noopener noreferrer">
                                                            {session.lead.attributes[key]}
                                                        </a>
                                                    ) : (
                                                        <div className="text-sm mt-1 dark:text-slate-200">
                                                            {session.lead.attributes[key]}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        )}
                        <div className="text-xl font-medium leading-6 text-slate-600 mb-6 px-4 py-2 text-center border bg-slate-100 rounded-md dark:bg-slate-800 dark:border-slate-700 dark:text-slate-100">
                            Session Details
                        </div>
                        {status === 'active' && (
                            <div className="flex flex-row px-2 items-center space-x-1 bg-green-100 border-green-200 border animate-pulse text-green-800 text-sm rounded-lg py-1 text-xs dark:bg-green-700 dark:border-green-600 dark:text-green-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>

                                <div className="font-semibold">
                                    Active
                                </div>
                            </div>
                        )}
                        <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                            User ID
                        </label>
                        <div className="text-sm mt-1 dark:text-slate-200">
                            <h1>
                                {session.visitor_id}
                            </h1>
                        </div>
                        <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                            Session Started
                        </label>
                        <div className="text-sm mt-1 mb-6 dark:text-slate-200">
                            <h1>
                                {formatDate(session.created_at)}
                            </h1>
                        </div>
                        <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                            Session Last Active
                        </label>
                        <div className="text-sm mt-1 mb-6 dark:text-slate-200">
                            <h1>
                                {session.last_activity ? formatDate(session.last_activity) : addMinutes(session.created_at, 1)}
                            </h1>
                        </div>
                        <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100 s">
                            Session Processed
                        </label>
                        <div className="text-sm mt-1 mb-6 dark:text-slate-200">
                            <h1>
                                {session.processed ? "Yes" : "No"}
                            </h1>
                        </div>
                        <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100 s">
                            City
                        </label>
                        <div className="text-sm mt-1 mb-6 dark:text-slate-200">
                            <h1>
                                {session.city}
                            </h1>
                        </div>
                        <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100 s">
                            Region
                        </label>
                        <div className="text-sm mt-1 mb-6 dark:text-slate-200">
                            <h1>
                                {session.region}
                            </h1>
                        </div>
                        <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100 s">
                            Country
                        </label>
                        <div className="text-sm mt-1 mb-6 dark:text-slate-200">
                            <h1>
                                {session.country}
                            </h1>
                        </div>
                        <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100 s">
                            Zip Code
                        </label>
                        <div className="text-sm mt-1 mb-6 dark:text-slate-200">
                            <h1>
                                {session.postal}
                            </h1>
                        </div>

                    </div>
                </div>
            )}
        </>
    )
}
