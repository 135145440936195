import React, { useCallback, useEffect, useState } from "react";
import useApi from '../api';
import { set } from "lodash";
import Spinner from '../documentation/Spinner';
import NewIntegration from "./NewIntegration";
import IntegrationIcon from './IntegrationIcon';
import GoogleDrive from './GoogleDrive';
import { Dialog, Transition } from '@headlessui/react'
import { deepEqual } from "assert";
import { conLog } from '../utils/conLog';

interface Integration {
    id: string;
    name: string;
    description: string;
    details: any;
}

interface IntegrationsProps {
    integrations: Integration[];
}

const Integrations: React.FC = () => {
    const { get, getStream, post, postStream, put, patch, remove } = useApi();
    const [integrations, setIntegrations] = useState<Integration[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);


    useEffect(() => {

        const getIntegrations = async () => {
            setFetching(true);
            const response = await get('/integrations/');
            setIntegrations(response.data);
            conLog(response);
            setFetching(false);
        };

        getIntegrations();

    }, []);


    return (
        <div className="">
            {loading && (
                <Spinner />
            )}
        {integrations && integrations.some((integration) => integration.details.type === 'doc') && (
            <>
            <Dialog.Title
            as="h3"
            className="text-md font-medium leading-6 text-slate-700 mt-4 mb-2"
          >
            Connected Systems
          </Dialog.Title>
            <Dialog.Description className="text-sm text-slate-500 mb-6">
              Select a document from a connected system
            </Dialog.Description>
            <div className="">
                {fetching && (
                    <Spinner />
                )}
                <div className="">
                    <div className="">
                        {integrations.map((integration) => (
                            integration.name == 'google' && (
                                <GoogleDrive type="doc" id={integration.id} setLoading={setLoading} />
                            )
                        ))}
                    </div>
                </div>
            </div>
            </>
        )}
        </div>
 
    );
};

export default Integrations;