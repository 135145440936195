import React, { useState, useEffect, useRef } from 'react';
import _, { omit, remove, set } from 'lodash';
import useApi from '../../api';
import { FormField } from '../../types';
import { useOutsideAlerter } from '../../useOutsideAlerter';
import CreateLink from './CreateLink';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import toastConfig from '../../config/Toast';
import useAnalytics from '../../analytics/analytics';
import { v4 as uuidv4 } from 'uuid';

const Links = ({ links, setLinks, setNeedsUpdate, agId, agentName }: { links: any, setLinks: any, setNeedsUpdate: any, agId: string | undefined, agentName: string | undefined }) => {
  const [modalVisible, setModalVisible] = useState(false); // State to manage modal visibility
  const [activeLink, setActiveLink] = useState<any>(null);
  const { get, getStream, post, postStream, put, patch, remove } = useApi();
  const { captureEvent } = useAnalytics();

  const toggleModal = (link?: string) => {
    if (modalVisible) {
      setActiveLink(null);
    } else {
      setActiveLink(link);
    }
    setModalVisible(!modalVisible);
  };

  const addLink = (linkName: string, linkDescription: string, linkUrl: string) => {
    // check if link description is greater than 100 characters
    if (linkDescription.length > 50) {
        toast.error('Link description must be less than 50 characters', toastConfig);
        return false;
    }
    // check if 5 links exist, if so throw error
    if (linkName === "") {
        toast.error('Link name is required', toastConfig);
        return false;
    }

    if (linkUrl === "") {
        toast.error('Link URL is required', toastConfig);
        return false;
    }
    if (links.length >= 4) {
        toast.error('You can only have 4 links', toastConfig);
        return false;
    }
    const link = {
        id: uuidv4(),
        name: linkName,
        description: linkDescription,
        url: linkUrl
    };
    setLinks((prevState: any) => [...prevState, link]);
    setNeedsUpdate(true);
    captureEvent('agent_link_added', {
        agent_id: agId,
        agent_name: agentName,
    });
    return true;
  };

  const editLink = (id: string, linkName: string, linkDescription: string, linkUrl: string) => {
      let updatedLinks = links.filter((link: any) => link.id !== id);

      updatedLinks = [...updatedLinks, { id: id, name: linkName, description: linkDescription, url: linkUrl }];

      setLinks(updatedLinks);
      setNeedsUpdate(true);
  };

  const deleteLink = (id: string) => {
      setLinks(links.filter((link: any) => link.id !== id));
      setNeedsUpdate(true);
      captureEvent('agent_link_deleted', {
          agent_id: agId,
          agent_name: agentName,
      });
  };

  const modalRef = useRef(null);

  useOutsideAlerter(modalRef, () => toggleModal());

  return (
    <div className='mb-6'>
    <div className="flex flex-row items-center">
      <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium text-slate-600 hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 transition duration-200 dark:bg-slate-600 dark:text-slate-50 dark:hover:bg-slate-600/50"
          onClick={() => toggleModal()}
      >
          Add Link
      </button>
      </div>
      {links && links.length > 0 && (
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="grid grid-cols-3 gap-4 mt-4 w-3/4">
              {links.length > 0 &&
                links.map((link: any) => (
                  <div className="border border-slate-200 shadow-sm rounded-lg p-4 flex flex-col leading-normal bg-white dark:bg-slate-700 dark:border-none">
                      <div className="flex flex-row justify-between">
                      <h2 onClick={() => toggleModal(link)} className="text-slate-800 font-medium text-lg hover:cursor-pointer hover:text-slate-600 dark:text-slate-300 dark:font-semibold dark:hover:text-slate-300">{link.name}</h2>
                      <div onClick={() => deleteLink(link.id)}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 shrink-0 stroke-slate-400 hover:stroke-red-400 hover:cursor-pointer dark:stroke-slate-300">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                  </svg>
                  </div>
                  </div>
                  <div>
                      <p className="text-slate-700 text-xs font-normal dark:text-slate-400">{link.description}</p>       
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      {modalVisible && (
        <CreateLink
          addLink={addLink}
          editLink={editLink}
          setModalVisible={setModalVisible}
          link={activeLink}
        />
      )}
    </div>
  );
};

export default Links;