import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useApi from '../api';
import toastConfig from '../config/Toast';
import { useNavigate } from 'react-router-dom';

interface QuestionProps {
    modalRef?: any;
    toggleModal?: any;
}

export default function CreateQuestion({ modalRef, toggleModal }: QuestionProps) {
    const [question, setQuestion] = useState<string>('');
    const [answer, setAnswer] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const { post } = useApi();
    const navigate = useNavigate();

    const createQuestion = async () => {
        if (question === "") {
            toast.error('Question is required.', toastConfig);
            return;
        }
        if (answer === "") {
            toast.error('Answer is required.', toastConfig);
            return;
        }

        const data = {
            question: question,
            answer: answer
        }

        try {
            setLoading(true);
            const response = await post('/questions/', data);
            toast.success('Question successfully created.', toastConfig);
            if (toggleModal) {
                toggleModal();
            }
            navigate(`/questions/${response.data.id}`);
        } catch (error) {
            console.error('Error creating question:', error);
            toast.error('Failed to create question.', toastConfig);
        }

        setLoading(false);
    };

    return (
        <div className="flex h-full w-full">
            <div className="flex flex-col h-full w-full bg-white dark:bg-slate-900 rounded-lg">
                <div className="p-8">
                    <div className="hover:cursor-pointer rounded-lg">
                        <label className="block text-md font-medium leading-6 text-slate-900 dark:text-slate-200">
                            Question
                        </label>
                        <label className="block text-sm font-light leading-6 text-slate-400 mt-1 dark:text-slate-300">
                            Enter your question here.
                        </label>
                        <div className="mt-3">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-slate-300 dark:bg-slate-800 dark:ring-slate-600">
                                <textarea
                                    name="question"
                                    id="question"
                                    autoComplete="off"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-slate-900 placeholder:text-slate-400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-md focus:ring-opacity-50 sm:leading-6 dark:focus-ring-slate-500 dark:text-slate-200"
                                    placeholder="Enter your question..."
                                    value={question}
                                    onChange={(e) => setQuestion(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="hover:cursor-pointer rounded-lg mt-6">
                        <label className="block text-md font-medium leading-6 text-slate-900 dark:text-slate-200">
                            Answer
                        </label>
                        <label className="block text-sm font-light leading-6 text-slate-400 mt-1 dark:text-slate-300">
                            Enter the answer to your question.
                        </label>
                        <div className="mt-3">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-slate-300 dark:bg-slate-800 dark:ring-slate-600">
                                <textarea
                                    name="answer"
                                    id="answer"
                                    autoComplete="off"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-slate-900 placeholder:text-slate-400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-md focus:ring-opacity-50 sm:leading-6 dark:focus-ring-slate-500 dark:text-slate-200"
                                    placeholder="Enter your answer..."
                                    value={answer}
                                    onChange={(e) => setAnswer(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex w-full justify-end mt-8">
                        <button
                            onClick={createQuestion}
                            type="submit"
                            disabled={loading}
                            className="rounded-md bg-blue-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:bg-slate-500 dark:hover:bg-slate-600 dark:focus-visible:outline-slate-500"
                        >
                            {loading ? 'Creating...' : 'Create Question'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}