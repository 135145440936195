import React, { useState, useEffect, useRef } from 'react';
import _, { omit, remove, set } from 'lodash';
import useApi from '../../api';
import { FormField } from '../../types';
import { useOutsideAlerter } from '../../useOutsideAlerter';
import CreateField from './CreateField';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import toastConfig from '../../config/Toast';
import useAnalytics from '../../analytics/analytics';
import { v4 as uuidv4 } from 'uuid';
import { Input, Field, Label, Switch, Description } from '@headlessui/react'
import { useUser } from '../../user/UserContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Form = ({ form, setForm, setNeedsUpdate, agId, agentName}: { form: any, setForm: any, setNeedsUpdate: any, agId: any, agentName: any }) => {
    const [modalVisible, setModalVisible] = useState(false); // State to manage modal visibility
    const [activeField, setActiveField] = useState<any>(null);
    const { get, getStream, post, postStream, put, patch, remove } = useApi();
    const { captureEvent } = useAnalytics();
    const { user, organization, checkLicenseType } = useUser();


    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(form);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setForm(items);
        setNeedsUpdate(true);
    };

    const toggleModal = (starter?: string) => {
        if (modalVisible) {
            setActiveField(null);
        } else {
            setActiveField(starter);
        }
        setModalVisible(!modalVisible);
    };

    const addField = (field: any) => {
        // check if 5 starters exist, if so throw error
        if (field.label === "") {
            toast.error('starter name is required', toastConfig);
            return false;
        }

        // if (starters.length >= 3) {
        //     toast.error('You can only have 3 starters', toastConfig);
        //     return false;
        // }
        const newField = {
            label: field.label,
            type: field.type,
            required: field.required,
            name: field.name,
        };
        // check if field already exists
        if (form.some((field: any) => field.label === newField.label)) {
            toast.error('Field already exists', toastConfig);
            return false;
        }
        setForm([...form, newField]);

        setNeedsUpdate(true);
        captureEvent('agent_field_added', {
            agent_id: agId,
            agent_name: agentName,
        });
        return true;
    };

    const editField = (field: any) => {
        const originalName = field.name; // Store the original name
        // ensure name is updated based on the new label
        const updatedField = {
            ...field,
            name: field.label.toLowerCase().replace(/\s+/g, '_').replace(/[^a-z0-9_]/g, '')
        };
        
        // merge updated field with existing form using the original name to find the field
        const updatedForm = form.map((f: any) => f.name === originalName ? updatedField : f);
        setForm(updatedForm);
        setNeedsUpdate(true);
        captureEvent('agent_field_updated', {
            agent_id: agId,
            agent_name: agentName,
        });
        toggleModal();
     };

    const deleteField = (field: any) => {
        // user cannot remove email or have 0 fields
        if (field.name === 'email') {
            toast.error('Email field cannot be removed', toastConfig);
            return;
        }
        if (form.length === 1) {
            toast.error('You must have at least one field', toastConfig);
            return;
        }
        const updatedForm = form.filter((f: any) => f.name !== field.name);
        setForm(updatedForm);
        setNeedsUpdate(true);
        captureEvent('agent_field_deleted', {
            agent_id: agId,
            agent_name: agentName,
        });
    };

    const modalRef = useRef(null);

    useOutsideAlerter(modalRef, () => toggleModal());

    return (
        <div className='mb-6'>
            <div className="flex flex-row items-center">
                <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium text-slate-600 hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 transition duration-200 dark:bg-slate-600 dark:text-slate-50 dark:hover:bg-slate-600/50"
                    onClick={() => toggleModal()}
                >
                    Add field
                </button>
            </div>
            {form && form.length > 0 && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="fields">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className="flex flex-col">
                                <div className="">
                                    <div className="flex flex-col gap-4 mt-4">
                                        {form.map((field: any, index: number) => (
                                            <Draggable key={field.id} draggableId={field.name} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        className="border border-slate-200 shadow-sm rounded-lg p-4 flex flex-col leading-normal bg-white dark:bg-slate-700 dark:border-none"
                                                    >
                                                        <div className="flex flex-row justify-between items-center">
                                                            <div {...provided.dragHandleProps} className="mr-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-400">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                                                </svg>
                                                            </div>
                                                            <h2 onClick={() => toggleModal(field)} className="text-slate-800 font-medium text-lg hover:cursor-pointer hover:text-slate-600 dark:text-slate-300 dark:font-semibold dark:hover:text-slate-300">{field.label}</h2>
                                                            <div onClick={() => deleteField(field)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 shrink-0 stroke-slate-400 hover:stroke-red-400 hover:cursor-pointer dark:stroke-slate-300">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                </div>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
            {modalVisible && (
                <CreateField
                    addField={addField}
                    editField={editField}
                    setModalVisible={setModalVisible}
                    field={activeField}
                />
            )}
        </div>
    );
};

export default Form;