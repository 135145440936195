import React, { createContext, useContext, useEffect, useState, ReactNode, useRef } from 'react';
import { supabase } from '../supabaseClient';
import useApi from '../api';

// Define the type for the user state. Adjust according to your user object structure
interface User {
  email: string;
  id: string;
  is_active?: boolean;
  organization_id?: string;
  username?: string;
  walkthrough_complete?: boolean;
}

interface Organization {
  id: string;
  name: string;
  license_type: string;
  trial_end_date: string;
  settings: {
    interaction_notification: boolean;
    new_lead_notification: boolean;
  };
}

// Context state type
interface UserContextType {
  user: User | null;
  organization: Organization | null;
  loading: boolean;
  refreshUser: () => void; // Optional, if you want to expose a method to refresh the user manually
  refreshOrganization: () => void; // Optional, if you want to expose a method to refresh the organization manually
  checkUser: () => void; // Optional, if you want to expose a method to refresh the user manually
  checkLicenseType: (license_type: string) => boolean;
}

// Creating context with default values
const UserContext = createContext<UserContextType | undefined>(undefined);

// Provider Props Type
interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem('aimdocAdmin');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [loading, setLoading] = useState(!user);
  const { get, post } = useApi();
  const initialLoad = useRef(true); // Track initial load

  const checkUser = async () => {
    setLoading(true);
    try {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        throw error;
      }
      if (session) {
        const storedUser = localStorage.getItem('aimdocAdmin');
        const storedOrganization = localStorage.getItem('aimdocOrganization');
        if (storedUser && storedOrganization) {
          setUser(JSON.parse(storedUser));
          setOrganization(JSON.parse(storedOrganization));
          setLoading(false);
          return;
        }
        try {
          const response = await get(`/users/me`);
          setUser(response.data);
          localStorage.setItem('aimdocAdmin', JSON.stringify(response.data));
        } catch (error: any) {
          const aimdocUser = await post('/users/', {
            auth_id: session.user.id,
            email: session.user.email,
            username: session.user.email
          });
          setUser(aimdocUser.data);
          localStorage.setItem('aimdocAdmin', JSON.stringify(aimdocUser.data));
          console.error('Error fetching user:', error);
        }

        try {
          const response = await get(`/users/me/organization`);
          setOrganization(response.data);
          localStorage.setItem('aimdocOrganization', JSON.stringify(response.data));
        } catch (error: any) {
          console.error('Error fetching organization:', error);
        }

      } else {
        setUser(null);
        localStorage.removeItem('aimdocAdmin');
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      setUser(null);
      localStorage.removeItem('aimdocAdmin');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (initialLoad.current) {
      checkUser();
      initialLoad.current = false;
    }
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      checkUser();
    });
    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  const refreshUser = async () => {
    try {
      const response = await get(`/users/me`);
      setUser(response.data);
      localStorage.setItem('aimdocAdmin', JSON.stringify(response.data));
    } catch (error: any) {
      console.error('Error fetching user:', error);
    }
  };

  const refreshOrganization = async () => {
    try {
      const response = await get(`/users/me/organization`);
      setOrganization(response.data);
      localStorage.setItem('aimdocOrganization', JSON.stringify(response.data));
    } catch (error: any) {
      console.error('Error fetching organization:', error);
    }
  };

  const checkLicenseType = (license_type: string) => {
    if (license_type === 'pro') {
      if (organization?.license_type === 'pro' || organization?.license_type === 'business' || organization?.license_type === 'aimdoc') {
        return true;
      }
    }
    if (license_type === 'standard') {
      if (organization?.license_type === 'standard' || organization?.license_type === 'pro' || organization?.license_type === 'business' || organization?.license_type === 'aimdoc' || organization?.license_type === 'trial') {
        return true;
      }
    }
    return false;
  };

  // Ensure you're passing down the checkUser method if you want it to be accessible
  return (
    <UserContext.Provider value={{ user, checkUser, loading, organization, refreshUser, refreshOrganization, checkLicenseType }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the user context
export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};