import React, { useState, useEffect } from 'react';
import useApi from '../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { conLog } from '../utils/conLog';

const UsageAnalytics = ({ organization } : { organization: any }) => {
    const { get } = useApi();
    const [usage, setUsage] = useState<any>();

    useEffect(() => {
        fetchAnalyticsData();
    }, []);

    const fetchAnalyticsData = async () => {
        try {
            const response = await get('/usage/');
            conLog(response.data);
            setUsage(response.data);
        } catch (error) {
            toast.error('Error fetching usage data');
        }
    };

    const getTextColorClass = (percentage: number) => {
        if (percentage < 50) return 'text-green-600';
        if (percentage >= 50 && percentage <= 75) return 'text-yellow-500';
        return 'text-red-600';
    };

    const getLicenseLimits = (type: string) => {
        switch (type) {
            case 'trial':
                return { charLimit: 1000000, messageLimit: 500, agentLimits: 1 };
            case 'starter':
                return { charLimit: 5000000, messageLimit: 1000, agentLimits: 1 };
            case 'standard':
                return { charLimit: 10000000, messageLimit: 3000, agentLimits: 2 };
            case 'pro':
                return { charLimit: 20000000, messageLimit: 6500, agentLimits: 5 };
            case 'business':
                return { charLimit: 50000000, messageLimit: 30000, agentLimits: 20 };
            default:
                return { charLimit: 50000000000, messageLimit: 10000000, agentLimits: 1000 };
        }
    };

    if (!usage || !organization) return null;

    const { charLimit, messageLimit } = getLicenseLimits(organization.license_type);

    const charUsagePercentage = (usage.total_char_count / charLimit) * 100;
    const messageUsagePercentage = (usage.total_messages / messageLimit) * 100;

    return (
        <div className="flex flex-col w-full ml-4 mt-6 bg-white p-4 rounded-xl max-w-2xl shadow-lg dark:bg-slate-700">
            <h1 className="flex justify-between rounded-lg bg-slate-50 border border-slate-200 px-4 py-2 text-left text-xl font-medium text-slate-900 focus:outline-none dark:bg-slate-700 dark:text-slate-200 dark:border-none">
                Usage
            </h1>
            <div className="flex flex-row h-full mt-2 space-x-4">
                <div className="flex flex-col w-1/2 mt-2 bg-white border border-slate-200 rounded-lg p-4 dark:bg-slate-600 dark:text-slate-200 dark:hover:bg-slate-600 transition duration-200 dark:border-slate-600">
                    <div className="text-xl text-center text-slate-600 dark:text-slate-200">
                        Knowledge Character Count
                    </div>
                    <div className="flex justify-center items-center h-full text-2xl">
                        <span className={`${getTextColorClass(charUsagePercentage)}`}>
                            {(usage.total_char_count / 1000000).toFixed(2)}M
                        </span>
                        <span className="text-slate-600 dark:text-slate-200 font-bold">
                            {` / ${(charLimit / 1000000).toFixed(1)}M`}
                        </span>
                    </div>
                </div>
                <div className="flex flex-col w-1/2 mt-2 bg-white border border border-slate-200 rounded-lg p-4 dark:bg-slate-600 dark:text-slate-200 dark:hover:bg-slate-600 transition duration-200 dark:border-slate-600">
                    <div className="text-xl text-center text-slate-600 dark:text-slate-200">
                        Agent Messages Sent
                    </div>
                    <div className="flex justify-center items-center h-full text-2xl">
                        <span className={`${getTextColorClass(messageUsagePercentage)}`}>
                            {usage.total_messages}
                        </span>
                        <span className="text-slate-600 dark:text-slate-200 font-bold">
                            {` / ${messageLimit}`}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UsageAnalytics;
