import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import useApi from '../api';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../documentation/Spinner';

interface NewPageProp {
    websiteId: string;
}

const NewPage: React.FC<NewPageProp> = ({ websiteId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pageUrl, setPageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const { post } = useApi();

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const indexPage = () => {
    if (!pageUrl) {
      toast.error('Please enter a page URL', toastConfig);
      return;
    }

    setLoading(true);

    post(`/index-page/${websiteId}`, { url: pageUrl })
      .then(() => {
        toast.success('Page indexing started', toastConfig);
        setLoading(false);
        setIsOpen(false);
      })
      .catch((error) => {
        console.error('Error indexing page:', error);
        toast.error('Error indexing page', toastConfig);
        setLoading(false);
      });
  };

  return (
    <>
      <button
        type="button"
        className="mb-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-700 dark:text-slate-200 dark:hover:bg-slate-800"
        onClick={openModal}
      >
        Add Page
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 dark:bg-black/40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-slate-900 mb-4 dark:text-slate-100">
                    Add Page for Indexing
                  </Dialog.Title>
                  <div className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-slate-700 dark:text-slate-300">Page URL</label>
                      <input
                        type="text"
                        className="mt-1 block w-full rounded-md border-slate-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-slate-700 dark:border-slate-600 dark:text-slate-100 disabled:opacity-50"
                        value={pageUrl}
                        onChange={(e) => setPageUrl(e.target.value)}
                        disabled={loading}
                      />
                    </div>
                    <div className="mt-4 flex justify-end">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium text-slate-900 hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-700 dark:text-slate-100 dark:hover:bg-slate-600 disabled:cursor-not-allowed"
                        onClick={indexPage}
                        disabled={loading}
                      >
                        {loading ? <Spinner /> : 'Index Page'}
                      </button>
                      <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium text-slate-900 hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-700 dark:text-slate-100 dark:hover:bg-slate-600 disabled:cursor-not-allowed"
                        onClick={closeModal}
                        disabled={loading}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default NewPage;