import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import config from '../../config.json';

const AgentPreview = () => {
  const { agentId, websiteUrl } = useParams();
  const location = useLocation();
  const iframeRef = useRef(null);
  const [iframeError, setIframeError] = useState(false);

  // Helper function to get query parameters
  const getQueryParam = (param: any) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  // Get the 'live_agent' query parameter
  const liveAgent = getQueryParam('live_agent') === 'true';

  useEffect(() => {

    // Dynamically inject the AI assistant script
    const script = document.createElement('script');
    script.src = 'https://app.aimdoc.ai/embedded.bundle.js';
    script.async = true;
    document.body.appendChild(script);

    // Initialize AI Assistant when the script loads
    script.onload = () => {
    if (window.Agent && typeof window.Agent.initAgentChat === 'function') {
        window.Agent.initAgentChat('ai-assistant', {
        agentId: agentId, // Use agent_id from the URL
        });
    }
    };

    // Cleanup script when component unmounts
    return () => {
    document.body.removeChild(script);
    };
    
  }, [agentId]);

  // Decode the website URL
  const decodedWebsiteUrl = decodeURIComponent(websiteUrl ? websiteUrl : '');

  // Handle iframe load errors (e.g., X-Frame-Options)
  const handleIframeError = () => {
    setIframeError(true);
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      {iframeError ? (
        // Display an error message if iframe can't load due to X-Frame-Options
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          textAlign: 'center',
          backgroundColor: '#f8f9fa',
          color: '#333',
          padding: '20px',
          borderRadius: '10px'
        }}>
          <h1 style={{ fontSize: '2rem', marginBottom: '10px', color: '#dc3545' }}>Preview Unavailable</h1>
          <p style={{ fontSize: '1.2rem', marginBottom: '20px' }}>
            The website you're trying to preview has security settings that prevent it from being loaded in an iframe.
          </p>
          <p style={{ fontSize: '1rem', color: '#6c757d' }}>
            Please visit the website directly at:
          </p>
          <a
            href={decodedWebsiteUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: '1.1rem',
              color: '#007bff',
              textDecoration: 'none',
              marginTop: '10px'
            }}
          >
            {decodedWebsiteUrl}
          </a>
        </div>
      ) : (
        <>
          {/* Iframe for target website */}
          <iframe
            id="target-site"
            src={decodedWebsiteUrl}
            title="Target Website"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 'none',
              zIndex: 1, // Behind the AI assistant widget or draft iframe
              backgroundColor: 'transparent',
            }}
            allowTransparency={true}
            onError={handleIframeError} // Capture iframe load errors
          />
            <div
              id="ai-assistant"
              style={{
                position: 'fixed',
                bottom: '1px',
                right: '1px',
                width: '400px',
                height: '100px',
                zIndex: 1000000000, // Ensure the widget is on top
                borderRadius: '10px',
                overflow: 'hidden',
                backgroundColor: 'transparent',
              }}
            ></div>
        </>
      )}
    </div>
  );
};

export default AgentPreview;