import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useApi from '../api';
import { ToastContainer, toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import RowDelete from '../ui/RowDelete';
import Filter from '../ui/Filter';
import { FilterInterface } from '../types';
import PageModal from './Page';
import NewPage from './NewPage';
import useAnalytics from '../analytics/analytics';
import Spinner from '../documentation/Spinner';
import { formatDate } from '../utils/dateFormatter';

interface PagesProps {
  websiteId?: string;
}

const initialFilters: FilterInterface[] = [
  {
    id: 'indexed',
    name: 'Indexed',
    options: [
      { value: 'true', label: 'Yes', checked: false },
      { value: 'false', label: 'No', checked: false }
    ],
  }
];

const Pages: React.FC<PagesProps> = ({ websiteId }) => {
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(initialFilters);
  const filtersRef = useRef<FilterInterface[]>(initialFilters);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [restrictToCurrentPage, setRestrictToCurrentPage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { get, remove, post } = useApi();
  const { captureEvent } = useAnalytics();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentPage = parseInt(params.get('page') || '1');
    const currentSearch = params.get('search') || '';
    const currentFilters = filters.map(filter => ({
      ...filter,
      options: filter.options.map(option => ({
        ...option,
        checked: params.get(filter.id) === option.value
      }))
    }));
    setPage(currentPage);
    setSearchInput(currentSearch);
    setFilters(currentFilters);
    filtersRef.current = currentFilters;
    getPages(currentPage, currentSearch, currentFilters);
  }, [location.search]);

  const getPages = async (page: number = 1, search: string = '', filters: FilterInterface[] = []) => {
    try {
      setLoading(true);
      const params = new URLSearchParams({
        page: page.toString(),
        per_page: '25',
        search,
      });
      if (websiteId) {
        params.append('website_id', websiteId);
      }
      filters.forEach(filter => {
        filter.options.forEach(option => {
          if (option.checked) {
            params.append(filter.id, option.value);
          }
        });
      });
      const data = await get(`/pages/?${params.toString()}`);
      setPages(data.data.items);
      setPage(data.data.page);
      setTotalPages(data.data.pages);
    } catch (error) {
      console.error('Error fetching pages:', error);
    }
    setLoading(false);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      const params = new URLSearchParams(location.search);
      params.set('page', (page + 1).toString());
      filtersRef.current.forEach(filter => {
        const selectedOption = filter.options.find(option => option.checked);
        if (selectedOption) {
          params.set(filter.id, selectedOption.value);
        }
      });
      navigate(`?${params.toString()}`);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      const params = new URLSearchParams(location.search);
      params.set('page', (page - 1).toString());
      filtersRef.current.forEach(filter => {
        const selectedOption = filter.options.find(option => option.checked);
        if (selectedOption) {
          params.set(filter.id, selectedOption.value);
        }
      });
      navigate(`?${params.toString()}`);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      await remove(`/pages/${id}`);
      setPages(pages.filter((page: any) => page.id !== id));
      setSelectedPages(selectedPages.filter((selectedId) => selectedId !== id));
      toast.success('Page deleted successfully', toastConfig);
      captureEvent('page_deleted', {
        page_id: id,
      });
    } catch (error) {
      console.error('Error deleting page:', error);
    }
    setLoading(false);
  };

  const handleSearch = (search: string) => {
    const params = new URLSearchParams({ search, page: '1' });
    filters.forEach(filter => {
      const selectedOption = filter.options.find(option => option.checked);
      if (selectedOption) {
        params.set(filter.id, selectedOption.value);
      }
    });
    if (websiteId) {
      params.set('website_id', websiteId);
    }
    navigate(`?${params.toString()}`);
  };

  const handleFilterChange = (newFilters: FilterInterface[]) => {
    setFilters(newFilters);
    filtersRef.current = newFilters;
    const params = new URLSearchParams({ search: searchInput, page: '1' });
    newFilters.forEach(filter => {
      const selectedOption = filter.options.find(option => option.checked);
      if (selectedOption) {
        params.set(filter.id, selectedOption.value);
      }
    });
    if (websiteId) {
      params.set('website_id', websiteId);
    }
    navigate(`?${params.toString()}`);
  };

  const handleRowClick = (id: string) => {
    if (!id) return;
    setSelectedPageId(id);
  };

  const closeModal = () => {
    setSelectedPageId(null);
  };

  const handleReindex = async (page_url: string) => {
    try {
      setLoading(true);
      await post(`/index-page/${websiteId}`, { url: page_url });
      toast.success('Reindexing started!', toastConfig);
      captureEvent('reindexed_page', {
        page_url,
      });
    } catch (error) {
      toast.error('Failed to start reindexing', toastConfig);
      console.error('Reindexing error:', error);
    }
    setLoading(false);

  };

  const selectAllCheckboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectAllCheckboxRef.current) {
      const allSelected = pages.every((page: any) => selectedPages.includes(page.id));
      const someSelected = pages.some((page: any) => selectedPages.includes(page.id));
      selectAllCheckboxRef.current.indeterminate = !allSelected && someSelected;
    }
  }, [pages, selectedPages]);

  const areAllCurrentPageSelected = pages.every((page: any) => selectedPages.includes(page.id));

  const handleSelectAllCurrentPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      // Add all pages on the current page to selectedPages
      const newSelected = [...selectedPages];
      pages.forEach((page: any) => {
        if (!newSelected.includes(page.id)) {
          newSelected.push(page.id);
        }
      });
      setSelectedPages(newSelected);
    } else {
      // Remove all pages on the current page from selectedPages
      setSelectedPages(selectedPages.filter((id: string) => !pages.some((page: any) => page.id === id)));
    }
  };

  const handleSelectRow = (id: string) => {
    if (selectedPages.includes(id)) {
      // Remove from selectedPages
      setSelectedPages(selectedPages.filter((selectedId) => selectedId !== id));
    } else {
      // Add to selectedPages
      setSelectedPages([...selectedPages, id]);
    }
  };

  const handleBulkAction = async () => {
    let pagesToActOn = pages.filter((page: any) => selectedPages.includes(page.id));
    
    try {
      if (restrictToCurrentPage) {
        // Filter out pages that are already restricted
        pagesToActOn = pagesToActOn.filter((page: any) => {
          return !(page.page_config && page.page_config.current_page_only);
        });
        if (pagesToActOn.length > 0) {
          await post(`/pages/bulk-action`, { page_ids: pagesToActOn.map((p: any) => p.id), action: 'restrict_pages' });
        }
      } else {
        // Filter out pages that are not restricted
        pagesToActOn = pagesToActOn.filter((page: any) => {
          return page.page_config && page.page_config.current_page_only;
        });
        if (pagesToActOn.length > 0) {
          await post(`/pages/bulk-action`, { page_ids: pagesToActOn.map((p: any) => p.id), action: 'release_pages' });
        }
      }
    
      console.log('Performing action on pages:', pagesToActOn);
      // Refresh the page list or update the local state here
      // For example: await getPages(page, searchInput, filters);
      setSelectedPages([]);
      setRestrictToCurrentPage(false);
      toast.success('Pages updated successfully', toastConfig);
    } catch (error) {
      console.error('Error performing bulk action:', error);
      toast.error('Failed to update pages', toastConfig);
    }

  };

  return (
    <div className="flex flex-row w-full dark:bg-slate-900">
      {selectedPageId && <PageModal isOpen={!!selectedPageId} closeModal={closeModal} pageId={selectedPageId} />}
      <div className={`w-full ${websiteId ? '' : 'mt-12'} grow`}>
        {websiteId && <NewPage websiteId={websiteId} />}
        <div className="flex flex-row">
          {selectedPages.length > 0 && (
            <div className="flex flex-col justify-between w-64 h-64 p-4 bg-gray-100 mt-6 dark:bg-slate-800 rounded-lg border dark:border-slate-700">
              <div>
              <h2 className="text-lg font-semibold text-slate-800 dark:text-slate-200">Bulk Actions</h2>
              <div className="text-xs text-slate-800 dark:text-slate-300 mb-4">
                Selected {selectedPages.length} pages
              </div>
              <div className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={restrictToCurrentPage}
                    onChange={(e) => setRestrictToCurrentPage(e.target.checked)}
                    className="bg-slate-50 dark:bg-slate-900"
                  />
                  <span className="ml-2 text-slate-800 dark:text-slate-200 text-xs">Restrict search to current page</span>
                </label>
              </div>
              </div>
              {/* <svg width="24" height="24" className="fill-slate-800 dark:fill-slate-200" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/><path d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"><animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite"/></path></svg> */}

              <button
                onClick={handleBulkAction}
                className="w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-600 dark:text-slate-200 dark:hover:bg-slate-700 transition duration-200"
              >
                Save
              </button>
            </div>
          )}
          <div className='flex flex-col w-full'>
          <div className='rounded-md flex flex-row pr-16 pl-16 mt-6'>
            <div className="relative flex w-full h-12 rounded-lg bg-white border dark:bg-slate-800 dark:border-slate-700">
              <div className="grid place-items-center h-full w-12 text-slate-300 dark:border-none dark:border-slate-900">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
              <input
                className="h-full w-full border-0 text-sm text-slate-700 pr-2 focus:outline-none focus:ring-1 rounded-r-lg focus:ring-blue-200 focus:border-transparent dark:bg-slate-800 dark:text-slate-300 dark:focus:ring-1 dark:focus:ring-slate-600"
                type="text"
                id="search"
                placeholder="Search pages by URL or meta..."
                autoComplete='off'
                value={searchInput}
                onChange={(e: any) => setSearchInput(e.target.value)}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    handleSearch(e.currentTarget.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="flex flex-col mt-4 max-h-[calc(100vh-220px)] mb-8 pr-16 pl-16">
            {loading && (
              <Spinner />
            )}
            <div className="-m-1.5 overflow-x-auto scrollbar-thin">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="border rounded-lg shadow overflow-hidden dark:border-slate-900">
                  <table className="min-w-full divide-y divide-slate-200 dark:divide-none">
                    <thead className="bg-slate-50 sticky top-0 dark:bg-slate-800">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                          <input
                            type="checkbox"
                            checked={areAllCurrentPageSelected}
                            onChange={handleSelectAllCurrentPage}
                            ref={selectAllCheckboxRef}
                            className="bg-slate-50 dark:bg-slate-900"
                          />
                        </th>
                        <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                          Reindex
                        </th>
                        <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                          URL
                        </th>
                        <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                          Last Indexed
                        </th>
                        <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                          Index Status
                        </th>
                        <th scope="col" className="px-6 py-3 text-end text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200 bg-white dark:bg-slate-700 dark:divide-slate-600">
                      {pages.length > 0 &&
                        pages.map((page: any) => (
                          <tr key={page.id} className="">
                            <td className="px-6 py-4 whitespace-pre-line text-sm font-semibold text-slate-800 w-12 dark:text-slate-100 dark:hover:text-slate-200">
                              <input
                                type="checkbox"
                                checked={selectedPages.includes(page.id)}
                                onChange={() => handleSelectRow(page.id)}
                                className="bg-slate-50 dark:bg-slate-800"
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-pre-line text-sm font-semibold text-slate-800 w-12 dark:text-slate-100 dark:hover:text-slate-200">
                              <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-600 dark:text-slate-200 dark:hover:bg-slate-800/60 transition duration-200"
                                onClick={() => handleReindex(page.url)}
                              >
                                Reindex
                              </button>
                            </td>
                            <td className="px-6 py-4 hover:cursor-pointer whitespace-pre-line text-sm font-semibold text-slate-800 w-1/4 dark:text-slate-100 dark:hover:text-slate-200 break-all">
                              <div onClick={() => handleRowClick(page.id)}>
                                {page.url}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate dark:text-slate-200">
                              {page.indexed_at ? formatDate(page.indexed_at) : ''}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium dark:text-slate-200">
                              {page.index_status}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium dark:text-slate-200">
                              <RowDelete deleteCallback={() => handleDelete(page.id)} />
                            </td>
                          </tr>
                        ))}
                      {pages.length === 0 && (
                        <tr>
                          <td colSpan={6} className="px-6 py-16 whitespace-nowrap text-sm font-medium text-slate-500 text-center grow">
                            No pages
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center mt-4">
              <button
                onClick={handlePreviousPage}
                disabled={page === 1}
                className="px-4 py-2 bg-slate-200 text-slate-700 rounded hover:bg-slate-300 font-semibold dark:bg-slate-700 dark:text-slate-200 dark:hover:bg-slate-800"
              >
                Previous
              </button>
              <span className="text-sm font-semibold text-slate-600 dark:text-slate-300">
                Page {page} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={page === totalPages}
                className="px-4 py-2 bg-slate-200 text-slate-700 rounded hover:bg-slate-300 font-semibold dark:bg-slate-700 dark:text-slate-200 dark:hover:bg-slate-800"
              >
                Next
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pages;