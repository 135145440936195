import React, { useEffect, useState, useRef } from "react";
import useApi from '../api';
import SpinnerLocal from "../ui/SpinnerLocal";
import { toast } from "react-toastify";
import GoogleDriveIcon from '../icons/google_drive.png';
import HubSpotIcon from '../icons/hubspot_logo.webp';
import OntraportIcon from '../icons/ontraport_logo.webp'
import Ontraport from './Ontraport';
import { set } from "lodash";
import SalesforceIcon from '../icons/salesforce_logo.png';
import { useUser } from "../user/UserContext";
interface Integration {
    id: string;
    name: string;
    description: string;
    details: any;
}

const Integrations: React.FC = () => {
    const { get, post, remove } = useApi();
    const [integrations, setIntegrations] = useState<Integration[]>([]);
    const [fetching, setFetching] = useState<boolean>(false);
    const [creatingIntegration, setCreatingIntegration] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const { user, organization, checkLicenseType } = useUser();

    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    const name = queryParams.get('name');

    const creatingIntegrationRef = useRef(creatingIntegration);
    creatingIntegrationRef.current = creatingIntegration;

    const connectGoogle = async () => {
        const response = await post('/google-drive-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };
    
    const connectHubSpot = async () => {
        const response = await post('/hubspot-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };

    const connectSalesforce = async () => {
        const response = await post('/salesforce-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };

    const connectOntraport = async () => {
        setModalVisible(true);
    };

    const createIntegration = async (appId?: string, apiKey?: string, appName?: string) => {
        if (creatingIntegrationRef.current) return;
        setCreatingIntegration(true);

        try {
            await post('/integrations/', {
                code: code,
                state: state,
                name: appName || name,
                appId,
                apiKey
            });
            if (code) {
                window.location.href = '/integrations';
            }
        } finally {
            setCreatingIntegration(false);
            setModalVisible(false);
            toast.success('Integration created successfully', { autoClose: 2000 });
        }
    };

    const deleteIntegration = async (id: string) => {
        try {
            await remove(`/integrations/${id}`);
            toast.success('Integration disconnected successfully', { autoClose: 2000 });
            setIntegrations(integrations.filter(integration => integration.id !== id));
        } catch (error) {
            toast.error('Failed to delete integration', { autoClose: 2000 });
        }
    };

    useEffect(() => {
        const getIntegrations = async () => {
            setFetching(true);
            try {
                const response = await get('/integrations/');
                setIntegrations(response.data);
            } catch (error) {
                toast.error('Failed to fetch integrations', { autoClose: 2000 });
            } finally {
                setFetching(false);
            }
        };

        getIntegrations();
    }, []);

    useEffect(() => {
        if (name && code && !creatingIntegrationRef.current) {
            createIntegration();
        }
    }, [name]);

    const renderIntegrationCard = (integrationName: string, sysName: string, integrationDescription: string, connectHandler: () => void, icon: string) => {
        const existingIntegration = integrations.find(integration => integration.name === sysName);

        return (
            <div className="flex flex-col p-3 border shadow-sm rounded-xl w-full mt-4 dark:border-slate-700 dark:bg-slate-800">
                <div className="flex flex-row justify-between">
                    <div className="pb-4 text-lg font-medium leading-6 text-gray-500 dark:text-slate-200">
                        {integrationName}
                    </div>
                    <div className="items-center">
                        <div className="flex-shrink-0 items-center justify-center pb-3">
                            <img src={icon} alt={`${integrationName} Icon`} className="h-10" />
                        </div>
                    </div>
                </div>
                <div className="flex grow">
                    <p className="text-sm dark:text-slate-200 mb-4">{integrationDescription}</p>
                </div>
                {existingIntegration ? (
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none"
                            onClick={() => deleteIntegration(existingIntegration.id)}
                        >
                            Disconnect
                        </button>
                ) : (
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none dark:bg-slate-600 dark:text-slate-200 dark:hover:bg-slate-700 transition duration-200"
                        onClick={connectHandler}
                        disabled={!checkLicenseType('pro')}
                    >
                        {checkLicenseType('pro') ? 'Connect' : 'Upgrade to Pro'}
                    </button>
                )}
                {modalVisible && sysName === 'ontraport' && (
                    <Ontraport
                        createIntegration={createIntegration}
                        setModalVisible={setModalVisible}
                        modalVisible={modalVisible}
                        ontraportAppId={existingIntegration?.details.app_id}
                        ontraportApiKey={existingIntegration?.details.api_key}
                    />
                )}
                </div>
        );
    };

    return (
        <div className="p-8 h-full w-full dark:bg-slate-900">
        <div className="flex flex-row items-center justify-between border-b pb-4">
            <h1 className="text-2xl font-semibold text-gray-700 mt-2 dark:text-slate-200">Integrations</h1>
        </div>
        <div className="flex mt-8">
            <div className="flex flex-row w-2/3 space-x-4">
                {renderIntegrationCard('HubSpot', 'hubspot', 'Creates an Aimdoc form in your HubSpot instance and submits lead data when a lead is created in Aimdoc. The chat transcript will be linked in HubSpot.', connectHubSpot, HubSpotIcon)}
                {renderIntegrationCard('Ontraport', 'ontraport', 'Creates a contact in Ontraport when a lead is created in Aimdoc.', connectOntraport, OntraportIcon)}
                {renderIntegrationCard(
                    'Salesforce', 
                    'salesforce', 
                    'Creates a contact in Salesforce when a lead is created in Aimdoc. The chat transcript will be linked as a Note.', 
                    connectSalesforce, 
                    SalesforceIcon
                )}
            </div>
        </div>
        {fetching && (
            <div className="w-full">
                <SpinnerLocal />
            </div>
        )}
    </div>
    );
};

export default Integrations;