import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, Link, Navigate } from 'react-router-dom';
import Register from './login/Register';
import Login from './login/Login';
import NavBar from "./navbar/NavBar";
import Documents from "./documentation/Documents";
import UserProfile from "./user/UserProfile";
import Dashboard from "./Dashboard";
import Integrations from "./integrations/Integrations";
import axios from "axios";
import Vector from "./admin/MilvusAdmin";
import useApi from './api';
import { set } from "lodash";
import { useUser } from './user/UserContext';
import PrivacyPolicy from './PrivacyPolicy';
import Agent from './agents/Agent';
import AgentsList from './agents/AgentsList';
import CreateAgent from "./agents/ConfigureAgent";
import LeadList from "./agents/LeadsList";
import Lead from "./agents/Lead";
import DeleteOrganization from "./user/DeleteOrganization";
import Sessions from "./agents/Sessions";
import Session from "./agents/Session";
import Websites from "./websites/Websites";
import Website from "./websites/Website";
import Analytics from "./analytics/AgentAnalytics";
import AgentFullScreen from "./agents/AgentFullscreen";
import WebsitePreview from "./agents/WebsitePreview";
import Questions from "./questions/Questions";
import QuestionView from "./questions/QuestionView";
import { conLog } from './utils/conLog';
import { ToastContainer } from 'react-toastify';
import logo_symbol from '../logo_symbol.png';
const EmbedAgent = lazy(() => import('./agents/EmbedAgent'));

const Main = () => {
  const location = useLocation();
  const { user, loading } = useUser();

  if (!user && !loading && location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password' && !location.pathname.includes('/agent/') && !location.pathname.includes('/agent-embed')) {
    conLog("User not logged in, redirecting to login page");
    return <Navigate to="/login" />;
  }

  return (
    <div className="">
      {loading && !user && !location.pathname.includes('/login') && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-white/50 dark:bg-slate-900/50 backdrop-blur-sm">
          <img src={logo_symbol} alt="logo" className="w-16 h-16 animate-bounce" />
        </div>
      )}
      <ToastContainer />
      <div className="fixed h-full w-full flex overflow-y-hidden">
        {user && !location.pathname.includes('/agent-embed') && <NavBar selectedItem={location.pathname} />}
        <div className={`h-full w-full flex ${!location.pathname.includes('/agent-embed') ? 'dark:bg-slate-800' : ''}`}>
          <Routes>
            {user ? (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/admin/:id" element={<Vector />} />
                <Route path="/documents" element={<Documents />} />
                <Route path="/user-settings" element={<UserProfile />} />
                <Route path="/integrations" element={<Integrations />} />
                {/* <Route path="/chats" element={<Chats />} />
                <Route path="/chats/:id" element={
                  <div className="flex grow h-full">
                    <Chats />
                    <AgentChat />
                  </div>
                }/> */}
                <Route path="/agents" element={<AgentsList />} />
                <Route path="/agents/:id" element={<CreateAgent />} />
                <Route path="/websites" element={<Websites />} />
                <Route path="/websites/:id" element={<Website />} />
                <Route path="/leads" element={<LeadList />} />
                <Route path="/leads/:id" element={<Lead />} />
                <Route path="/sessions" element={<Sessions />} />
                <Route path="/sessions/:id" element={<Session />} />
                <Route path="/questions" element={<Questions />} />
                <Route path="/questions/:id" element={<QuestionView />} />
              </>
            ) : null} {/* Add colon here */}
            <Route path="/login" element={<Login />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/agent/:id" element={<AgentFullScreen welcomeMessage={'Welcome!'} />} />
            <Route path="/agent-embed/:agentId" element={
              <Suspense>
                <EmbedAgent />
              </Suspense>
            } />
            <Route path="/agent-embed-draft/:agentId" element={<EmbedAgent isDraft={true} />} />
            <Route path="/agent-embed-preview/:agentId/:websiteUrl" element={<WebsitePreview />} />
            <Route path="/agent/:id/:lead_id" element={<AgentFullScreen welcomeMessage={'Welcome!'} />} />
            <Route path="/organizations/:id/delete" element={<DeleteOrganization />} />
          </Routes>
        </div>
      </div>
    </div>
  )

};

export default Main;
