import React, { useState, useEffect } from 'react';
import useApi from './../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useUser } from './../user/UserContext';
import { conLog } from './../utils/conLog';

interface AnalyticsData {
    total_messages_last_7_days: number;
    total_conversations_last_7_days: number;
    messages_per_day: {
        day: string;
        message_count: number;
    }[];
    conversations_per_day: {
        day: string;
        conversation_count: number;
    }[];

}

const Analytics = () => {
    const { get } = useApi();
    const { user } = useUser();
    const [analyticsData, setAnalyticsData] = useState<AnalyticsData | null>(null);

    useEffect(() => {
        fetchAnalyticsData();
    }, []);

    const fetchAnalyticsData = async () => {
        try {
            const response = await get('/analytics/');
            conLog(response.data);
            setAnalyticsData(response.data);
        } catch (error) {
            toast.error('Error fetching analytics data');
        }
    };

    if (!analyticsData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex flex-row w-full bg-gradient-to-br from-blue-100 to-blue-500 overflow-y-auto scrollbar-thin justify-center dark:from-slate-900 dark:to-slate-500">
            {/* <div className="total-widgets">
                <div className="widget">
                    <h2>Total Messages in Last 7 Days</h2>
                    <p>{analyticsData.total_messages_last_7_days}</p>
                </div>
                <div className="widget">
                    <h2>Total Conversations in Last 7 Days</h2>
                    <p>{analyticsData.total_conversations_last_7_days}</p>
                </div>
            </div> */}
            <div className="w-2/3 flex justify-center items-center space-x-8">
            <div className="flex flex-col w-full items-center py-4 pr-2 border rounded-lg shadow-md bg-white">
                <div className="font-semibold text-xl text-slate-600 mb-2">
                    Messages Per Day
                </div>
                {analyticsData.messages_per_day.length === 0 ? (
                    <div className="h-[300px] flex font-medium text-slate-500 pt-16">No data yet!</div>
                ) : (
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={analyticsData.messages_per_day}>
                            <XAxis dataKey="day" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="message_count" fill="#0f172a" />
                        </BarChart>
                    </ResponsiveContainer>
                )}
            </div>
            <div className="flex flex-col w-full items-center py-4 pr-2 border rounded-lg shadow-md bg-white">
                <div className="font-semibold text-xl text-slate-600 mb-2">
                        Conversations Per Day
                </div>
                {analyticsData.conversations_per_day.length === 0 ? (
                    <div className="h-[300px] flex font-medium text-slate-500 pt-16">No data yet!</div>
                ) : (
                    <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={analyticsData.conversations_per_day}>
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="conversation_count" fill="#1e3a8a" />
                    </BarChart>
                </ResponsiveContainer>
                )}
                </div>
            </div>
        </div>
    );
};

export default Analytics;