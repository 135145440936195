import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useApi from '../api';
import { ToastContainer, toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { formatDate } from '../utils/dateFormatter';
import RowDelete from '../ui/RowDelete';
import Filter from '../ui/Filter';
import { FilterInterface } from '../types';
import useAnalytics from '../analytics/analytics';
import BeatLoader from '../ui/BeatLoader';
import { Transition, Dialog } from '@headlessui/react';
import { Fragment } from 'react';
import CreateQuestion from './CreateQuestion';


interface QuestionsProps {
    dashboard?: boolean;
}

const initialFilters: FilterInterface[] = [
    {
        id: 'status',
        name: 'Status',
        options: [
            { value: 'answered', label: 'Answered', checked: false },
            { value: 'unanswered', label: 'Unanswered', checked: false },
        ],
    },
];

const Questions: React.FC<QuestionsProps> = ({ dashboard }) => {
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState(initialFilters);
    const filtersRef = useRef<FilterInterface[]>(initialFilters);
    const [totalPages, setTotalPages] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { get, remove } = useApi();
    const { captureEvent } = useAnalytics();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const currentPage = parseInt(params.get('page') || '1');
        const currentSearch = params.get('search') || '';
        const currentFilters = initialFilters.map((filter) => ({
            ...filter,
            options: filter.options.map((option) => ({
                ...option,
                checked: params.get(filter.id) === option.value,
            })),
        }));
        setPage(currentPage);
        setSearchInput(currentSearch);
        setFilters(currentFilters);
        filtersRef.current = currentFilters;
        getQuestions(currentPage, currentSearch, currentFilters);
    }, [location.search]);

    const getQuestions = async (
        page: number = 1,
        search: string = '',
        filters: FilterInterface[] = []
    ) => {
        try {
            setLoading(true);
            const params = new URLSearchParams({
                page: page.toString(),
                per_page: '25',
                search,
            });
            filters.forEach((filter) => {
                filter.options.forEach((option) => {
                    if (option.checked) {
                        params.append(filter.id, option.value);
                    }
                });
            });
            const data = await get(`/questions/?${params.toString()}`);
            setQuestions(data.data.items);
            setPage(data.data.page);
            setTotalPages(data.data.pages);
            captureEvent('viewed_questions', {});
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
        setLoading(false);
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            const params = new URLSearchParams(location.search);
            params.set('page', (page + 1).toString());
            filtersRef.current.forEach((filter) => {
                const selectedOption = filter.options.find((option) => option.checked);
                if (selectedOption) {
                    params.set(filter.id, selectedOption.value);
                }
            });
            navigate(`?${params.toString()}`);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            const params = new URLSearchParams(location.search);
            params.set('page', (page - 1).toString());
            filtersRef.current.forEach((filter) => {
                const selectedOption = filter.options.find((option) => option.checked);
                if (selectedOption) {
                    params.set(filter.id, selectedOption.value);
                }
            });
            navigate(`?${params.toString()}`);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            await remove(`/questions/${id}`);
            setQuestions(questions.filter((question: any) => question.id !== id));
            toast.success('Question deleted successfully', toastConfig);
            captureEvent('delete_question', {
                question_id: id,
            });
        } catch (error) {
            console.error('Error deleting question:', error);
        }
        setLoading(false);
    };

    const handleSearch = (search: string) => {
        const params = new URLSearchParams({ search, page: '1' });
        filters.forEach((filter) => {
            const selectedOption = filter.options.find((option) => option.checked);
            if (selectedOption) {
                params.set(filter.id, selectedOption.value);
            }
        });
        navigate(`?${params.toString()}`);
    };

    const handleFilterChange = (newFilters: FilterInterface[]) => {
        setFilters(newFilters);
        filtersRef.current = newFilters;
        const params = new URLSearchParams({ search: searchInput, page: '1' });
        newFilters.forEach((filter) => {
            const selectedOption = filter.options.find((option) => option.checked);
            if (selectedOption) {
                params.set(filter.id, selectedOption.value);
            }
        });
        navigate(`?${params.toString()}`);
    };

    return (
        <div
            className={`pt-12 flex flex-row w-full ${dashboard ? 'bg-transparent' : 'dark:bg-slate-900'
                }`}
        >
            <div className={`${dashboard ? 'hidden' : ''}`}>
                <Filter filters={filters} onFilterChange={handleFilterChange} />
            </div>
            <div className={`pr-12 w-full grow`}>
                <div className="mx-auto rounded-md flex flex-row">
                    <div className="relative flex w-full h-12 rounded-lg bg-white border dark:bg-slate-800 dark:border-slate-700">
                        <div className="grid place-items-center h-full w-12 text-slate-300 dark:border-none dark:border-slate-900">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                            </svg>
                        </div>
                        <input
                            className="h-full w-full border-0 text-sm text-slate-700 pr-2 focus:outline-none focus:ring-1 rounded-r-lg focus:ring-blue-200 focus:border-transparent dark:bg-slate-800 dark:text-slate-300 dark:focus:ring-1 dark:focus:ring-slate-600"
                            type="text"
                            id="search"
                            placeholder="Search questions..."
                            autoComplete="off"
                            value={searchInput}
                            onChange={(e: any) => setSearchInput(e.target.value)}
                            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') {
                                    handleSearch(e.currentTarget.value);
                                }
                            }}
                        />
                    </div>
                    <div className="relative w-full h-12 rounded-lg overflow-hidden">
                        <button onClick={() => setShowModal(true)} className="absolute right-0 top-0 h-full px-4 text-sm text-white bg-blue-500 hover:bg-blue-600 rounded-lg focus-within:shadow-lg">
                            New Question
                        </button>
                    </div>
                </div>
                <div className="flex flex-col mt-8 max-h-[calc(100vh-220px)] mb-8">
                    <div className="-m-1.5 overflow-x-auto scrollbar-thin pb-12">
                        <div className="p-1.5 min-w-full inline-block align-middle">
                            <div className="border rounded-lg shadow overflow-hidden dark:border-slate-900">
                                <table className="min-w-full divide-y divide-slate-200 dark:divide-none">
                                    <thead className="bg-slate-50 sticky top-0 dark:bg-slate-800">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400"
                                            ></th>
                                            <th
                                                scope="col"
                                                className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400"
                                            >
                                                Question
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400"
                                            >
                                                Answer
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400"
                                            >
                                                Status
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400"
                                            >
                                                Created At
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400"
                                            >
                                                Delete
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-slate-200 bg-white dark:bg-slate-700 dark:divide-slate-600">
                                        {questions.length > 0 && !loading &&
                                            questions.map((question: any) => (
                                                <tr key={question.id} className="">
                                                    <td className="px-6 py-4 whitespace-pre-line text-sm font-semibold text-slate-800 w-1/6 hover:text-blue-500 dark:text-slate-100 dark:hover:text-slate-200">
                                                        <Link
                                                            to={`/questions/${question.id}`}
                                                            className="flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-600 dark:text-slate-200 dark:hover:bg-slate-800/60 transition duration-200"
                                                        >
                                                            View
                                                        </Link>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-pre-line text-sm font-semibold items-center text-slate-800 w-1/4 hover:text-blue-500 dark:text-slate-100 dark:hover:text-slate-200">
                                                        <div className="flex flex-row justify-center">
                                                            {question.question}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-pre-line text-sm text-slate-800 items-center truncate hover:text-blue-500 w-1/4 dark:text-slate-100 dark:hover:text-slate-200"> 
                                                        <div className="flex flex-row justify-center">
                                                            {question.answer}
                                                        </div>
                                                    </td>
                                                    <td className="items-center px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate hover:text-blue-500 w-1/6 dark:text-slate-100 dark:hover:text-slate-200">
                                                        <div className="flex flex-row justify-center">
                                                            {question.status === 'answered' ? (
                                                                <div className="flex flex-row items-center space-x-1 bg-green-100 border-green-200 border text-green-800 text-sm rounded-lg py-1 px-2 text-xs dark:bg-green-700 dark:border-green-600 dark:text-green-200">
                                                                    <div className="font-semibold">Answered</div>
                                                                </div>
                                                            ) : (
                                                                <div className="flex flex-row items-center space-x-1 bg-slate-100 border-red-200 border text-red-500 text-sm rounded-lg py-1 px-2 text-xs dark:bg-red-800 dark:border-red-700 dark:text-red-300">
                                                                    <div className="font-semibold">Unanswered</div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-pre-line text-sm font-semibold text-slate-800 w-1/6 hover:text-blue-500 dark:text-slate-100 dark:hover:text-slate-200">
                                                        <div className="flex flex-row justify-center">
                                                            {formatDate(question.created_at)}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                                                        <RowDelete
                                                            deleteCallback={() => handleDelete(question.id)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        {questions.length === 0 && !loading && (
                                            <tr>
                                                <td
                                                    colSpan={6}
                                                    className="px-6 py-16 whitespace-nowrap text-sm font-medium text-slate-500 text-center grow dark:text-slate-200"
                                                >
                                                    No questions
                                                </td>
                                            </tr>
                                        )}
                                        {loading && (
                                            <tr>
                                                <td
                                                    colSpan={6}
                                                    className="px-6 py-16 whitespace-nowrap text-sm font-medium text-slate-500 text-center grow dark:text-slate-200"
                                                >
                                                    <BeatLoader />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <button
                        onClick={handlePreviousPage}
                        disabled={page === 1}
                        className="px-4 py-2 bg-slate-200 text-slate-700 rounded hover:bg-slate-300 font-semibold dark:bg-slate-700 dark:text-slate-200 dark:hover:bg-slate-800"
                    >
                        Previous
                    </button>
                    <span className="text-sm font-semibold text-slate-600 dark:text-slate-300">
                        Page {page} of {totalPages}
                    </span>
                    <button
                        onClick={handleNextPage}
                        disabled={page === totalPages}
                        className="px-4 py-2 bg-slate-200 text-slate-700 rounded hover:bg-slate-300 font-semibold dark:bg-slate-700 dark:text-slate-200 dark:hover:bg-slate-800"
                    >
                        Next
                    </button>
                </div>
            </div>
            <Transition appear show={showModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setShowModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0">
                        <div className="flex min-h-full items-center justify-center text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all">
                                    <CreateQuestion />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default Questions;