import React, { useEffect, memo, useState } from 'react';
import { conLog } from '../../utils/conLog';

const TidyCalWidget = memo(({ url }: { url: string }) => {

    return (
        <div style={{ width: '100%', height: '1000px', border: 'none' }}>
        <iframe
            src={url}
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            title="TidyCal Widget"
        />
    </div>
    );
});

export default TidyCalWidget;
