import React, { useEffect, useState, useContext, useRef, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DocumentFileInterface, DocumentationInterface } from '../types';
import useApi from '../api';
import { useOutsideAlerter } from '../useOutsideAlerter';
import DocUpload from './DocUpload';
import { set } from 'lodash';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, Transition } from '@headlessui/react'
import IntegrationList from '../integrations/IntegrationList';
import EmbedAgent from '../agents/EmbedAgent';
import RowDelete from '../ui/RowDelete';
import BeatLoader from '../ui/BeatLoader';


const Documents: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState<DocumentFileInterface[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate(); // Create a navigate function
  const { get, getStream, post, postStream, put, patch, remove } = useApi();

  // get search params
  const search = new URLSearchParams(window.location.search);
  const createType = search.get('createType');
  const newDocument = search.get('new');

  const toggleModal = () => {
    setShowModal(!showModal);
    // clear search params
    navigate('/documents');
  };

  const modalRef = useRef(null);

  useOutsideAlerter(modalRef, toggleModal);

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = async () => {
    try {
      setLoading(true);

      const data = await get('/documentation/');
      console.log(data.data)

      setDocuments(data.data);
    } catch (error) {
      console.error('Error fetching products:', error);

    }
    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    // get document by id
    const document = documents.find((doc) => doc.id === id);
    if (!document) {
      return;
    }

    try {
      if (document.type === 'document') {
        const response = await remove(`/documentation/${id}`);
        setDocuments(documents.filter((document: DocumentFileInterface) => document.id !== id));
        toast.success('Documentation deleted successfully', toastConfig);
      } else if (document.type === 'file') {
        const response = await remove(`/files/${id}`);
        setDocuments(documents.filter((document: DocumentFileInterface) => document.id !== id));
        toast.success('File deleted successfully', toastConfig);
      }
    } catch (error) {
      console.error('Error deleting documentation:', error);
    }
    setLoading(false);
  };

  const handleSearch = async (search: string) => {
    setSearchInput(search);
    try {
      setLoading(true);
      const data = await get(`/documentation/?search=${search}`);
      console.log(data.data)
      setDocuments(data.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    setLoading(false);
  };


  const createWithAI = () => {
    setShowModal(false);
    navigate('/documents?createType=chat');
  };

  const fromScratch = () => {
    setShowModal(false);

    navigate('/documents?createType=scratch');
  };

  const fromExisting = () => {
    setShowModal(false);
    navigate('/documents?createType=upload');
  };


  const fromText = () => {
    setShowModal(false);
    navigate('/documents?createType=text');
  };

  useEffect(() => {
    if (newDocument) {
      setShowModal(true);
    }
  }, [newDocument]);


  return (
    <>
      <ToastContainer />
      {!createType && (
        <div className="p-12 overflow-y-auto max-h-[calc(100vh-1px)] scrollbar-thin grow dark:bg-slate-900">
          <div className='mx-auto rounded-md flex flex-row'>
            <div className="relative flex w-full h-12 rounded-lg bg-white border dark:bg-slate-800 dark:border-slate-700">
              <div className="grid place-items-center h-full w-12 text-slate-300 dark:border-none dark:border-slate-900">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 dark:stroke-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
              <input
                className="h-full w-full border-0 text-sm text-slate-700 pr-2 focus:outline-none focus:ring-1 rounded-r-lg focus:ring-blue-200 focus:border-transparent dark:bg-slate-800 dark:focus:border-none dark:text-slate-300 dark:focus:ring-slate-500"
                type="text"
                id="search"
                placeholder="Search for documentation.."
                autoComplete='off'
                value={searchInput}
                onChange={(e: any) => setSearchInput(e.target.value)}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    handleSearch(e.currentTarget.value);
                  }
                }}
              />

            </div>
            <div className="relative w-full h-12 rounded-lg overflow-hidden">
              <button onClick={() => setShowModal(true)} className="absolute right-0 top-0 h-full px-4 text-sm text-white bg-blue-500 hover:bg-blue-600 rounded-lg focus-within:shadow-lg">
                New Document
              </button>
            </div>
          </div>
          <div className="flex flex-col mt-8">
            <div className="-m-1.5 overflow-x-auto pb-12">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="border rounded-lg shadow overflow-hidden dark:border-slate-800">
                  <table className="min-w-full divide-y divide-slate-200 dark:divide-none">
                    <thead className="bg-slate-50 sticky top-0 dark:bg-slate-800">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                          Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                          Description
                        </th>
                        <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                          Type
                        </th>
                        <th scope="col" className="px-6 py-3 text-end text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200 bg-white dark:bg-slate-700 dark:divide-slate-600">
                      {documents.length > 0 &&
                        documents.map((document) => (
                          <tr key={document.id}>
                            <td className={`px-6 py-4 whitespace-pre-line text-sm font-semibold text-slate-800 w-1/4 ${document.type === 'file' ? '' : 'hover:text-blue-500 dark:hover:text-slate-200'} dark:text-slate-100`}>
                              {document.type === 'file' ? (
                                <div>
                                  {document.name}
                                </div>
                              ) : (
                                <Link
                                  to={`/documents/${document.id}`}
                                  className="hover:underline"
                                >
                                  {document.name || "DRAFT"}
                                </Link>
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate dark:text-slate-100">
                              {document.description || ""}
                            </td>
                            <td className="px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate dark:text-slate-100">
                              {document.type}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium dark:text-slate-100">
                              <RowDelete
                                deleteCallback={() => handleDelete(document.id)}
                                deleteMessage={`Are you sure you want to delete ${document.name}? This will remove the document from your live agent's knowledge base. This cannot be undone.`}
                              />
                            </td>
                          </tr>
                        ))}
                      {documents.length === 0 && !loading && (
                        <tr>
                          <td colSpan={5} className="px-6 py-16 whitespace-nowrap text-sm font-medium text-slate-500 text-center grow dark:text-slate-200">
                            No documents
                          </td>
                        </tr>
                      )}
                      {loading && (
                        <tr>
                          <td colSpan={5} className="px-6 py-16 whitespace-nowrap text-sm font-medium text-slate-500 text-center grow dark:text-slate-200">
                            <BeatLoader />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      )}
      {createType === 'upload' && (
        <DocUpload />
      )}
      {/* <EmbedAgent agentId='7ec1b622-d128-4cad-a65c-04ae02d4ceb3' /> */}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setShowModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-slate-900 mb-2"
                  >
                    Create New Document
                  </Dialog.Title>
                  <Dialog.Description className="text-sm text-slate-500 mb-6">
                    Aimdoc allows you to create documents many different ways.
                  </Dialog.Description>
                  <div className="flex justify-between flex flex-col space-y-4">
                    <div onClick={fromExisting} className="bg-slate-100 hover:bg-slate-300 text-black font-thin py-4 px-8 rounded border text-center rounded-xl">
                      Upload files
                    </div>
                    <IntegrationList />
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </>
  );
};

export default Documents;