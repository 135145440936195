import React, { useEffect, useState, useContext, useRef, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DocumentationInterface } from '../types';
import useApi from '../api';
import { useOutsideAlerter } from '../useOutsideAlerter';
import { set } from 'lodash';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, Transition } from '@headlessui/react'
import IntegrationList from '../integrations/IntegrationList';
import CreateAgent from './CreateAgent';
import RowDelete from '../ui/RowDelete';
import useAnalytics from '../analytics/analytics';
import SpinnerLocal from '../ui/BeatLoader';
import { Toast } from 'react-toastify/dist/components';
import BeatLoader from '../ui/BeatLoader';


const AgentLists: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate(); // Create a navigate function
  const { get, getStream, post, postStream, put, patch, remove } = useApi();
  const { captureEvent } = useAnalytics();

  // get search params
  const search = new URLSearchParams(window.location.search);
  const newAgent = search.get('new');

  const toggleModal = () => {
    setShowModal(!showModal);
    // clear search params
    navigate('/agents');
  };

  useEffect(() => {
    getAgents();
  }, []);

  const getAgents = async () => {
    try {
      setLoading(true);
      const data = await get('/agents/');
      setAgents(data.data);
    } catch (error) {
      console.error('Error fetching products:', error);

    }
    setLoading(false);
  };

  const handleDelete = async (id: string) => {

    try {
      const response = await remove(`/agents/${id}`);
      setAgents(agents.filter((agent: any) => agent.parent_agent_id !== id));
      toast.success('Agent deleted successfully', toastConfig);
      captureEvent('delete_agent', {
        agent_id: id,
      });
    } catch (error) {
      console.error('Error deleting Agent:', error);
    }
    setLoading(false);
  };

  const handleSearch = async (search: string) => {
    setSearchInput(search);
    try {
      setLoading(true);
      const data = await get(`/agents/?search=${search}`);
      setAgents(data.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (newAgent === 'true') {
      setShowModal(true);
    }
  }, [newAgent]);

  return (
    <>
      <div className="p-12 overflow-y-auto max-h-[calc(100vh-1px)] scrollbar-thin grow dark:bg-slate-900">
        <div className='mx-auto rounded-md flex flex-row'>
          <div className="relative flex w-full h-12 rounded-lg bg-white border dark:bg-slate-800 dark:border-slate-700">
            <div className="grid place-items-center h-full w-12 text-slate-300 dark:border-none dark:border-slate-900">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 dark:stroke-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <input
              className="h-full w-full border-0 text-sm text-slate-700 pr-2 focus:outline-none focus:ring-1 rounded-r-lg focus:ring-blue-200 focus:border-transparent dark:bg-slate-800 dark:text-slate-300 dark:focus:ring-1 dark:focus:ring-slate-600"
              type="text"
              id="search"
              placeholder="Search agents..."
              autoComplete='off'
              value={searchInput}
              onChange={(e: any) => setSearchInput(e.target.value)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleSearch(e.currentTarget.value);
                }
              }}
            />

          </div>
          <div className="relative w-full h-12 rounded-lg overflow-hidden">
            <button onClick={() => setShowModal(true)} className="absolute right-0 top-0 h-full px-4 text-sm text-white bg-blue-500 hover:bg-blue-600 rounded-lg focus-within:shadow-lg">
              New Agent
            </button>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-m-1.5 overflow-x-auto pb-12">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="border rounded-lg shadow overflow-hidden dark:border-slate-900">
                <table className="min-w-full divide-y divide-slate-200 dark:divide-none">
                  <thead className="bg-slate-50 sticky top-0 dark:bg-slate-800">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Description
                      </th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Persona
                      </th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Goal
                      </th>

                      <th scope="col" className="px-6 py-3 text-end text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-200 bg-white dark:bg-slate-700 dark:divide-slate-600">
                    {agents.length > 0 &&
                      agents.map((agent: any) => (
                        <tr key={agent.id} className="">
                          <td className="px-6 py-4 whitespace-pre-line text-sm font-semibold text-slate-800 w-1/4 hover:text-blue-500 dark:text-slate-100 dark:hover:text-slate-200">
                            <Link
                              to={`/agents/${agent.parent_agent_id}`}
                              className="hover:underline"
                            >
                              {agent.name}
                            </Link>
                          </td>
                          <td className="px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate dark:text-slate-200">
                            {agent.description.length > 200 ? agent.description.substring(0, 200) + '...' : agent.description}
                          </td>
                          <td className="px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate dark:text-slate-200">
                            {agent.persona.length > 200 ? agent.persona.substring(0, 200) + '...' : agent.persona}
                          </td>
                          <td className="px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate dark:text-slate-200">
                            {agent.goal.length > 200 ? agent.goal.substring(0, 200) + '...' : agent.goal}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium dark:text-slate-200">
                            <RowDelete 
                              deleteCallback={() => handleDelete(agent.parent_agent_id)} 
                              deleteMessage={`Are you sure you want to delete ${agent.name}? This action cannot be undone.`}  
                            />
                          </td>
                        </tr>
                      ))}
                    {agents.length === 0 && !loading && (
                      <tr>
                        <td colSpan={5} className="px-6 py-16 whitespace-nowrap text-sm font-medium text-slate-500 text-center grow dark:text-slate-200">
                          No agents
                        </td>
                      </tr>
                    )}
                    {loading && (
                      <tr>
                        <td colSpan={5} className="px-6 py-16 whitespace-nowrap text-sm font-medium text-slate-500 text-center grow dark:text-slate-200">
                          <BeatLoader />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <CreateAgent
          setShowModal={toggleModal}
          showModal={showModal}
        />
      )}

    </>
  );
};

export default AgentLists;