import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useUser } from './../user/UserContext';
import { conLog } from './../utils/conLog';

const useAnalytics = () => {
  const posthog = usePostHog();
  const { user } = useUser();

  useEffect(() => {
    if (posthog && user) {
      posthog.identify(user.id, {
        email: user.email,
      });
      if (user.organization_id) {
        posthog.group('organization', user.organization_id);
      }
    } else {
      console.warn('PostHog or user context is not initialized');
    }
  }, [user]);

  const captureEvent = (eventName: string, eventProperties: Record<string, any> = {}) => {
    if (posthog && user) {
        conLog('Capturing event', eventName, eventProperties)
    try {
        posthog.capture(eventName, {
            user_id: user.id,
            organization_id: user.organization_id,
            ...eventProperties
        });
    } catch (error) {
        console.error('Error capturing event:', error);
    }
    } else {
      console.warn('PostHog or user context is not initialized');
    }
  };

  return {
    captureEvent
  };
};

export default useAnalytics;