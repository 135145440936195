import { IconPickerItem } from './IconListItem'
import { iconList } from './iconList'
import { useState, useEffect, Fragment } from 'react'
import { IconList } from './iconType'
import { Dialog, Transition, Input } from '@headlessui/react'
import * as React from 'react'

interface IconPickerProps {
  value: IconList
  library: 'outline' | 'solid' | 'mini'
  onChange: (value: IconList) => void
  hideSearch?: boolean
}

const IconPicker: React.FC<IconPickerProps> = ({
  value,
  library,
  onChange,
  hideSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchString, setSearchString] = useState('')

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value)
  }

  return (
    <>
      <div
        className="rounded-3xl p-4 hover:cursor-pointer border border-slate-200 dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-800 transition duration-200"
        onClick={openModal}
      >
        <IconPickerItem icon={value} library={library} />
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                  <Dialog.Title
                    as="h3"
                    className="text-xl text-center font-medium leading-6 text-slate-900 mb-4 dark:text-slate-50"
                  >
                    Select an Icon
                  </Dialog.Title>

                  {!hideSearch && (
                    <Input
                      className="w-full mb-4 p-2 border border-gray-300 rounded-md dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-300 dark:text-slate-100"
                      onChange={onChangeSearch}
                      value={searchString}
                      placeholder="Search icons"
                    />
                  )}

                  <div className="grid grid-cols-6 gap-4 max-h-96 h-96 in-h-96 overflow-y-auto">
                    {iconList
                      .filter((i: string) =>
                        i.toLowerCase().includes(searchString.toLowerCase())
                      )
                      .map((icon: string) => (
                        <div className="p-2">
                            <div
                            key={icon}
                            className="flex cursor-pointer justify-center py-4 items-center hover:bg-gray-100 border rounded-3xl dark:hover:bg-slate-600 dark:border-slate-600 transition duration-200"
                            onClick={() => {
                                onChange(icon as IconList)
                                closeModal()
                                setSearchString('')
                            }}
                            >
                            <IconPickerItem
                                icon={icon as IconList}
                                library={library}
                            />
                            </div>
                        </div>
                      ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export { IconPicker }