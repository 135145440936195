function BeatLoader() {
    return (
        <div className='flex space-x-2 justify-center items-center bg-transparent p-8'>
        <div className='h-5 w-5 bg-slate-100 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
        <div className='h-5 w-5 bg-slate-100 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
        <div className='h-5 w-5 bg-slate-100 rounded-full animate-bounce'></div>
    </div>
    );
}

export default BeatLoader