import { Dialog, Transition, Fieldset, Field, Label, Input } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import { Fragment, useState, useEffect } from 'react'
import useApi from '../../api';
import { set } from 'lodash';
import Spinner from '../../documentation/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import toastConfig from '../../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import clsx from 'clsx'
import useAnalytics from '../../analytics/analytics';
import WebsitesList from './WebsitesList';
import BeatLoader from '../../ui/BeatLoader';
import { useUser } from '../../user/UserContext';

export default function Website({ agentId, indexedPages, setIndexedPages, selectedPages, setSelectedPages, website, setWebsite, agentName }: { agentId: string | undefined, indexedPages: any, setIndexedPages: any, selectedPages: any, setSelectedPages: any, website: any, setWebsite: any, agentName: string }) {
    let [isOpen, setIsOpen] = useState(false)
    const [pages, setPages] = useState(indexedPages ? indexedPages.map((page: any) => page.url) : []);
    const { get, getStream, post, postStream, put, patch, remove } = useApi();
    const [startScrape, setStartScrape] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [websites, setWebsites] = useState<any[]>([]);
    const [scraping, setScraping] = useState<boolean>(false);
    const [showWebsites, setShowWebsites] = useState<boolean>(false);
    const [websiteSelected, setWebsiteSelected] = useState<any>();
    const { captureEvent } = useAnalytics();
    const { user, organization, checkLicenseType } = useUser();
    const navigate = useNavigate();

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    const handleCheckboxChange = (url: string) => {
        setSelectedPages((prevSelected: any) => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(url)) {
                newSelected.delete(url);
            } else {
                newSelected.add(url);
            }
            return newSelected;
        });
    };

    function CheckIcon(props: any) {
        return (
            <svg viewBox="0 0 24 24" fill="none" {...props}>
                <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
                <path
                    d="M7 13l3 3 7-7"
                    stroke="#fff"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )
    }

    const scrapeWebsite = () => {
        setScraping(true);
        setPages([]); // Clear pages before scraping
        setSelectedPages(new Set()); // Clear selected pages before scraping
        try {
            const url = new URL(website);
            post('/get-website-urls/', { url: website }).then((res) => {
                setIsOpen(true);
                setPages(res.data.urls);
                setWebsite(res.data.base_url);
                setScraping(false); // Set scraping to false after success
                toast.success('Pages retrieved successfully', toastConfig);
                captureEvent('get_website_urls', {
                    website: website,
                    urls: res.data.urls,
                });
            }).catch((error) => {
                console.error('Error scraping website:', error);
                toast.error('Error getting pages.', toastConfig);
                setScraping(false); // Set scraping to false after error
            });
        } catch (e) {
            toast.error('Invalid website URL', toastConfig);
            setScraping(false); // Set scraping to false for invalid URL
        }
    };

    const crawlWebsite = () => {
        if (!checkLicenseType('standard')) {
            toast.error('Upgrade to the Standard plan to crawl your website.', toastConfig);
            return;
        }
        setPages([]); // Clear pages before crawling
        try {
            const url = new URL(website);
            post('/crawl-website/', { website: website, agent_id: agentId }).then((res) => {
                toast.success('Crawling in progress', toastConfig);
                captureEvent('crawl_website', {
                    website: website,
                    agent_id: agentId,
                });
            }).catch((error) => {
                console.error('Error crawling website:', error);
                toast.error('Error getting pages.', toastConfig);
                setScraping(false); // Set scraping to false after error
            });
        } catch (e) {
            toast.error('Invalid website URL', toastConfig);
            setScraping(false); // Set scraping to false for invalid URL
        }
    };  

    const indexPages = () => {
        if (selectedPages.size === 0) {
            toast.error('No pages selected for indexing', toastConfig);
            return;
        }
        setScraping(true);
        const selectedUrls = Array.from(selectedPages);
        post('/index-pages/', { website, urls: selectedUrls, agent_id: agentId }).then((res) => {
            setIndexedPages(res.data.pages);
            setScraping(false); // Set scraping to false after success
            setIsOpen(false); // Close modal after indexing
            setWebsites((prevWebsites: any) => [
                ...prevWebsites,
                {
                    id: res.data.id,
                    url: website,
                    index_status: 'In Progress',
                    page_count: 0
                }
            ]);
            toast.success('Your website is indexing! Check the websites module for progress.', toastConfig);
            captureEvent('indexing_pages', {
                website: website,
                urls: selectedUrls,
            });
        }).catch((error) => {
            console.error('Error indexing pages:', error);
            toast.error(error.detail, toastConfig);
            setScraping(false); // Set scraping to false after error
        });
    };

    const clear = () => {
        setWebsite('');
        setIndexedPages([]);
        setPages([]);
    };

    const selectAllPages = () => {
        setSelectedPages(new Set(pages));
    };

    // set selected if indexedPages is not empty
    useEffect(() => {
        if (indexedPages && indexedPages.length > 0) {
            setSelectedPages(new Set(indexedPages.map((page: any) => page.url)));
        }
    }, [indexedPages]);

    useEffect(() => {
        if (agentId) {
            get(`/websites/?agent_id=${agentId}`).then((res) => {
                setWebsites(res.data);
                setLoading(false);
            }).catch((error) => {
                console.error('Error fetching websites:', error);
            });
        }
    }, [agentId, showWebsites]);

    // Function to handle website association deletion
    function deleteWebsiteAssociation(websiteId: string) {
        remove(`/agents/${agentId}/websites/${websiteId}`)
            .then((response) => {
                console.log('Website association deleted:', response.data);
                toast.success('Website association deleted', toastConfig);
                setWebsites((prevWebsites) => prevWebsites.filter((website: any) => website.id !== websiteId));
            })
            .catch((error) => {
                console.error('Error deleting website association:', error);
                toast.error('Error deleting website association', toastConfig);
            });
    }

    return (
        <>
            <div className="flex flex-row w-full pt-2 dark:bg-slate-800 max-w-full">
                <Fieldset className="flex flex-col space-y-6 rounded-xl pr-6 pl-2 w-1/2 max-w-1/2">
                    {scraping && (
                        <div>
                            <span className={`text-sm font-light leading-6 text-slate-500 dark:text-slate-200 ${scraping ? 'animate-pulse' : ''}`}>If your website has a lot of content, this may take up to 2 minutes</span>
                        </div>
                    )}
                    <Field>
                        <div className="flex flex-col w-full max-w-full">
                            <Input
                                className={clsx(
                                    'border-slate-200 mt-3 block rounded-lg py-1.5 px-3 text-sm/6 grow',
                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25 dark:bg-slate-700 dark:border-slate-600 dark:focus:ring-slate-600 dark:text-slate-100 dark:placeholder:text-slate-400'
                                )}
                                placeholder="https://aimdoc.ai"
                                onChange={(e) => setWebsite(e.target.value)}
                                value={website}
                            />
                            <div className="flex flex-row w-full justify-between space-x-4 mt-3">
                                <div className="flex-1 flex-col">
                                    <div className="text-xs font-light leading-6 text-slate-500 dark:text-slate-200">
                                        Get pages from your site. Select pages to train your agent on.
                                    </div>
                                    <div
                                        className={`mt-4 text-center rounded-md hover:cursor-pointer border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium text-slate-600 hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 transition duration-200 dark:bg-slate-600 dark:text-slate-50 dark:hover:bg-slate-600/50 ${scraping ? 'cursor-not-allowed animate-pulse' : ''}`}
                                        onClick={() => scrapeWebsite()}
                                    >
                                        Get Pages
                                    </div>
                                </div>
                                <div className="flex border-l border-slate-200 dark:border-slate-700">

                                </div>
                                <div className="flex-1 flex-col">
                                    <div className="text-xs font-light leading-6 text-slate-500 dark:text-slate-200">
                                        Comprehensive website crawl and training.
                                    </div>
                                    <div
                                        className={`mt-4 text-center rounded-md hover:cursor-pointer border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium text-slate-600 hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 transition duration-200 dark:bg-slate-600 dark:text-slate-50 dark:hover:bg-slate-600/50 ${scraping ? 'cursor-not-allowed animate-pulse' : ''}`}
                                        onClick={() => crawlWebsite()}
                                    >
                                        Crawl Site
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Field>
                    {pages && pages.length > 0 && isOpen && (
                        <div className="space-y-2 p-4 border border-slate-200 shadow-sm rounded-lg bg-white dark:bg-slate-700/60 dark:border-slate-700 w-full max-w-full">
                            <label className="block text-md font-medium leading-6 text-slate-900 dark:text-slate-100">
                                Select Pages
                            </label>
                            <label className="block text-sm font-light leading-6 text-slate-400 mt-1 dark:text-slate-300">
                                It is recommended to only select pages aligned with your agent's goal and assigned persona.
                            </label>
                            <div className="flex flex-row justify-between items-center mb-4 pt-2 pb-2">
                                <div className="space-x-2">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-600 dark:hover:bg-slate-600/50 dark:text-slate-50 transition duration-200"
                                        onClick={() => selectAllPages()}
                                    >
                                        Select All Pages
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-600 dark:hover:bg-slate-600/50 dark:text-slate-50 transition duration-200"
                                        onClick={() => clear()}
                                    >
                                        Reset
                                    </button>
                                </div>
                                <div className="block text-sm font-medium leading-6 text-slate-400 mt-1 dark:text-slate-300">
                                    {pages.length} Pages Found
                                </div>
                            </div>
                            <div className="space-y-2 px-2 pb-4 pt-2 overflow-y-auto max-h-80">
                                {pages.map((page: any) => (
                                    <div key={page} className="relative flex items-start">
                                        <div className="flex items-center h-5">
                                            <input
                                                id={page}
                                                name={page}
                                                type="checkbox"
                                                checked={selectedPages.has(page)}
                                                onChange={() => handleCheckboxChange(page)}
                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-slate-300 rounded hover:cursor-pointer dark:focus:ring-slate-500 dark:border-slate-500 dark:text-slate-800"
                                            />
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label htmlFor={page} className="font-medium text-slate-700 hover:cursor-pointer dark:text-slate-200 break-all">
                                                {page}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-center w-full">
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md border border-transparent 
                                bg-slate-100 px-4 py-2 text-sm font-medium text-slate-900 hover:bg-slate-200 focus:outline-none 
                                focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 
                                dark:bg-slate-600 dark:text-slate-50 dark:hover:bg-slate-600/50 transition duration-200"
                                    onClick={() => indexPages()}
                                >
                                    Train Agent
                                </button>
                            </div>
                        </div>
                    )}
                </Fieldset>
                <div className="flex flex-col mt-4 pr-2 pl-6 border-l dark:border-slate-700 w-1/2 max-w-1/2">
                    <div className="block text-md font-semibold leading-6 text-slate-800/60 px-2 py-1 border bg-slate-50 rounded-md text-center mb-2 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600">
                        Trained Wesbites
                    </div>
                    <div className="pt-2">
                        <div onClick={() => setShowWebsites(true)} className="p-4 group flex text-slate-500 rounded-lg hover:cursor-pointer items-center border border-dashed justify-center dark:text-slate-200 text-sm font-medium dark:bg-slate-700/40 dark:border-slate-700">
                            <div className="group-hover:scale-105 transition duration-200">
                                Select Existing
                            </div>
                        </div>
                        {websites.length > 0 && (
                            <div className="border-b border-slate-200 mt-4 mb-2 dark:border-slate-700">

                            </div>
                        )}
                    </div>
                    <div className="flex flex-col pt-2 space-y-2 w-full max-w-full">
                        {websites.map((website: any) => (
                            <div className="flex flex-col w-full items-center justify-between p-2 rounded-md border bg-white text-sm font-medium text-slate-800 shadow-sm transition duration-200 dark:bg-slate-700 dark:border-none dark:hover:bg-slate-700/50">
                                <div className="flex justify-end w-full">
                                    <svg onClick={() => deleteWebsiteAssociation(website.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-400 flex-shrink-0 hover:cursor-pointer hover:stroke-slate-600 transition duration-200 dark:stroke-slate-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                </div>
                                <div className="w-full px-4 mb-2">
                                    <div className="flex flex-row justify-between w-full max-w-full pb-2">
                                        <div onClick={() => navigate(`/websites/${website.id}`)} className="flex hover:cursor-pointer items-center w-full max-w-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${website.index_status === 'In Progress' ? 'animate-pulse' : ''} mr-3 w-6 h-6 stroke-slate-400 flex-shrink-0 dark:stroke-slate-300`}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                            </svg>
                                            <div key={website.id} className={`text-sm ${website.index_status === 'In Progress' ? 'animate-pulse' : ''} mr-6 dark:text-slate-200 break-all`}>
                                                {website.url}
                                            </div>
                                        </div>
                                        {website.index_status === '' && (
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 stroke-red-500 flex-shrink-0">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg>
                                        )}
                                        {website.index_status === 'Completed' && (
                                            <div className="flex flex-row bg-slate-100 text-sm text-slate-500 text-xs rounded-md px-2 py-1 items-center justify-between dark:bg-slate-900/10 dark:border dark:border-slate-800">
                                                <div className="dark:text-slate-300">
                                                    {`${website.page_count} pages`}
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-4 h-4 stroke-green-500 flex-shrink-0">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>
                                            </div>
                                        )}
                                        {website.index_status === 'In Progress' && (
                                            <div className="flex flex-row bg-slate-100 text-sm text-slate-500 text-xs rounded-md px-2 py-1 items-center justify-between dark:bg-slate-900/10 dark:border dark:border-slate-800">
                                                <div className="dark:text-slate-300">
                                                    {`${website.page_count} pages`}
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-4 h-4 stroke-blue-500 flex-shrink-0">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex-grow h-2 bg-slate-200/80 rounded-full overflow-hidden w-full dark:bg-slate-500/70">
                                        <div
                                            className="h-full bg-green-400/60 rounded-full dark:bg-green-600/90"
                                            style={{ width: `${(website.progress ? website.progress : 0) * 100}%` }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {loading && (
                            <BeatLoader />
                        )}
                        {showWebsites && (
                            <WebsitesList
                                agentId={agentId}
                                setShowWebsites={setShowWebsites}
                                setWebsitesSelected={setWebsiteSelected}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
