import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

export default function GenericDropdown({ value, setValue } : { value: string | undefined, setValue: any}) {

    const fontClassMap: { [key: string]: string } = {
        'poppins': 'Poppins',
        'open-sans': 'Open Sans',
        'roboto': 'Roboto',
        'lato': 'Lato',
        'montserrat': 'Montserrat'
    };
    
    const fonts = Object.keys(fontClassMap);
  
    return (
    <div className="w-72 mt-2">
      <Listbox value={value ? value : 'open-sans'} onChange={setValue}>
        <div className="relative mt-1 z-50">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white border border-slate-200 py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm dark:bg-slate-700 dark:border-none">
            <span className={`${value} block truncate dark:text-slate-100`}>{value ? fontClassMap[value] : 'Open Sans'}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-slate-400 dark:text-slate-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {fonts.map((val: string, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `${val} relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-blue-100 text-blue-900' : 'text-slate-900'
                    }`
                  }
                  value={val}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {fontClassMap[val]}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
