import React, { useContext, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Link, useParams } from 'react-router-dom';
import useApi from '../api';
import { ToastContainer } from 'react-toastify';
import useToggle from '../UseToggle';


const Vectors: React.FC = () => {
    const [vectors, setVectors] = useState<any>([]);

    const { id } = useParams();

    // query params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filter = queryParams.get('filter') || '';

    console.log(filter)

    const { get, getStream, post, postStream, put, patch, remove } = useApi();

    // get list of vectors from server
    useEffect(() => {
        // call vectors api endpoint
        const getVectors = async () => {
            try {
                let vecs = [];
                const response = await get(`/vectors/${id}`);
                console.log(response.data.vectors)
                for (const key in response.data.vectors) {
                    for (const vec of response.data.vectors[key]) {
                        vecs.push({ type: key, ...vec })
                    }
                }
                console.log(vecs)
                setVectors(vecs); // Set the vectors data in state
            } catch (error) {
                console.error('Error fetching vectors:', error);
            }
        };
        getVectors();
    }, [id]);

    // filter vectors
    useEffect(() => {
        // filter based on existing state
        const filterVectors = async () => {
            try {
                let vecs = [];
                const response = await get(`/vectors/${id}`);
                console.log(response.data.vectors)
                for (const key in response.data.vectors) {
                    for (const vec of response.data.vectors[key]) {
                        vecs.push({ type: key, ...vec })
                    }
                }
                console.log(vecs)
                if (filter) {
                    const filtered = vecs.filter((vector: any) => vector.type === filter);
                    setVectors(filtered);
                } else {
                    setVectors(vecs);
                }
            } catch (error) {
                console.error('Error fetching vectors:', error);
            }
        };
        filterVectors();
    }, [filter, id]);

    return (
        <>
            <div className="p-12 mt-12 overflow-y-auto max-h-[calc(100vh-1px)] scrollbar-thin">

                <div className="flex flex-col mt-8">

                    <div className="-m-1.5 overflow-x-auto pb-12">
                        <div className="p-1.5 min-w-full inline-block align-middle">
                            <div className="border rounded-lg shadow overflow-hidden dark:border-gray-700 dark:shadow-gray-900">
                                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                    <thead className="bg-gray-50 dark:bg-gray-700 sticky top-0">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-400">
                                                Name
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-400">
                                                Type
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-400">
                                                Field ID
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-400">
                                                Field Name
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase dark:text-gray-400">
                                                Field Value
                                            </th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                        {vectors.length > 0 &&
                                            vectors.map((vector: any) => (
                                                <tr key={vector.id}>
                                                    <td className="px-6 py-4 whitespace-pre-line text-sm font-semibold text-gray-800 dark:text-gray-200 w-1/4 hover:text-blue-500">
                                                        <Link
                                                            to={`/documents/${vector.id}`}
                                                            className="hover:underline"
                                                        >
                                                            {vector.name}
                                                        </Link>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-pre-line text-sm text-gray-800 dark:text-gray-200 truncate">
                                                        {vector.type}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-pre-line text-sm text-gray-800 dark:text-gray-200 truncate">
                                                        {vector.field_guid}
                                                    </td>
                                                    <td className="px-2 py-4 whitespace-pre-line text-sm text-gray-800 dark:text-gray-200 truncate w-40">
                                                        {vector?.doc_schema?.name}
                                                    </td>
                                                    <td className="px-4 py-3 text-xs">
                                                        {vector.type.includes('field') ? vector?.doc_schema?.value.text_value : vector.type === 'name' ? vector?.name : vector.type === 'description' ? vector?.description : vector.type === 'doc_form' ? vector?.doc_form?.value.text_value : null}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default Vectors;