import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import GoogleDriveIcon from '../icons/google_drive.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastConfig from '../config/Toast';
import useApi from '../api';
import IntegrationIcon from './IntegrationIcon';
import { useEffect } from 'react';
import Spinner from '../documentation/Spinner';
import { useNavigate } from 'react-router-dom';
import { set } from 'lodash';
import { conLog } from '../utils/conLog';


export default function GoogleDrive({ id, type, deleteIntegration, setLoading }: { id: any, type: string, deleteIntegration?: any, setLoading?: any }) {
  let [isOpen, setIsOpen] = useState(false)
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { get, getStream, post, postStream, put, patch, remove } = useApi();

  const navigate = useNavigate();

  function closeModal() {
    setIsOpen(false)
  }

  function openModalDoc() {
    if (type === "doc") {
      setIsOpen(true)
    }
  }

  const createDoc = async (file: any) => {
    setLoading(true); // Start loading before upload
    setIsOpen(false);

    try {
        const response = await post("/documents/text", {
            integration_id: id,
            file: file
        });

        conLog(response.data);
        //toast.success("Document uploaded successfully", toastConfig);
        navigate('/documents/' + response.data.id + '?new=true');
    } catch (error) {
        console.error("Error uploading Document:", error);
        toast.error("Error uploading Document", toastConfig);
    } finally {
        setLoading(false); // End loading after upload
    }
    setLoading(false);
};

  useEffect(() => {
    const fetchFiles = async () => {
      const response = await get(`/google-drive/${id}/files`);
      conLog(response.data);
      setFiles(response.data);
    }
    fetchFiles();
  }, []);

  return (
    <>
        <div onClick={openModalDoc} className={`flex flex-row justify-between items-center border-b border-slate-200 bg-slate-50 shadow-sm p-4 w-full border rounded-xl dark:bg-slate-800 dark:border-none`}>
            <IntegrationIcon type={'Google Drive'} />
            <div>
            {type == "data" && (
                <div className="flex flex-row justify-end w-full">
                  <button
                  onClick={() => setIsOpen(true)}
  
                  className="bg-blue-400 hover:bg-blue-700 text-white font-bold py-2 px-1 rounded mr-2"
              >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                  </svg>
              </button>
          
              <button
                  onClick={() => deleteIntegration(id)}
                  className="bg-red-400 hover:bg-red-700 text-white font-bold py-2 px-1 rounded"
              >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                  </svg>
              </button>
              </div>
                )}
          
        </div>
    </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-slate-900 mb-2"
                  >
                    {type === "doc" ? "Select Document" : "Select Documents"}
                  </Dialog.Title>
                  {type === "doc" ? (
                    <Dialog.Description className="text-sm text-slate-500 mb-6">
                      Aimdoc will create a structured document from your selected Google document.
                    </Dialog.Description>
                  ) : (
                    <Dialog.Description className="text-sm text-slate-500 mb-6">
                    The selected files will sync on a daily basis. The integration will also pick up files that you do not select, but have been modified since connectiong the integration.
                    </Dialog.Description>
                  )}


                <div className="overflow-y-auto scrollbar-thin max-h-96 p-3">
                {files && files.map((file: any) => (
                    <div onClick={() => createDoc(file) } className="flex flex-col p-3 border shadow-sm rounded-xl w-full mb-2 hover:bg-slate-200 hover:cursor-pointer">
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col">
                            <div className="text-md font-medium leading-6 mb-1 text-slate-500">
                                {file.name}
                            </div>
                            <div className="text-sm text-slate-500">
                                Last Modified: {file.modifiedTime}
                            </div>
                            </div>
                            <div className="items-center">
                                <div className="flex-shrink-0 items-center justify-center">
                                    <img src={GoogleDriveIcon} alt="GDrive Icon" className="w-10 h-10" />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>

                  {/* <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Done
                    </button>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
