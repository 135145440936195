import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import config from '../../config.json';
import { Tab, TabGroup, TabList, TabPanel } from '@headlessui/react';
import useAnalytics from '../analytics/analytics';
import Markdown from 'react-markdown';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect, useRef } from 'react';
import useApi from '../api';
import { useOutsideAlerter } from '../useOutsideAlerter';
import Confetti from 'react-confetti';
import BeatLoader from '../ui/BeatLoader';


interface PublishAgentProps {
    agId: string;
    editName: any;
    setEditName: any;
    agentName: string;
    setAgentName: any;
}

const PublishAgent: React.FC<PublishAgentProps> = ({ agId, editName, setEditName, agentName, setAgentName }) => {
    let [isOpen, setIsOpen] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [confettiVisible, setConfettiVisible] = useState(false); // State for confetti visibility
    const [changes, setChanges] = useState({});
    const [loading, setLoading] = useState(false);
    const { get, getStream, post, postStream, put, patch, remove } = useApi();

    function closeModal() {
        setModalVisible(false);
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    const nameRef = useRef(null);
    useOutsideAlerter(nameRef, () => {
        setAgentName(agentName);
        setEditName(false);
    });

    const publishAgent = async () => {
        setLoading(true);
        try {
            const response = await post(`/agents/${agId}/publish`, changes);
            toast.success('Agent published successfully');
            setChanges({});
            setConfettiVisible(true); // Show confetti on success
            setTimeout(() => {
                setConfettiVisible(false); // Hide confetti after a few seconds
            }, 5000); // Confetti disappears after 5 seconds
        } catch (error) {
            toast.error('Error publishing agent');
        }
        setLoading(false);
        closeModal();
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;
    
        const fetchChanges = () => {
            if (agId) {
                get(`/agents/${agId}/changes`)
                    .then(response => {
                        console.log('Draft changes:', response.data);
                        setChanges(response.data.changes);
                    })
                    .catch(error => {
                        console.error('Error fetching draft changes:', error);
                    });
            }
        };
    
        // Poll for changes every 5 seconds, regardless of modal state
        fetchChanges(); // Fetch immediately on mount
        intervalId = setInterval(fetchChanges, 10000); // Set the polling interval
    
        // Clean up the interval when the component unmounts
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [agId, isOpen]); // Only run when `agId` changes

    const numberOfChanges = Object.keys(changes).length;

    return (
        <>
            {confettiVisible && <Confetti recycle={false}/>} {/* Render confetti when visible */}
            <div className="flex w-full justify-between p-4 border-b dark:border-slate-700 dark:bg-slate-950">
                <div className="flex hover:shadow-sm hover:cursor-pointer rounded-lg justify-start" onClick={() => setEditName(true)} ref={nameRef}>
                    {editName || !agId ? (
                        <div className="">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    autoComplete="off"
                                    autoFocus={true}
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-slate-900 placeholder:text-slate-400 placeholder:text-md rounded-md focus:border-blue-300 focus:ring focus:ring-slate-200 text-2xl focus:ring-opacity-50 sm:leading-6 dark:bg-slate-800 dark:placeholder:text-slate-400 dark:text-slate-200 dark:border dark:border-slate-600"
                                    placeholder="Enter you agent name..."
                                    value={agentName || ''}
                                    onChange={(e) => setAgentName(e.target.value)}
                                />
                            </div>
                        </div>
                    ) : (
                        <h2 className="text-3xl font-semibold leading-7 text-slate-900 py-1.5 dark:text-slate-200">{agentName !== "" ? agentName : "Agent Name"}</h2>
                    )}
                </div>
                {!modalVisible && (
                    <div className="flex items-center space-x-3">
                        {numberOfChanges > 0 && (
                            <div className="flex flex-row items-center justify-center text-xs font-semibold text-yellow-500">
                                <div className="mr-2 rounded full w-2 h-2 bg-yellow-500 animate-pulse">
                                </div>
                                {numberOfChanges} unpublished change{numberOfChanges > 1 ? 's' : ''}
                            </div>
                        )}
                        <div onClick={openModal} className="group flex flex-row justify-between items-center px-4 py-2 text-sm text-blue-500 border border-blue-200 bg-blue-50 hover:cursor-pointer hover:bg-blue-100 dark:hover:bg-blue-800 rounded-lg font-bold dark:bg-blue-900 dark:border-blue-800 dark:text-blue-100 transition duration-200">
                            <div className="mr-2">
                                Publish
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 rotate-180 group-hover:rotate-0 transition duration-100 dark:stroke-slate-200">
                                <path fillRule="evenodd" d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 0 1 .75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 0 1 9.75 22.5a.75.75 0 0 1-.75-.75v-4.131A15.838 15.838 0 0 1 6.382 15H2.25a.75.75 0 0 1-.75-.75 6.75 6.75 0 0 1 7.815-6.666ZM15 6.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" clipRule="evenodd" />
                                <path d="M5.26 17.242a.75.75 0 1 0-.897-1.203 5.243 5.243 0 0 0-2.05 5.022.75.75 0 0 0 .625.627 5.243 5.243 0 0 0 5.022-2.051.75.75 0 1 0-1.202-.897 3.744 3.744 0 0 1-3.008 1.51c0-1.23.592-2.323 1.51-3.008Z" />
                            </svg>
                        </div>
                    </div>
                )}
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="flex flex-col w-full max-w-2xl h-1/2 transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800 dark:border dark:border-slate-700">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-center text-xl font-medium leading-6 text--900 dark:text-slate-100"
                                    >
                                        Publish Agent
                                    </Dialog.Title>
                                    <div className="flex flex-col h-full justify-between">
                                        <div>
                                        <div className="border-b border-slate-200 py-2 text-md font-light leading-6 text-slate-800 mt-2 dark:text-slate-200">
                                            Review Changes
                                        </div>
                                        <div className="flex flex-col space-y-2 p-4">
                                            {Object.keys(changes).length > 0 && Object.keys(changes).map((key) => {
                                                // Format the key: capitalize the first letter and replace '_key' with an empty string for 'logo_key'
                                                const formattedKey = key === 'logo_key'
                                                    ? 'Logo'
                                                    : key.charAt(0).toUpperCase() + key.slice(1).replace('_key', '');

                                                return (
                                                    <div className="flex flex-row space-x-2 items-center" key={key}>
                                                        <div className="rounded full w-2 h-2 bg-yellow-400 animate-pulse">
                                                        </div>
                                                        <div>
                                                            <p className="text-sm font-medium text-slate-800 dark:text-slate-200">
                                                                {formattedKey}
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {Object.keys(changes).length === 0 && (
                                                <div className="flex text-slate-800 font-light text-sm dark:text-slate-400 h-full justify-center items-center">
                                                    No changes to publish
                                                </div>
                                            )}
                                            {loading && (
                                                <BeatLoader />
                                            )}
                                        </div>
                                        </div>
                                        <div className="flex mt-4 justify-center">
                                            <div onClick={async () => await publishAgent()} className="group flex flex-row w-1/2 justify-between items-center px-4 py-2 text-sm text-blue-500 border border-blue-200 bg-blue-50 hover:bg-blue-100 dark:hover:bg-blue-800 rounded-lg font-bold dark:bg-blue-900 dark:border-blue-800 dark:text-blue-100 transition duration-200">
                                                <div className="mr-2">
                                                    Publish Agent
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 rotate-180 group-hover:rotate-0 transition duration-100 dark:stroke-slate-200">
                                                    <path fillRule="evenodd" d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 0 1 .75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 0 1 9.75 22.5a.75.75 0 0 1-.75-.75v-4.131A15.838 15.838 0 0 1 6.382 15H2.25a.75.75 0 0 1-.75-.75 6.75 6.75 0 0 1 7.815-6.666ZM15 6.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" clipRule="evenodd" />
                                                    <path d="M5.26 17.242a.75.75 0 1 0-.897-1.203 5.243 5.243 0 0 0-2.05 5.022.75.75 0 0 0 .625.627 5.243 5.243 0 0 0 5.022-2.051.75.75 0 1 0-1.202-.897 3.744 3.744 0 0 1-3.008 1.51c0-1.23.592-2.323 1.51-3.008Z" />
                                                </svg>
                                            </div>

                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default PublishAgent;