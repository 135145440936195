import { Fragment, useState, useEffect } from 'react'
import GoogleDriveIcon from '../icons/google_drive.png'
import useApi from '../api';
import { LeftHandSideExpression } from 'typescript';
import UserCircle from '../user/UserCircle';
import { set } from 'lodash';
import { useParams, useNavigate } from 'react-router-dom';
import Sessions from './Sessions';
import { formatDate } from '../utils/dateFormatter';
import { QuestionMarkCircleIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import SalesforceIcon from '../icons/salesforce_logo.png'
import HubspotIcon from '../icons/hubspot_logo.webp'



interface LinkProps {
    leadId: string;
}

interface Lead {
    id: string;
    first_name: string;
    last_name: string;
    company: string;
    email: string;
    job_title: string;
    attributes: any;
    created_at: string;
    email_verified: boolean;
    integrations: {
        salesforce: string;
        hubspot: string;
    };
}

export default function Lead() {
    const { id } = useParams();
    const [modalVisible, setModalVisible] = useState(false)
    let [isOpen, setIsOpen] = useState(false)
    const { get, getStream, post, postStream, put, patch, remove } = useApi();
    const [lead, setLead] = useState<Lead | undefined>();
    const [conversations, setConversations] = useState<any[]>([]);
    const [interacted, setInteracted] = useState<boolean>(false);
    const [message, setMessage] = useState<any>(null);

    function closeModal() {
        setModalVisible(false)
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    useEffect(() => {
        if (id) {
            get(`/leads/${id}`).then((response) => {
                setLead(response.data);
            });
            get(`/leads/${id}/conversations`).then((response) => {
                setConversations(response.data);
            });
        }
    }, [id]);

    const hasInteracted = () => {
        for (let conversation of conversations) {
            for (let message of conversation.messages) {
                if (message.role === "user" && (message.content !== "" && message.content !== null)) {
                    setInteracted(true);
                }
            }
        }
    };

    function isLink(str: string): boolean {
        try {
            new URL(str);
            return true;
        } catch {
            return false;
        }
    }

    useEffect(() => {
        hasInteracted();
    }, [conversations]);

    useEffect(() => {
        if (message) {
            openModal();
        }
    }, [message]);

    return (
        <>
            {lead && (
                <div className="flex w-full h-full overflow-y-auto scrollbar-thin justify-center text-center">
                    <div className="w-full transform h-full bg-white p-6 text-left align-middle dark:bg-slate-900">
                        <div className="col-span-2 text-xl font-medium leading-6 text-slate-600 mb-6 px-4 py-2 text-center border bg-slate-100 rounded-md dark:bg-slate-800 dark:text-slate-100 dark:border-none">
                            Lead Details
                        </div>
                        <div className="flex flex-row w-fit mb-2">
                            <div className="flex items-center p-4 bg-slate-50 border border-slate-200 rounded-lg dark:bg-slate-800 dark:border-slate-700">
                                <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-blue-500 text-white font-medium">
                                {(lead.first_name?.[0] || '') + (lead.last_name?.[0] || '')}
                                </div>
                                <div className="ml-4">
                                    <div className="text-lg font-medium dark:text-slate-200">
                                        {lead.first_name} {lead.last_name}
                                    </div>
                                    <div className="flex items-center text-sm text-slate-600 dark:text-slate-300">
                                        {lead.email}
                                        {lead.email_verified === null ? (
                                            <div className="group relative">
                                                <QuestionMarkCircleIcon className="h-5 w-5 ml-1 text-blue-500 stroke-2" />
                                                <span className="absolute z-40 bottom-full mb-2 hidden group-hover:block bg-slate-200 text-slate-800 dark:bg-slate-800 dark:text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                                                    Email not verified
                                                </span>
                                            </div>
                                        ) : lead.email_verified ? (
                                            <div className="group relative">
                                                <CheckCircleIcon className="h-5 w-5 ml-1 text-green-500 stroke-2" />
                                                <span className="absolute z-40 bottom-full mb-2 hidden group-hover:block bg-slate-200 text-slate-800 dark:bg-slate-800 dark:text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                                                    Email verified
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="group relative">
                                                <XCircleIcon className="h-5 w-5 ml-1 text-red-500 stroke-2" />
                                                <span className="absolute z-40 bottom-full mb-2 hidden group-hover:block bg-slate-200 text-slate-800 dark:bg-slate-800 dark:text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                                                    Invalid email
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {lead.integrations && (
                            <div className="flex items-center gap-2 ml-2">
                                {lead.integrations.salesforce && (
                                    <div className="group relative h-full">
                                        <a
                                            href={lead.integrations.salesforce}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="p-1 rounded-md hover:bg-slate-200 dark:hover:bg-slate-700 bg-slate-50 items-center flex h-full w-full border border-slate-200 rounded-lg dark:bg-slate-800 dark:border-slate-700"
                                        >
                                            <img
                                                src={SalesforceIcon}
                                                alt="Salesforce"
                                                className="w-16"
                                            />
                                        </a>
                                        <span className="absolute bottom-full mb-2 hidden group-hover:block bg-slate-800 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                                            View in Salesforce
                                        </span>
                                    </div>
                                )}
                                {lead.integrations.hubspot && (
                                    <div className="group relative h-full">
                                        <a
                                            href={lead.integrations.hubspot}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="p-1 rounded-md hover:bg-slate-200 dark:hover:bg-slate-700 bg-slate-50 items-center flex h-full w-full border border-slate-200 rounded-lg dark:bg-slate-800 dark:border-slate-700"
                                        >
                                            <img
                                                src={HubspotIcon}
                                                alt="HubSpot"
                                                className="w-10"
                                            />
                                        </a>
                                        <span className="absolute bottom-full mb-2 hidden group-hover:block bg-slate-800 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                                            View in HubSpot
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                        </div>
                        <div className="flex flex-col gap-4 px-4 pt-2">
                            <div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 dark:text-slate-200">
                                    Company
                                </label>
                                <div className="text-sm dark:text-slate-300">
                                    <h3>{lead.company}</h3>
                                </div>
                            </div>
                            <div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 dark:text-slate-200">
                                    Title
                                </label>
                                <div className="text-sm dark:text-slate-300">
                                    <h4>{lead.job_title}</h4>
                                </div>
                            </div>
                            <div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 dark:text-slate-200">
                                    Page Submitted On
                                </label>
                                <div className="text-sm dark:text-slate-300">
                                    <h4>{lead.attributes ? lead.attributes.aimdoc_parent_url : "N/A"}</h4>
                                </div>
                            </div>
                            <div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 dark:text-slate-200">
                                    Time Submitted At
                                </label>
                                <div className="text-sm dark:text-slate-300">
                                    <h4>{formatDate(lead.created_at)}</h4>
                                </div>
                            </div>
                            {lead.attributes && Object.keys(lead.attributes).map((key) => {
                                if (key === "email" || key === "first_name" || key === "last_name" || key === "company" || key === "job_title" || key === "aimdoc_parent_url") {
                                    return null;
                                } else {
                                    return (
                                        <div key={key}>
                                            <label className="block text-md font-medium leading-6 text-slate-900 pb-1 dark:text-slate-200">
                                                {key}
                                            </label>
                                            <div className="">
                                                {isLink(lead.attributes[key]) ? (
                                                    <a href={lead.attributes[key]} className="text-blue-500 underline break-words text-sm font-medium dark:text-slate-300" target="_blank" rel="noopener noreferrer">
                                                        {lead.attributes[key]}
                                                    </a>
                                                ) : (
                                                    <div className="text-sm dark:text-slate-200">
                                                        {lead.attributes[key]}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div className="flex w-full mt-10">
                            <div className="flex flex-col w-full space-y-6 items-center justify-center">
                                <div
                                    className="text-xl w-full font-medium leading-6 text-slate-600 px-4 py-2 text-center border bg-slate-100 rounded-md dark:bg-slate-800 dark:text-slate-100 dark:border-none"
                                >
                                    Session History
                                </div>
                                <Sessions leadId={id} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
