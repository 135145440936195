import React from 'react';
import GoogleDriveIcon from '../icons/google_drive.png'
import HubSpotIcon from '../icons/hubspot_logo.webp'
import OntraportIcon from '../icons/ontraport_logo.webp'
import { conLog } from '../utils/conLog';

interface IntegrationIconProps {
    type: string;
}

const IntegrationIcon: React.FC<IntegrationIconProps> = (props) => {
    const { type } = props;
    conLog(type)

    return (
        <>
        {type == "Google Drive" && (
            <div className="flex flex-row items-center">
            <img src={GoogleDriveIcon} className="w-10 h-10" />
            <h3 className="pl-4 text-lg font-medium text-slate-800 dark:text-slate-300">{type}</h3>
        </div>
        )}
        {type == "HubSpot" && (
            <div className="flex flex-row items-center">
            <img src={HubSpotIcon} className="w-10 h-10" />
            <h3 className="pl-4 text-lg font-medium text-slate-800 dark:text-slate-300">{type}</h3>
        </div>
        )}
        {type == "Ontraport" && (
            <div className="flex flex-row items-center">
            <img src={OntraportIcon} className="w-10 h-10" />
            <h3 className="pl-4 text-lg font-medium text-slate-800 dark:text-slate-300">{type}</h3>
        </div>
        )}
        </>



    );
};

export default IntegrationIcon;