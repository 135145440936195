import { useEffect, useState, memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Spinner from '../../documentation/Spinner';
import { set } from 'lodash';


const CalendlyWidget = memo(({ url }: { url: string }) => {
    useEffect(() => {
        const loadScript = () => {
            const existingScript = document.getElementById('calendly-script');
            if (existingScript) {
                document.body.removeChild(existingScript);
            }
            const script = document.createElement('script');
            script.id = 'calendly-script';
            script.src = 'https://assets.calendly.com/assets/external/widget.js';
            script.async = true;
            document.body.appendChild(script);
        };

        loadScript();

        return () => {
            // Cleanup if needed, though generally we might not need to remove the script
        };
    }, []); // This ensures the effect runs only once

    // Generate a uuid for the unique id of the div
    const uuid = uuidv4();
    const uniqueId = `calendly-widget-${uuid}`;

    const checkIfMobile = () => {
        return window.innerWidth < 768;
    };

    return (
        <div id={uniqueId} className={`calendly-inline-widget min-w-[200px] h-[1000px]`} data-url={`${url}`}></div>
    );
});

export default CalendlyWidget;
