import React, { useState, useContext, useEffect } from 'react';
import useApi from './api';
import logo from '../../logo.webp';
import logo_symbol from '../logo_symbol.png';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastConfig from './config/Toast';
import { log } from 'console';
import Walkthrough from './Walkthrough';
import { set } from 'lodash';
import { UserInterface } from './types';
import { useUser } from './user/UserContext';
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import UserCircle from './user/UserCircle';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { conLog } from './utils/conLog';
import UsageAnalytics from './analytics/UsageAnalytics';
import Sessions from './agents/Sessions';

const Dashboard = () => {

    const { get, getStream, post, postStream, put, patch, remove } = useApi();
    const [userProfile, setUserProfile] = useState<UserInterface>();
    const { user, organization, loading } = useUser();
    const [documents, setDocuments] = useState([]);
    const [leads, setLeads] = useState([]);
    const [agents, setAgents] = useState<[] | undefined>(undefined);
    const [initWalkthroughDone, setInitWalkthroughDone] = useState(false);
    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }


    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            await getAgents();
            getDocuments();
            getLeads();
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (!agents) return;
        if (agents.length === 0 && user && (initWalkthroughDone === true || user?.walkthrough_complete === true)) openModal();
    }, [agents, userProfile, initWalkthroughDone]);

    const getDocuments = async () => {
        try {
            const data = await get('/documentation/?this_week=true');
            setDocuments(data.data);
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    const getLeads = async () => {
        try {
            const response = await get('/leads/?this_week=true');
            setLeads(response.data);
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    };

    const getAgents = async () => {
        try {
            const response = await get('/agents/');
            setAgents(response.data);
        } catch (error) {
            console.error('Error fetching agents:', error);
        }
    };


    return (
        <div className="flex flex-col w-full px-4 bg-gradient-to-br from-blue-100 to-blue-500 overflow-y-auto scrollbar-thin pb-16 dark:from-slate-900 dark:to-slate-700">
            {user && user.walkthrough_complete !== true && <Walkthrough setComplete={setInitWalkthroughDone} user={user} />}
                <>
                    <Transition appear show={isOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={closeModal}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black/25 dark:bg-black/60" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-bold leading-6 text-slate-900 dark:text-slate-200"
                                            >
                                                Create your first agent!
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-slate-500 dark:text-slate-300">
                                                    It looks like you haven't created an agent yet. Click the button below to create your first agent!
                                                </p>
                                            </div>

                                            <div className="mt-4">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-600 dark:text-slate-200 dark:hover:bg-slate-700"
                                                    onClick={() => navigate('/agents/?new=true')}
                                                >
                                                    Create agent
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            <div className="flex w-full justify-center mt-16">
                <UsageAnalytics organization={organization}/>
                <div className="flex flex-col w-full ml-4 mt-6 bg-white p-4 rounded-xl max-w-2xl shadow-lg dark:bg-slate-700">
                    <h1 className="flex justify-between rounded-lg bg-slate-50 border border-slate-200 px-4 py-2 text-left text-xl font-medium text-slate-900 focus:outline-none dark:bg-slate-700 dark:text-slate-200 dark:border-none">
                        {leads.length} New Leads This Week!
                    </h1>
                    <div className="flex flex-col overflow-x-auto max-h-72 mt-1 scrollbar-hidden space-y-2">
                        {leads && leads.length > 0 && leads.map((lead: any) => (
                            <div className="p-1 mt-4" key={lead.id}>
                                <div onClick={() => navigate(`/leads/${lead.id}`)} className="flex flex-row justify-between bg-white rounded-lg border border-slate-200 pl-4 pr-1 py-2 hover:bg-slate-50 hover:cursor-pointer dark:bg-slate-600 dark:hover:bg-slate-600/50 dark:border-slate-600 transition duration-200">
                                    <div className="flex flex-row space-x-2 items-center">
                                        <h2 className="text-slate-900 font-medium text-lg dark:text-slate-200">{lead.first_name} {lead.last_name}</h2>
                                        <p className="text-slate-600 text-sm dark:text-slate-300">{lead.email}</p>
                                    </div>
                                    <UserCircle leadEmail={lead.email} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex flex-row justify-center items-center mt-16">
                <h1 className="text-4xl font-semibold text-center items-center text-blue-900 mr-2 dark:text-slate-200">Active Sessions</h1>
            </div>
            <div className="flex w-full justify-center ml-8">
                <div className="flex flex-col w-full max-w-6xl">
                    <Sessions dashboard={true} />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;