import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
    // get the current path
    const path = window.location.pathname;
    useEffect(() => {
        // Function to load the Iubenda script
        const loadIubendaScript = () => {
            const script = document.createElement('script');
            script.src = "https://cdn.iubenda.com/iubenda.js";
            script.async = true;
            document.body.appendChild(script);
        };

        // Call the function to load the script
        loadIubendaScript();

        // Optional: Clean up the script when the component unmounts
        return () => {
            // Assuming 'https://cdn.iubenda.com/iubenda.js' creates a global variable or function,
            // you should clean it up here if necessary to prevent memory leaks
        };
    }, []); // Empty array ensures this effect only runs once after the initial rendering

    return (
        <div className={`${path === '/privacy' ? 'mt-16 p-8' : ''}`}>
            <a href="https://www.iubenda.com/privacy-policy/36587222"
               className="iubenda-white iubenda-embed iubenda-noiframe"
               title="Privacy Policy">
                Privacy Policy
            </a>
        </div>
    );
};

export default PrivacyPolicy;
